import RefreshIcon from '@mui/icons-material/Refresh';
import { CircularProgress, IconButton, Skeleton } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import MapContext from "../../contexts/MapContext";
import IClusterDetails from "../../interfaces/clusterDetails";
import { generateClusterPicture } from "../../requests";
import './ClusterPictureDisplay.scss';
import "./PaperPictureDisplay.scss"; // Import the CSS file for styles
import ResponsiveImage from '../utils/ResponsiveImage';

export default function ClusterPictureDisplay({
  clusterDetails,
  autoGenerate = false
}: {
  clusterDetails: IClusterDetails;
  autoGenerate?: boolean;
}) {
  const { mapName, selectedCorpusId, setSelectedClusterDetails, isMobile } = useContext(MapContext);
  const [isGenerating, setIsGenerating] = useState(false);
  const [imageKey, setImageKey] = useState(0); // Add key to force image refresh

  // Update imageKey when image_url changes to force refresh
  useEffect(() => {
    setImageKey(prevKey => prevKey + 1);
  }, [clusterDetails.image_url]);

  // Auto-generate image if enabled and no image exists
  useEffect(() => {
    if (autoGenerate && !clusterDetails.image_url && !isGenerating) {
      generatePicture();
    }
  }, [autoGenerate, clusterDetails.image_url]);

  const generatePicture = async () => {
    setIsGenerating(true);
    try {
      const updatedClusterDetails = await generateClusterPicture(mapName, clusterDetails.cluster_id.toString(), "tf");
      setSelectedClusterDetails(updatedClusterDetails);
    } catch (e) {
      console.error(e);
    } finally {
      setIsGenerating(false);
    }
  }

  if (!clusterDetails.image_url && !isGenerating) {
    return  <div className="clusterPicture" style={{ position: "relative" }}>
      <ResponsiveImage
          src={"/cover3.webp"}
          alt="picture could not be loaded"
          key={imageKey}
          maxImageHeight={isMobile ? 300 : 400}
        />
      
      <div className="overlay-text">
        <span style={{'fontSize':'11px',textAlign:'left'}}>Topic</span><br/>
        <span>{clusterDetails.label}
        </span>
      </div>
    </div>
  } else {
    return (
      <div className="clusterPicture" style={{ position: "relative" }}>
        <ResponsiveImage
          src={clusterDetails.image_url || "/no_picture.png"}
          alt="picture could not be loaded"
          key={imageKey}
          maxImageHeight={isMobile ? 200 : 400}
        />
        {isGenerating && (
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            animation="wave"
            style={{
              opacity: 0.4,
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.8)'
            }}
          />
        )}
        <IconButton
          className="btn-regenerate"
          disabled={isGenerating}
          onClick={generatePicture}
          style={{ position: "absolute", top: 10, right: 10, backgroundColor: "rgba(255, 255, 255, 0.7)" }}
          size="small"
        >
          {isGenerating ? <CircularProgress size={20} /> : <RefreshIcon />}
        </IconButton>
      </div>
    );
  }
}
import IPaperSelection from "./interfaces/paperSelection";
import { createGeometry } from "./requests";
import _ from "lodash";

export default async function createLandmarkGeometries(
  mapName: string,
  geometryConfig: any,
  minZoomShowTitles: number = 6,
  fitToBounds: (
    options: {
      points?: { lng: number; lat: number }[];
      bounds?: {
        ne: { lng: number; lat: number };
        sw: { lng: number; lat: number };
      };
    },
    buffer?: number,
    animated?: boolean,
    duration?: number
  ) => void,
): Promise<{
  sourceConfig: any;
  layerConfigWithTitles: any;
  layerConfigWithOutTitles: any;
}> {
  const data = await createGeometry(mapName, geometryConfig);
  const sourceConfig = data["source"];
  const layerConfig = data["layer"];

  const layerConfigWithTitles = _.cloneDeep(layerConfig);
  const layerConfigWithOutTitles = _.cloneDeep(layerConfig);
  layerConfigWithOutTitles["layout"]["icon-size"] = 0.02;
  layerConfigWithTitles.id = `${layerConfig.id}_with_titles`;
  layerConfigWithOutTitles.id = `${layerConfig.id}_without_titles`;

  //remove textfield from layout
  delete layerConfigWithOutTitles.layout["text-field"];
  layerConfigWithOutTitles.maxzoom = minZoomShowTitles;
  layerConfigWithTitles.minzoom = minZoomShowTitles;

  return { sourceConfig, layerConfigWithTitles, layerConfigWithOutTitles };
}

export function createDynamicLandmarkLayers(
  sourceConfig: any,
  layerConfig: any,
  minZoomShowTitles: number
) {
  const layerConfigWithTitles = _.cloneDeep(layerConfig);
  const layerConfigWithOutTitles = _.cloneDeep(layerConfig);
  layerConfigWithTitles.id = `${layerConfig.id}_with_titles`;
  layerConfigWithOutTitles.id = `${layerConfig.id}_without_titles`;
  layerConfigWithOutTitles["layout"]["icon-size"] = 0.02;
  //remove textfield from layout
  delete layerConfigWithOutTitles.layout["text-field"];
  layerConfigWithOutTitles.maxzoom = minZoomShowTitles;
  layerConfigWithTitles.minzoom = minZoomShowTitles;

  return { sourceConfig, layerConfigWithTitles, layerConfigWithOutTitles };
}

export function createClusterAreaLayerConfig(cluster_areas: string[]) {
  return {
    layer_name: "cluster_area_landmarks",
    min_zoom: 0,
    icon: "landmark_red",
  };
}

export function createLandmarkLayerConfig(selection: IPaperSelection) {
  if (selection.type == "search") {
    return {
      layer_name: "search_landmarks",
      min_zoom: 0,
      icon: "landmark_yellow",
    };
  } else if (selection.type == "author_papers") {
    return {
      layer_name: "author_papers_landmarks",
      min_zoom: 0,
      icon: "landmark_yellow",
    };
  } else if (selection.type.indexOf("citation") != -1) {
    return {
      layer_name: "citation_landmarks",
      min_zoom: 0,
      icon: "landmark_blue",
    };
  } else if (selection.type.indexOf("reference") != -1) {
    return {
      layer_name: "reference_landmarks",
      min_zoom: 0,
      icon: "landmark_green",
    };
  } else if (selection.type.indexOf("journal_papers") != -1) {
    return {
      layer_name: "journal_papers_landmarks",
      min_zoom: 0,
      icon: "landmark_orange",
    };
  }
}

export function getIconForSelection(selection: IPaperSelection) {
  if (selection.type == "search") {
    return "landmark_red";
  } else if (selection.type == "author_papers") {
    return "landmark_yellow";
  } else if (selection.type == "author_citations") {
    return "landmark_blue";
  } else if (selection.type == "author_references") {
    return "landmark_green";
  } else if (selection.type == "publication_papers") {
    return "landmark_orange";
  }else if (selection.type == "paper_citations") {
    return "landmark_blue";
  } else if (selection.type == "paper_references") {
    return "landmark_green";
  }
}

export function getFillColorsForSelection(selection: IPaperSelection) {
  if (selection.type == "search") {

    
    return [
      "interpolate",
      [
        "linear"
      ],
      [
        "get",
        "value"
      ],
      0,
      "#fcae91",
      5,
      "#ed7a6b",
      25,
      "#de2d26"
    ];
  } else if (selection.type == "author_papers") {
    return [
      "interpolate",
      ["linear"],
      ["get", "value"],
      0,
      "#fffde7",
      15,
      "#fff176",
      30,
      "#f57f17",
    ];
  } else if (selection.type == "author_citations") {
    return [
      "interpolate",
      ["linear"],
      ["get", "value"],
      0,
      "#e3f2fd",
      50,
      "#64b5f6",
      100,
      "#0d47a1",
    ];
  } else if (selection.type == "author_references") {
    return [
      "interpolate",
      ["linear"],
      ["get", "value"],
      0,
      "#e8f5e9",
      50,
      "#66bb6a",
      100,
      "#1b5e20",
    ];
  } else if (selection.type == "publication_papers") {
    return [
      "interpolate",
      ["linear"],
      ["get", "value"],
      0,
      "#FFD580",
      50,
      "#E67300",
      100,
      "#CC5500"
    ];
  } else if (selection.type == "paper_citations") {
    return [
      "interpolate",
      ["linear"],
      ["get", "value"],
      0,
      "#e3f2fd",
      50,
      "#64b5f6",
      100,
      "#0d47a1",
    ];
  } else if (selection.type == "paper_references") {
    return [
      "interpolate",
      ["linear"],
      ["get", "value"],
      0,
      "#e8f5e9",
      50,
      "#66bb6a",
      100,
      "#1b5e20",
    ];
  }
}

export async function createSelectionLayerConfigs(
  selection: IPaperSelection,
  prefix: string,
  mapName: string,
  upsertLayer: any,
  minZoomLandmarks: number = 0,
  fitToBounds?: (
    options: {
      points?: { lng: number; lat: number }[];
      bounds?: {
        ne: { lng: number; lat: number };
        sw: { lng: number; lat: number };
      };
    },
    buffer?: number,
    animated?: boolean,
    duration?: number
  ) => void
) {
  const geometryConfigCluster_l2: any = {
    selection: selection,
    geometry_type: "cluster",
    hex_column: "hex_8",
    cluster_set_name: "cs2",
    layer_config: {
      layer_name: `${prefix}_cluster_areas_2`,
      min_fill_value: 0,
      max_fill_value: 1000,
      fill_color: getFillColorsForSelection(selection),
      fill_opacity: [
        "interpolate",
        ["linear"],
        ["zoom"],
        6, 0.8,
        7, 0
      ],
      minzoom: 0,
      maxzoom: 7,
      tag: null,
    },
    source_type: "inline",
    op: "count",
    column: "year",
  };
  const geometryConfigCluster_l3 = {
    selection: selection,
    geometry_type: "cluster",
    hex_column: "hex_8",
    cluster_set_name: "cs3",
    layer_config: {
      layer_name: `${prefix}_cluster_areas_3`,
      min_fill_value: 0,
      max_fill_value: 1000,
      fill_color: getFillColorsForSelection(selection),
      fill_opacity: 0.8,
      minzoom: 5,
      maxzoom: 9,
      tag: null,
    },
    source_type: "inline",
    op: "count",
    column: "year",
  };
  const landmarkSelection = _.cloneDeep(selection);
  landmarkSelection["limit"] = 5000;
  const geometryConfigLandmarks: any = {
    selection: landmarkSelection,
    geometry_type: "landmark",
    layer_config: {
      layer_name: `${prefix}_landmarks`,
      min_zoom: 0,
      icon: getIconForSelection(selection),
      fill_color: "#d32f2f",
      max_zoom: 15,
      tag: null,
    },
    source_type: "inline",
    op: "count",
    column: "year",
  };

  //await all geometries
  const [
    geometryConfigCluster_l2_details,
    //geometryConfigCluster_l3_details,
    geometryConfigLandmarks_details,
  ] = await Promise.all([
    createGeometry(mapName, geometryConfigCluster_l2),
    //createGeometry(mapName, geometryConfigCluster_l3),
    createGeometry(mapName, geometryConfigLandmarks),
  ]);

  geometryConfigCluster_l2_details.layer.maxzoom = 8;
  //geometryConfigCluster_l3_details.layer.minzoom = 8;
  //geometryConfigCluster_l3_details.layer.maxzoom = 12;
  
  const geometryDetails = createDynamicLandmarkLayers(
    geometryConfigLandmarks_details.source,
    geometryConfigLandmarks_details.layer,
    10
  );

  const features = geometryConfigCluster_l2_details.source.data.features;
  
  if (features && features.length > 0 && fitToBounds) {
    // Initialize bounds with the first feature's coordinates
    let minLng = Infinity;
    let maxLng = -Infinity;
    let minLat = Infinity;
    let maxLat = -Infinity;
    
    // Find the bounding box by examining all feature geometries
    features.forEach((feature: any) => {
      if (feature.geometry && feature.geometry.coordinates) {
        // Handle different geometry types
        let coordinates: any[] = [];
        
        if (feature.geometry.type === 'Point') {
          coordinates = [feature.geometry.coordinates];
        } else if (feature.geometry.type === 'Polygon') {
          coordinates = feature.geometry.coordinates[0]; // Outer ring
        } else if (feature.geometry.type === 'MultiPolygon') {
          // Flatten all polygon coordinates
          coordinates = feature.geometry.coordinates.flatMap((poly: any[]) => poly[0]);
        }
        
        // Update bounds
        coordinates.forEach((coord: any) => {
          const lng = coord[0];
          const lat = coord[1];
          
          minLng = Math.min(minLng, lng);
          maxLng = Math.max(maxLng, lng);
          minLat = Math.min(minLat, lat);
          maxLat = Math.max(maxLat, lat);
        });
      }
    });
    
    // Create bounds object for fitToBounds
    const bounds = {
      ne: { lng: maxLng, lat: maxLat },
      sw: { lng: minLng, lat: minLat }
    };
    
    // Call fitToBounds with the calculated bounds
    if (minLng !== Infinity && maxLng !== -Infinity && minLat !== Infinity && maxLat !== -Infinity) {
      fitToBounds({ bounds }, 0.1, true);
    }
  }

  if (minZoomLandmarks > 0) {
    geometryDetails.layerConfigWithOutTitles.minzoom = minZoomLandmarks;
  }
  upsertLayer(
    geometryConfigCluster_l2_details.layer.id,
    geometryConfigCluster_l2_details.layer as any,
    geometryConfigCluster_l2_details.source,
    "papers"
  );
  /*upsertLayer(
    geometryConfigCluster_l3_details.layer.id,
    geometryConfigCluster_l3_details.layer as any,
    geometryConfigCluster_l3_details.source,
    "papers"
  );*/
  upsertLayer(
    geometryDetails.layerConfigWithTitles.id,
    geometryDetails.layerConfigWithTitles as any,
    geometryDetails.sourceConfig,
    "areas0"
  );
  upsertLayer(
    geometryDetails.layerConfigWithOutTitles.id,
    geometryDetails.layerConfigWithOutTitles as any,
    geometryDetails.sourceConfig,
    "areas0"
  );
}

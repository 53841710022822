import IViewport from "./interfaces/viewport";

// Determine the base URL dynamically based on the current hostname
const getBaseUrl = () => {
  // Get the current hostname (e.g., localhost, 10.0.0.8, etc.)
  const currentHostname = window.location.hostname;
  // Use the environment variable if set, otherwise construct URL with current hostname
  return process.env.REACT_APP_API_BASE_URL || `http://${currentHostname}:5000`;
};

const API_BASE_URL = getBaseUrl();

const API_ROUTES={
    searchPapers: (mapName: string, query:string, limit:number, bounds?:number[] | null) => `${API_BASE_URL}/search/${mapName}?q=${query}&limit=${limit}${bounds? "&bounds="+bounds.join(',') : ""}`,
    searchAuthors:(query:string) => ``,
    getCachedResponse:(cachingKey:string) => `${API_BASE_URL}/prompting/cached_response/${cachingKey}`,
    fetchAuthorDetails:(authorId:string) => `${API_BASE_URL}/authors/${authorId}/details`,
    fetchAuthorPapers:(mapName:string, authorId:string) => `${API_BASE_URL}/authors/${mapName}/${authorId}/papers`,
    fetchAuthorCitations:(mapName:string, authorId:string) => `${API_BASE_URL}/authors/${mapName}/${authorId}/citations`,
    fetchAuthorReferences:(mapName:string, authorId:string) => `${API_BASE_URL}/authors/${mapName}/${authorId}/references`,
    getMapConfig: (mapName: string) => `${API_BASE_URL}/maps/${mapName}`,
    getPaperDetailsWithCoordinates:(mapName:string,corpusid:string) => `${API_BASE_URL}/papers/${mapName}/${corpusid}`,
    getPaperDetails:(corpusid:string) => `${API_BASE_URL}/papers/${corpusid}`,
    getPaperCitations:(mapName:string,corpusid:string) => `${API_BASE_URL}/papers/${mapName}/${corpusid}/citations`,
    getPaperReferences:(mapName:string,corpusid:string) => `${API_BASE_URL}/papers/${mapName}/${corpusid}/references`,
    getPaperNearestNeighbors:(mapName:string,corpusid:string) => `${API_BASE_URL}/papers/${mapName}/${corpusid}/nearest_neighbors`,
    postViewportBookmark: () => `${API_BASE_URL}/bookmarks/viewport`,
    fetchClusterDetails: (mapName:string,clusterId:string) => `${API_BASE_URL}/clusters/${mapName}/${clusterId}`,
    postCreateContext: () => `${API_BASE_URL}/prompting/context`,
    postGenerateClusterPicture: (mapName: string, clusterId:string) => `${API_BASE_URL}/clusters/${mapName}/${clusterId}/images`,
    postGeneratePaperPicture: (mapName: string, corpusid:string) => `${API_BASE_URL}/papers/${mapName}/${corpusid}/images`,
    getPapersInViewport: (mapName: string, viewport: IViewport) => {
        // Extract the coordinates from the viewport object
        const minLng = viewport.sw.lng;
        const minLat = viewport.sw.lat;
        const maxLng = viewport.ne.lng;
        const maxLat = viewport.ne.lat;
    
        // Construct the URL with the min/max syntax
        return `${API_BASE_URL}/papers/viewport/${mapName}?minLng=${minLng}&minLat=${minLat}&maxLng=${maxLng}&maxLat=${maxLat}`;
    },
    getClustersInViewport: (mapName: string, viewport: IViewport) => 
        `${API_BASE_URL}/clusters/${mapName}/viewport?minLng=${viewport.sw.lng}&minLat=${viewport.sw.lat}&maxLng=${viewport.ne.lng}&maxLat=${viewport.ne.lat}`,
    getViewportBookmarks: (mapName:string) => `${API_BASE_URL}/bookmarks/viewport/${mapName}`,
    postFullTextPrompting: () => `${API_BASE_URL}/prompting/execute_context/text`,
    postFunctionCallingPrompting_old: () => `${API_BASE_URL}/prompting/execute_context/fc`,
    postFunctionCallingPrompting: () => `${API_BASE_URL}/prompting/execute_context/function_calling`,
    postCreateGridLayer: ()=>`${API_BASE_URL}/layers/statistics`,
    postCreateHeatmapLayer: ()=>`${API_BASE_URL}/layers/heatmap`,
    postCreateHexbinLayer: ()=>`${API_BASE_URL}/layers/hexbin`,
    postCreateClusterLabelsLayer: ()=>`${API_BASE_URL}/layers/cluster_labels`,
    postCreateClusterLayer: ()=>`${API_BASE_URL}/layers/cluster`,
    updateMapSourceJSON:(mapName:string)=>`${API_BASE_URL}/maps/${mapName}/source_json`,
    deleteSourceJSONLayer:(mapName:string, layerId:string)=>`${API_BASE_URL}/maps/${mapName}/source_json/${layerId}`,
    getAutocompleteSuggestions:(mapName:string, query:string, autocompleteType:'keywords' | 'authors' | 'journals' | 'institutions', size:number = 10)=>`${API_BASE_URL}/autocomplete/${mapName}?q=${query}&type=${autocompleteType}&size=${size}`,
    getPaperSelection:(mapName:string)=>`${API_BASE_URL}/papers/${mapName}/selection`,
    createPapersSelection:(mapName:string)=>`${API_BASE_URL}/selection/${mapName}`,
    listMaps:()=>`${API_BASE_URL}/maps/`,
    postCreateSelection:(mapName:string)=>`${API_BASE_URL}/selections/${mapName}`,
    getRandomAuthorId:(mapName:string)=>`${API_BASE_URL}/selection/${mapName}/random_author`,
    getRandomJournalId:(mapName:string)=>`${API_BASE_URL}/selection/${mapName}/random_journal`,
    getRandomPaperId:(mapName:string)=>`${API_BASE_URL}/selection/${mapName}/random_paper`,
    getRandomClusterId:(mapName:string)=>`${API_BASE_URL}/selection/${mapName}/random_cluster`,
    postCreateGeometrySelection:(mapName:string)=>`${API_BASE_URL}/geometry/${mapName}`,
    postCreateGeometry:(mapName:string)=>`${API_BASE_URL}/geometry/${mapName}`,
    getJournalDetails:(journalId:string)=>`${API_BASE_URL}/journals/${journalId}`,
    getUserProfile: () => `${API_BASE_URL}/user`,
    getUserHistory: () => `${API_BASE_URL}/user/history`,
    deleteUserHistory: (historyId: number) => `${API_BASE_URL}/user/history/${historyId}`,
    addUserHistory: () => `${API_BASE_URL}/user/history`,
    addBookmark: () => `${API_BASE_URL}/user/bookmarks`,
    deleteBookmark: (bookmarkId: number) => `${API_BASE_URL}/user/bookmarks/${bookmarkId}`,
}

export default API_ROUTES;
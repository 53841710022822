import "./LayerControl.scss";
import React, { useState, useEffect, useContext } from "react";
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { Edit, ExpandLess, ExpandMore, Delete } from "@mui/icons-material";
import { ILayer, ISourceJSON } from "../../interfaces/mapConfig";
import { useSnackbar } from "../../contexts/SnackbarContext";
import { updateMapSourceJSON, deleteSourceJSONLayer } from "../../requests";
import MapContext from "../../contexts/MapContext";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";

interface LayerControlProps {
  sourceJson: ISourceJSON;
  onLayersUpdate: (layers: ILayer[]) => void;
}

interface LayerItem {
  id: string;
  type: "layer";
  visible: boolean;
  layer: ILayer;
}

interface GroupItem {
  id: string;
  type: "group";
  collapsed: boolean;
  children: Item[];
}

type Item = LayerItem | GroupItem;

const LayerControl: React.FC<LayerControlProps> = ({
  sourceJson,
  onLayersUpdate,
}) => {
  const snackbar = useSnackbar();
  const { mapName } = useContext(MapContext);
  const [items, setItems] = useState<Item[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [layerToDelete, setLayerToDelete] = useState<string | null>(null);
  const [currentLayerIndex, setCurrentLayerIndex] = useState<string | null>(
    null
  );
  const [currentLayerJson, setCurrentLayerJson] = useState<string>("");
  const [isJsonInvalid, setIsJsonInvalid] = useState(false);

  useEffect(() => {
    const layersWithVisibility = sourceJson.layers.map((layer) => ({
      ...layer,
      visible: layer.visible ?? true,
    }));

    const groupMap: { [key: string]: GroupItem } = {};
    const newItems: Item[] = [];

    layersWithVisibility.forEach((layer) => {
      const groupName = layer.metadata?.group;
      const item: LayerItem = {
        id: layer.id,
        type: "layer",
        visible: layer.visible,
        layer: layer,
      };

      if (groupName) {
        if (!groupMap[groupName]) {
          const groupItem: GroupItem = {
            id: groupName,
            type: "group",
            collapsed: false,
            children: [],
          };
          groupMap[groupName] = groupItem;
          newItems.push(groupItem);
        }
        groupMap[groupName].children.push(item);
      } else {
        newItems.push(item);
      }
    });

    setItems(newItems);
  }, [sourceJson]);

  const handleDeleteLayer = async (layerId: string) => {
    setLayerToDelete(layerId);
    setIsDeleteDialogOpen(true);
  };

  const confirmDeleteLayer = async () => {
    if (layerToDelete) {
      try {
        const updatedSourceJson = await deleteSourceJSONLayer(mapName, layerToDelete);
        const newItems = items.filter(item => {
          if (item.type === "layer") {
            return item.id !== layerToDelete;
          } else {
            item.children = item.children.filter(child => 
              child.type === "layer" && child.id !== layerToDelete
            );
            return true;
          }
        });
        setItems(newItems);
        onLayersUpdate(flattenItems(newItems));
        snackbar.showSnackbar("Layer deleted successfully", "success");
      } catch (e) {
        snackbar.showSnackbar("Failed to delete layer", "error");
      }
    }
    setIsDeleteDialogOpen(false);
    setLayerToDelete(null);
  };

  const toggleGroupVisibility = (groupId: string) => {
    const updateVisibility = (items: Item[]): Item[] => {
      return items.map((item) => {
        if (item.type === "group") {
          if (item.id === groupId) {
            const newVisible = !isAllChildrenVisible(item);
            const updatedChildren = item.children.map((child) => {
              if (child.type === "layer") {
                return {
                  ...child,
                  visible: newVisible,
                  layer: {
                    ...child.layer,
                    visible: newVisible,
                  },
                };
              } else {
                return {
                  ...child,
                  children: updateVisibility(child.children),
                };
              }
            });
            return {
              ...item,
              children: updatedChildren,
            };
          } else {
            return {
              ...item,
              children: updateVisibility(item.children),
            };
          }
        } else {
          return item;
        }
      });
    };

    const newItems = updateVisibility(items);
    setItems(newItems);
    onLayersUpdate(flattenItems(newItems));
  };

  const toggleLayerVisibility = (layerId: string) => {
    const updateVisibility = (items: Item[]): Item[] => {
      return items.map((item) => {
        if (item.type === "layer" && item.id === layerId) {
          const newVisible = !item.visible;
          return {
            ...item,
            visible: newVisible,
            layer: {
              ...item.layer,
              visible: newVisible,
            },
          };
        } else if (item.type === "group") {
          return {
            ...item,
            children: updateVisibility(item.children),
          };
        } else {
          return item;
        }
      });
    };

    const newItems = updateVisibility(items);
    setItems(newItems);
    onLayersUpdate(flattenItems(newItems));
  };

  const toggleGroupCollapse = (groupId: string) => {
    const updateCollapse = (items: Item[]): Item[] => {
      return items.map((item) => {
        if (item.type === "group") {
          if (item.id === groupId) {
            return {
              ...item,
              collapsed: !item.collapsed,
            };
          } else {
            return {
              ...item,
              children: updateCollapse(item.children),
            };
          }
        } else {
          return item;
        }
      });
    };

    const newItems = updateCollapse(items);
    setItems(newItems);
  };

  const openEditDialog = (layerId: string) => {
    const findLayer = (items: Item[]): LayerItem | null => {
      for (const item of items) {
        if (item.type === "layer" && item.id === layerId) {
          return item;
        } else if (item.type === "group") {
          const found = findLayer(item.children);
          if (found) return found;
        }
      }
      return null;
    };

    const layerItem = findLayer(items);
    if (layerItem) {
      setCurrentLayerIndex(layerItem.id);
      setCurrentLayerJson(JSON.stringify(layerItem.layer, null, 2));
      setIsDialogOpen(true);
    }
  };

  const closeEditDialog = () => {
    setIsDialogOpen(false);
    setIsJsonInvalid(false);
    setCurrentLayerIndex(null);
    setCurrentLayerJson("");
  };

  const handleJsonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentLayerJson(event.target.value);
    try {
      JSON.parse(event.target.value);
      setIsJsonInvalid(false);
    } catch (e) {
      setIsJsonInvalid(true);
    }
  };

  const handleSubmit = () => {
    if (!isJsonInvalid && currentLayerIndex !== null) {
      const updatedLayerData = JSON.parse(currentLayerJson);

      const updateLayer = (items: Item[]): Item[] => {
        return items.map((item) => {
          if (item.type === "layer" && item.id === currentLayerIndex) {
            return {
              ...item,
              layer: updatedLayerData,
            };
          } else if (item.type === "group") {
            return {
              ...item,
              children: updateLayer(item.children),
            };
          } else {
            return item;
          }
        });
      };

      const newItems = updateLayer(items);
      setItems(newItems);
      onLayersUpdate(flattenItems(newItems));
      closeEditDialog();
    }
  };

  const saveSourceJSON = async () => {
    try {
      await updateMapSourceJSON(mapName, sourceJson);
      snackbar.showSnackbar("Source JSON updated successfully", "success");
    } catch (e) {
      snackbar.showSnackbar("Failed to update source JSON", "error");
    }
  };

  const flattenItems = (items: Item[]): ILayer[] => {
    let layers: ILayer[] = [];
    items.forEach((item) => {
      if (item.type === "layer") {
        layers.push(item.layer);
      } else if (item.type === "group") {
        layers = layers.concat(flattenItems(item.children));
      }
    });
    return layers;
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const sourceDroppableId = result.source.droppableId;
    const destinationDroppableId = result.destination.droppableId;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const newItems = [...items];

    const findListById = (droppableId: string, items: Item[]): Item[] | null => {
      if (droppableId === "root") {
        return items;
      }

      for (const item of items) {
        if (item.type === "group" && item.id === droppableId) {
          return item.children;
        } else if (item.type === "group") {
          const result = findListById(droppableId, item.children);
          if (result) return result;
        }
      }
      return null;
    };

    const sourceList = findListById(sourceDroppableId, newItems);
    const destinationList = findListById(destinationDroppableId, newItems);

    if (!sourceList || !destinationList) return;

    const [movedItem] = sourceList.splice(sourceIndex, 1);
    destinationList.splice(destinationIndex, 0, movedItem);

    if (
      movedItem.type === "layer" &&
      sourceDroppableId !== destinationDroppableId
    ) {
      const newGroupId =
        destinationDroppableId === "root" ? undefined : destinationDroppableId;
      if (newGroupId) {
        movedItem.layer.metadata = {
          ...movedItem.layer.metadata,
          group: newGroupId,
        };
      } else {
        if (movedItem.layer.metadata) {
          delete movedItem.layer.metadata.group;
        }
      }
    }

    setItems(newItems);
    onLayersUpdate(flattenItems(newItems));
  };

  const isAllChildrenVisible = (group: GroupItem): boolean => {
    return group.children.every((child) => {
      if (child.type === "layer") {
        return child.visible;
      } else {
        return isAllChildrenVisible(child);
      }
    });
  };

  const isSomeChildrenVisible = (group: GroupItem): boolean => {
    return group.children.some((child) => {
      if (child.type === "layer") {
        return child.visible;
      } else {
        return isSomeChildrenVisible(child);
      }
    });
  };

  const renderItems = (items: Item[], droppableId: string = "root") => {
    return (
      <Droppable droppableId={droppableId} type="ITEM">
        {(provided) => (
          <List ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                      ...provided.draggableProps.style,
                      backgroundColor: snapshot.isDragging
                        ? "#f0f0f0"
                        : "inherit",
                    }}
                  >
                    {item.type === "group" ? (
                      <div>
                        <ListItem>
                          <div
                            {...provided.dragHandleProps}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Checkbox
                              checked={isAllChildrenVisible(item)}
                              indeterminate={
                                !isAllChildrenVisible(item) && isSomeChildrenVisible(item)
                              }
                              onChange={() => toggleGroupVisibility(item.id)}
                            />
                            <ListItemText primary={item.id} />
                            <IconButton onClick={() => toggleGroupCollapse(item.id)}>
                              {item.collapsed ? <ExpandMore /> : <ExpandLess />}
                            </IconButton>
                          </div>
                        </ListItem>
                        {!item.collapsed && (
                          <div style={{ paddingLeft: "20px" }}>
                            {renderItems(item.children, item.id)}
                          </div>
                        )}
                      </div>
                    ) : (
                      <ListItem>
                        <div
                          {...provided.dragHandleProps}
                          style={{ display: "flex", alignItems: "center", width: "100%" }}
                        >
                          <Checkbox
                            checked={item.visible}
                            onChange={() => toggleLayerVisibility(item.id)}
                          />
                          <ListItemText primary={item.id} />
                          <ListItemSecondaryAction>
                            <IconButton onClick={() => openEditDialog(item.id)}>
                              <Edit />
                            </IconButton>
                            <IconButton onClick={() => handleDeleteLayer(item.id)}>
                              <Delete />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </div>
                      </ListItem>
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    );
  };

  return (
    <div className="layer-control">
      <DragDropContext onDragEnd={handleDragEnd}>
        {renderItems(items)}
      </DragDropContext>

      <Button onClick={saveSourceJSON}>Update Source JSON</Button>
      
      <Dialog open={isDialogOpen} onClose={closeEditDialog}
        PaperProps={{
          style: {
            width: "50%",
            maxWidth: "none",
          },
        }}
      >
        <DialogTitle>Edit Layer JSON</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={20}
            variant="outlined"
            value={currentLayerJson}
            onChange={handleJsonChange}
            error={isJsonInvalid}
            helperText={isJsonInvalid ? "Invalid JSON" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditDialog}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={isJsonInvalid}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this layer?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDeleteLayer} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LayerControl;

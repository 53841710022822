import "./HoverStickyPopup.scss";
import { Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import Skeleton from "@mui/material/Skeleton";
import { useContext, useEffect, useRef, useState } from "react";
import { fetchPaperDetails } from "../../requests";
import MapContext, { IHoveredPaper } from "../../contexts/MapContext";
import IAuthorMeta from "../../interfaces/authorMeta";
import IJournalMeta from "../../interfaces/journalMeta";
import CitationCountDisplay from "../paper/CitationCountDisplay";
import YearDisplay from "../paper/YearDisplay";
import { Divider } from "@mui/material";
import { useSnackbar } from "../../contexts/SnackbarContext";
const SHOW_BOOKMARK_BUTTON = false;
export default function PaperStickyHoverPopup({
  hoveredPaper,
}: {
  hoveredPaper: IHoveredPaper;
}) {
  const { mapName, setSelectedCorpusId, setStickyHoveredPaper } =
    useContext(MapContext);
  const snackbar = useSnackbar();
  const loadingPaperIdRef = useRef<number | null>(null);

  useEffect(() => {
    if (hoveredPaper && !hoveredPaper.meta) {
      const paperId = hoveredPaper.id;
      loadingPaperIdRef.current = paperId;
      fetchPaperDetails(paperId.toString(), [
        "citationcount",
        "year",
        "authors",
        "tldr",
        "title"
      ])
        .then((details) => {
          if (loadingPaperIdRef.current === paperId) {
            setStickyHoveredPaper({ ...hoveredPaper, meta: details });
          }
        })
        .catch((e) => {
          snackbar.showSnackbar("Error fetching paper details", "error");
        });
      return () => {
        loadingPaperIdRef.current = null;
      };
    }
  }, [hoveredPaper]);

  // When the header is clicked, we call setSelectedCorpusId to select the paper.
  const handleHeaderClick = () => {
    setSelectedCorpusId(hoveredPaper.id.toString());
  };

  // Close button clears the hover state.
  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the header click
    e.preventDefault(); // Prevent any default behavior
    console.log("close button clicked");
    setStickyHoveredPaper(null);
  };

  const getAuthorString = (
    authors: IAuthorMeta[] | undefined,
    maxCharacters: number = 50
  ) => {
    if (!authors || authors.length === 0) {
      return "authors unknown";
    }

    if (authors.length === 1) {
      const authorName = authors[0].name;
      if (authorName.length > maxCharacters) {
        return authorName.slice(0, maxCharacters) + "...";
      }
      return authorName;
    } else {
      const firstAuthor = authors[0].name;
      const suffix = " et al.";
      if (firstAuthor.length + suffix.length > maxCharacters) {
        return (
          firstAuthor.slice(0, maxCharacters - suffix.length) + "..." + suffix
        );
      }
      return firstAuthor + suffix;
    }
  };

  const getJournalString = (
    journal: IJournalMeta | undefined,
    maxCharacters: number = 50
  ) => {
    if (!journal || !journal.name) {
      return "";
    }

    const prefix = "(";
    const suffix = ")";
    const availableChars = maxCharacters - prefix.length - suffix.length;

    if (journal.name.length > availableChars) {
      return `${prefix}${journal.name.slice(0, availableChars)}...${suffix}`;
    }
    return `${prefix}${journal.name}${suffix}`;
  };

  const handleShowDetails = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the header click
    console.log("show details");
    setSelectedCorpusId(hoveredPaper.id.toString());
    setStickyHoveredPaper(null);
  };
  const handleBookmark = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the header click
    // Add bookmark functionality here
    snackbar.showSnackbar("Paper bookmarked", "success");
  };

  return (
    <>
      <div
        className={`popupHoverPaper type-${hoveredPaper.type}`}
        onClick={handleHeaderClick}
        style={{ cursor: "pointer", position: "relative" }}
      >
        {/* Close button */}
        <div 
          style={{ 
            position: "absolute", 
            top: "5px", 
            right: "5px", 
            zIndex: 100 
          }}
        >
          <IconButton
            size="small"
            onClick={handleClose}
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              padding: "4px",
              pointerEvents: "auto",
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>

        <div className="paperTitle">{hoveredPaper.meta?.title || hoveredPaper.title}</div>
        <div className="meta">
          {!hoveredPaper.meta ? (
            <>
              <Skeleton variant="text" className="skeleton-text" width="100%" />
              <Skeleton variant="text" className="skeleton-text" width="100%" />
            </>
          ) : (
            <>
              <div className="metaRow">
                <YearDisplay year={hoveredPaper.meta.year} />
                <CitationCountDisplay
                  citationCount={hoveredPaper.meta.citationcount}
                />
              </div>
              <div className="metaRow">
                <div>{getAuthorString(hoveredPaper.meta.authors)}</div>
                <div>{getJournalString(hoveredPaper.meta.journal)}</div>
              </div>
              {hoveredPaper.meta.tldr && (
                <div>
                  <Divider />
                  <div style={{ textAlign: "left" }}>
                    <b>TLDR</b>
                    <span
                      style={{
                        paddingLeft: "5px",
                        fontSize: "11px",
                      }}
                    >
                      {hoveredPaper.meta.tldr}
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div
        style={{
          borderTop: "1px solid #e0e0e0",
          display: "flex",
          marginTop: "8px",
          pointerEvents: "auto", // Ensure clicks are captured
          zIndex: 20, // Higher z-index to ensure it's above other elements
        }}
      >
        <div
          className="hoverInfo"
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            textAlign: "center",
            padding: "6px",
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            borderRadius: "4px",
            flex: "70%",
            cursor: "pointer",
            transition: "background-color 0.2s",
            height: "28px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
            position: "relative",
            marginRight: "0px",
            borderTopRightRadius: "0",
            borderBottomRightRadius: "0",
          }}
          onClick={handleShowDetails}
          title="View paper details"
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.1)"}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.05)"}
        >
          Show Details
        </div>
        {SHOW_BOOKMARK_BUTTON && <div
          className="bookmarkButton"
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            textAlign: "center",
            padding: "6px",
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            borderRadius: "4px",
            flex: "30%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            transition: "background-color 0.2s",
            height: "28px",
            boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
          }}
          onClick={handleBookmark}
          title="Bookmark this paper"
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.1)"}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.05)"}
        >
          <BookmarkIcon fontSize="small" />
        </div>}
      </div>
    </>
  );
}

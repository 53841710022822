import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { AutoSizer, List, ListRowProps } from "react-virtualized";
import MapContext, { HoveredPaperType } from "../../contexts/MapContext";
import IPaperMeta from "../../interfaces/paperMeta";
import IPaperSelection from "../../interfaces/paperSelection";
import './PaperList.scss';
import PaperListItem from "./PaperListItem";
import { Alert, Button, ButtonGroup, Tooltip, ToggleButton, IconButton, Popover } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Group, GroupWork } from "@mui/icons-material";
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import { createPapersSelection, getPaperSelection } from "../../requests";
import SkeletonPaperListItem from "./SkeletonPaperListItem";
import { IClusterMetaSmall } from "../../interfaces/clusterMeta";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { computeBoundingBox } from "../../geometryUtils";
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import InfoIcon from '@mui/icons-material/Info';
import _ from "lodash";
import HoveredPaperListItem from "./HoveredPaperListItem";

// Define additional types
interface SortingOption {
  field: 'citationcount' | 'year' | 'search_score' | string;
  label: string;
}

interface PaperListWithPapersProps {
  papers: IPaperMeta[];
  type: HoveredPaperType;
  selection?: never;
  limit?: number;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  customSortingOptions?: SortingOption[];
  showDummyLoading?: boolean;
  listInformation?: string;
  clusterMetas?: Record<number, IClusterMetaSmall> | null;
  emptyMessage?: string;
}

interface PaperListWithSelectionProps {
  selection: IPaperSelection;
  type: HoveredPaperType;
  papers?: never;
  limit?: number;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  customSortingOptions?: SortingOption[];
  showDummyLoading?: boolean;
  listInformation?: string;
  clusterMetas?: Record<number, IClusterMetaSmall>;
  emptyMessage?: string;
}

type PaperListProps = PaperListWithPapersProps | PaperListWithSelectionProps;

interface GroupedCluster {
  clusterId: number;
  label: string;
  papers: IPaperMeta[];
  isExpanded: boolean;
}

type FlattenedListItem = GroupedCluster | IPaperMeta;

export default function PaperList(props: PaperListProps) {
  const {
    type,
    limit,
    sortBy: initialSortBy,
    sortDirection: initialSortDirection = 'desc',
    customSortingOptions,
    showDummyLoading = false,
    listInformation,
    clusterMetas: propClusterMetas,
    emptyMessage = "No Papers could be found"
  } = props;

  const papers = 'papers' in props ? props.papers : undefined;
  const selection = 'selection' in props ? props.selection : undefined;
  const { setSelectedCorpusId, mapName, fitToBounds, setSelectedCluster } = useContext(MapContext);
  
  // State for fetched papers
  const [fetchedPapers, setFetchedPapers] = useState<IPaperMeta[]>([]);
  
  // State for fetched clusterMetas when in selection mode
  const [fetchedClusterMetas, setFetchedClusterMetas] = useState<Record<number, IClusterMetaSmall> | null>(null);
  
  // State for loading and error
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Determine if selection prop is provided
  const isSelectionMode = selection !== undefined;

  // Sorting state
  const [currentSortBy, setCurrentSortBy] = useState<string>(
    initialSortBy || (customSortingOptions && customSortingOptions[0]?.field) || 'citationcount'
  );
  const [currentSortDirection, setCurrentSortDirection] = useState<'asc' | 'desc'>(
    initialSortDirection
  );

  // Grouping state
  const [groupByCluster, setGroupByCluster] = useState<boolean>(false);
  const [expandedClusters, setExpandedClusters] = useState<Set<number>>(new Set());

  // Store previous selection to compare for changes
  const [prevSelection, setPrevSelection] = useState<IPaperSelection | undefined>(undefined);

  // Global popover state – holds the paper, its type and the original anchor coordinates.
  const [popoverInfo, setPopoverInfo] = useState<{
    paper: IPaperMeta;
    type: HoveredPaperType;
    anchorPosition: { top: number; left: number };
  } | null>(null);

  // New: Ref to access the popover paper element
  const popoverPaperRef = useRef<HTMLDivElement>(null);
  // New: State to store the adjusted anchor position
  const [adjustedAnchorPosition, setAdjustedAnchorPosition] = useState<{ top: number; left: number } | null>(null);

  // Effect to update popover position once the popover content renders or changes size
  useEffect(() => {
    if (popoverInfo) {
      const updateAnchorPosition = () => {
        if (popoverPaperRef.current) {
          const popoverRect = popoverPaperRef.current.getBoundingClientRect();
          // Calculate the bottom of the popover if it were rendered at the current anchor
          const popoverBottom = popoverInfo.anchorPosition.top + popoverRect.height;
          // If popover extends beyond the window height, adjust the top position
          if (popoverBottom > window.innerHeight) {
            const newTop = window.innerHeight - popoverRect.height - 10; // 10px margin from bottom
            setAdjustedAnchorPosition({ top: newTop, left: popoverInfo.anchorPosition.left });
          } else {
            setAdjustedAnchorPosition(popoverInfo.anchorPosition);
          }
        }
      };

      // Use requestAnimationFrame to ensure the DOM is updated before measuring
      requestAnimationFrame(updateAnchorPosition);
    } else {
      setAdjustedAnchorPosition(null);
    }
  }, [popoverInfo]);

  // Effect to recalc the popover position on window resize
  useEffect(() => {
    const handleResize = () => {
      if (popoverInfo && popoverPaperRef.current) {
        const popoverRect = popoverPaperRef.current.getBoundingClientRect();
        if (popoverInfo.anchorPosition.top + popoverRect.height > window.innerHeight) {
          const newTop = window.innerHeight - popoverRect.height - 10;
          setAdjustedAnchorPosition({ top: newTop, left: popoverInfo.anchorPosition.left });
        } else {
          setAdjustedAnchorPosition(popoverInfo.anchorPosition);
        }
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [popoverInfo]);

  useEffect(() => {
    if (isSelectionMode && selection) {
      // Check if selection has changed
      const hasSelectionChanged = !prevSelection || 
        !_.isEqual(prevSelection, selection);
      
      if (hasSelectionChanged) {
        const fetchPapers = async () => {
          setLoading(true);
          setError(null);
          try {
            const selectionCopy = _.cloneDeep(selection);
            // Make sure selection.fields includes authors, title, citationcount, and year
            selectionCopy.fields = selectionCopy.fields || [];
            selectionCopy.fields.push("authors", "title", "citationcount", "year");
            selectionCopy.limit = 100;
            const papersData = await createPapersSelection(mapName, selectionCopy);
            setFetchedPapers(papersData as IPaperMeta[]);
         //   setFetchedClusterMetas(clusterMetasByClusterId);
          } catch (err: any) {
            console.error("Error fetching papers:", err);
            setError("Failed to load papers.");
          } finally {
            setLoading(false);
          }
        };
        fetchPapers();
        // Update previous selection
        setPrevSelection(selection);
      }
    }
  }, [selection, isSelectionMode, mapName, prevSelection]);

  // Handle sort change
  const handleSortChange = (field: string) => {
    if (currentSortBy === field) {
      // Toggle sort direction
      setCurrentSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      // Set new sort field and default to descending
      setCurrentSortBy(field);
      setCurrentSortDirection('desc');
    }
    // If grouping was active, disable it
    if (groupByCluster) {
      setGroupByCluster(false);
    }
  };

  // Handle grouping toggle
  const handleGroupByClusterToggle = () => {
    setGroupByCluster((prev) => !prev);
    // If grouping is being enabled, clear current sorting and reset expanded clusters
    if (!groupByCluster) {
      setCurrentSortBy('');
      setCurrentSortDirection('desc');
      setExpandedClusters(new Set());
    }
  };

  // Handle cluster expand/collapse
  const toggleClusterExpand = (clusterId: number) => {
    const isCurrentlyExpanded = expandedClusters.has(clusterId);
    setExpandedClusters((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(clusterId)) {
        newSet.delete(clusterId);
      } else {
        newSet.add(clusterId);
      }
      return newSet;
    });

    // Only focus on cluster if it's being expanded
    if (!isCurrentlyExpanded) {
      focusOnCluster(clusterId);
    }
  };

  // Focus on cluster (Assuming a function exists in MapContext)
  const focusOnCluster = (clusterId: number) => {
    // Determine which clusterMetas to use
    const effectiveClusterMetas = isSelectionMode ? fetchedClusterMetas : propClusterMetas;

    if (effectiveClusterMetas && effectiveClusterMetas[clusterId]?.bounding_box?.coordinates) {
      const coordinates = effectiveClusterMetas[clusterId].bounding_box.coordinates[0];
      fitToBounds({
        bounds: {
          sw: { lng: coordinates[0][0], lat: coordinates[0][1] },
          ne: { lng: coordinates[2][0], lat: coordinates[2][1] }
        }
      }, 0.5, true, 2000);
    }
  };

  // Determine which list of papers to use and apply sorting/limiting
  const sortedPapers = useMemo(() => {
    let processedPapers = isSelectionMode ? fetchedPapers : papers || [];

    if (!groupByCluster && currentSortBy) {
      processedPapers = [...processedPapers].sort((a, b) => {
        const aValue = a[currentSortBy as keyof IPaperMeta] || 0;
        const bValue = b[currentSortBy as keyof IPaperMeta] || 0;

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return currentSortDirection === 'desc'
            ? bValue.localeCompare(aValue)
            : aValue.localeCompare(bValue);
        }

        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return currentSortDirection === 'desc' ? bValue - aValue : aValue - bValue;
        }

        return 0;
      });
    }

    if (!groupByCluster && limit && limit > 0) {
      processedPapers = processedPapers.slice(0, limit);
    }

    return processedPapers;
  }, [isSelectionMode, fetchedPapers, papers, currentSortBy, currentSortDirection, limit, groupByCluster]);

  // Determine which clusterMetas to use
  const effectiveClusterMetas = isSelectionMode ? fetchedClusterMetas : propClusterMetas;

  // Grouped papers by cluster
  const groupedClusters: GroupedCluster[] = useMemo(() => {
    if (!groupByCluster || !effectiveClusterMetas) return [];

    const clusterMap: Record<number, IPaperMeta[]> = {};

    sortedPapers.forEach((paper) => {
      if (paper.cluster_id && effectiveClusterMetas[paper.cluster_id]) {
        if (!clusterMap[paper.cluster_id]) {
          clusterMap[paper.cluster_id] = [];
        }
        clusterMap[paper.cluster_id].push(paper);
      } else {
        // Handle papers without a cluster_id by assigning them to a special cluster, e.g., cluster_id: -1
        if (!clusterMap[-1]) {
          clusterMap[-1] = [];
        }
        clusterMap[-1].push(paper);
      }
    });

    // Convert to array and sort clusters by frequency
    const clustersArray: GroupedCluster[] = Object.keys(clusterMap).map((clusterIdStr) => {
      const clusterId = parseInt(clusterIdStr, 10);
      const papersInCluster = clusterMap[clusterId];
      const label = clusterId === -1 ? 'Unclustered' : effectiveClusterMetas[clusterId]?.label || `Cluster ${clusterId}`;
      return {
        clusterId,
        label,
        papers: papersInCluster,
        isExpanded: expandedClusters.has(clusterId),
      };
    });

    clustersArray.sort((a, b) => b.papers.length - a.papers.length);

    return clustersArray;
  }, [groupByCluster, effectiveClusterMetas, sortedPapers, expandedClusters]);

  // Flattened list when grouping is active
  const flattenedList: FlattenedListItem[] = useMemo(() => {
    if (!groupByCluster) return sortedPapers;

    const items: FlattenedListItem[] = [];
    groupedClusters.forEach((cluster) => {
      items.push(cluster);
      if (cluster.isExpanded) {
        items.push(...cluster.papers);
      }
    });
    return items;
  }, [groupByCluster, sortedPapers, groupedClusters]);

  // This callback will be passed to each PaperListItem.
  // When a paper is clicked, we capture its bounding rectangle and update the global popover state.
  const handlePaperClick = (
    paper: IPaperMeta,
    type: HoveredPaperType,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setPopoverInfo({
      paper,
      type,
      anchorPosition: { top: rect.top, left: rect.left },
    });
  };

  // Row renderer for react-virtualized
  const rowRenderer = ({ key, index, style }: ListRowProps) => {
    if (showDummyLoading || (isSelectionMode && loading)) {
      // Render skeletons while loading
      return (
        <div key={key} style={style} className="paperListItemParent">
          <SkeletonPaperListItem />
        </div>
      );
    }

    if (isSelectionMode && error) {
      // Render error message
      return (
        <div
          className="paperListItemParent error"
          style={style}
          key={key}
        >
          <Alert severity="error">{error}</Alert>
        </div>
      );
    }

    if (groupByCluster && effectiveClusterMetas) {
      const item = flattenedList[index];
      
      if ('clusterId' in item) {
        // Render cluster header
        return (
          <div 
            key={key} 
            style={style} 
            className="paperListclusterHeader"
            onClick={() => {
              toggleClusterExpand(item.clusterId);
              focusOnCluster(item.clusterId);
            }}
          >
            <div className="paperListclusterHeaderContent">
              {item.clusterId !== -1 && ( // Don't allow collapsing 'Unclustered'
                <Tooltip title={!item.isExpanded ? `Expand ${item.label}` : `Collapse ${item.label}`}>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      toggleClusterExpand(item.clusterId);
                      e.stopPropagation();
                    }}
                    aria-label={!item.isExpanded ? `Expand cluster ${item.label}` : `Collapse cluster ${item.label}`}
                  >
                    {!item.isExpanded ? <ExpandMoreIcon fontSize="small" /> : <ExpandLessIcon fontSize="small" />}
                  </IconButton>
                </Tooltip>
              )}
              <div 
                className="paperListclusterLabel clickableLabel"
                role="button"
                tabIndex={0}
                aria-label={`Focus on cluster ${item.label}`}
              >
                {item.label} ({item.papers.length})
              </div>
              <div className="paperListclusterActions">
                <Tooltip title={`Focus on ${item.label}`}>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      focusOnCluster(item.clusterId);
                    }}
                    aria-label={`Focus on cluster ${item.label}`}
                  >
                    <FilterCenterFocusIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={`View details for ${item.label}`}>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (effectiveClusterMetas && effectiveClusterMetas[item.clusterId]) {
                        setSelectedCluster({
                          cluster_id: effectiveClusterMetas[item.clusterId].cluster_id,
                          bounds: computeBoundingBox(effectiveClusterMetas[item.clusterId].bounding_box.coordinates),
                          geometry: effectiveClusterMetas[item.clusterId].geometry as any
                        });
                      }
                    }}
                    aria-label={`View details for cluster ${item.label}`}
                  >
                    <ArrowForwardIosIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      } else {
        // Render paper item
        const paper = item as IPaperMeta;
        return (
          <div
            className="paperListItemParent"
            style={style}
            key={key}
          >
            <PaperListItem paper={paper} type={type} onPaperClick={handlePaperClick} />
          </div>
        );
      }
    }

    // When not grouping, render paper items directly
    const paper = flattenedList[index] as IPaperMeta;

    return (
      <div
        className="paperListItemParent"
        style={style}
        key={key}
      >
        <PaperListItem paper={paper} type={type} onPaperClick={handlePaperClick} />
      </div>
    );
  };

  // Determine if papers are empty after loading
  const isPapersEmpty = !loading && !showDummyLoading && flattenedList.length === 0;

  // Determine row count based on loading state and empty state
  const rowCount = showDummyLoading || (isSelectionMode && loading) 
    ? 10 
    : isPapersEmpty 
      ? 0 
      : flattenedList.length;

  // Determine if grouping is possible
  const canGroupByCluster = effectiveClusterMetas && sortedPapers.some(paper => paper.cluster_id && effectiveClusterMetas[paper.cluster_id]);

  // Check if we're on a mobile device
  const isMobile = window.innerWidth <= 768;

  return (
    <div className="paperListContainer">
      {listInformation && (
        <div style={{
          padding: isMobile ? '4px 8px' : '8px 12px',
          backgroundColor: '#f5f5f5',
          borderBottom: '1px solid #e0e0e0',
          fontSize: '14px',
          color: '#666',
          fontStyle: 'italic'
        }}>
          {listInformation}
        </div>
      )}
      {(customSortingOptions || canGroupByCluster) && sortedPapers.length > 0 && !showDummyLoading && (
        <div className="sortingToolbar" style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          padding: isMobile ? '4px 8px' : '8px 12px',
          //flexWrap: isMobile ? 'wrap' : 'nowrap'
        }}>
          <div style={{ 
            display: 'flex', 
            alignItems: 'center',
            marginBottom: isMobile ? '4px' : '0'
          }}>
            {customSortingOptions && (
              <>
                <span style={{ 
                  marginRight: isMobile ? '4px' : '8px', 
                  fontSize: isMobile ? '12px' : '14px' 
                }}>Sort by:</span>
                <ButtonGroup 
                  variant="outlined" 
                  size="small" 
                  style={{ 
                    transform: isMobile ? 'scale(0.7)' : 'scale(0.8)', 
                    transformOrigin: 'left' 
                  }}
                >
                  {customSortingOptions.map((option) => (
                    <Tooltip key={option.field} title={`Sort by ${option.label}`}>
                      <Button
                        onClick={() => handleSortChange(option.field)}
                        color={currentSortBy === option.field ? "primary" : "inherit"}
                        endIcon={
                          currentSortBy === option.field ? (
                            currentSortDirection === 'asc' ? (
                              <ExpandMoreIcon fontSize="small" />
                            ) : (
                              <ExpandLessIcon fontSize="small" />
                            )
                          ) : null
                        }
                        style={{ 
                          padding: isMobile ? '2px 4px' : '4px 8px',
                          //minWidth: isMobile ? 'auto' : undefined
                        }}
                      >
                        {option.label}
                      </Button>
                    </Tooltip>
                  ))}
                </ButtonGroup>
              </>
            )}
          </div>
          {canGroupByCluster && (
            <Tooltip title={groupByCluster ? "Ungroup by Cluster" : "Group papers by Cluster"}>
              <ToggleButton
                value="group"
                selected={groupByCluster}
                onChange={handleGroupByClusterToggle}
                size="small"
                aria-label="Group by Cluster"
                style={{ 
                  padding: isMobile ? '2px' : '4px',
                  marginLeft: isMobile ? 'auto' : undefined
                }}
              >
                {groupByCluster ? <FormatAlignJustifyIcon fontSize="small" /> : <FormatIndentDecreaseIcon fontSize="small" />}
              </ToggleButton>
            </Tooltip>
          )}
        </div>
      )}
      {isPapersEmpty ? (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: isMobile ? '20px 10px' : '40px 20px',
          color: '#666',
          textAlign: 'center',
          height: 'calc(100% - 50px)'
        }}>
          <InfoIcon style={{ fontSize: isMobile ? 36 : 48, color: '#999', marginBottom: isMobile ? 8 : 16 }} />
          <div>{emptyMessage}</div>
        </div>
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="virtualizedList"
              width={width}
              height={height - ((customSortingOptions || canGroupByCluster) ? 50 : 0) - (listInformation ? 37 : 0)}
              rowCount={rowCount}
              rowHeight={({ index }) => {
                if (showDummyLoading || (isSelectionMode && loading)) {
                  return isMobile ? 120 : 100;
                }
                if (groupByCluster && effectiveClusterMetas) {
                  const item = flattenedList[index];
                  if ('clusterId' in item) {
                    return isMobile ? 50 : 50;
                  }
                }
                return isMobile ? 120 : 100;
              }}
              rowRenderer={rowRenderer}
              overscanRowCount={5}
            />
          )}
        </AutoSizer>
      )}

      {/* Global Popover rendered at the PaperList level */}
      <Popover
        open={Boolean(popoverInfo)}
        onClose={() => setPopoverInfo(null)}
        anchorReference="anchorPosition"
        anchorPosition={adjustedAnchorPosition || (popoverInfo ? popoverInfo.anchorPosition : { top: 0, left: 0 })}
        hideBackdrop={true}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        //@ts-ignore
        ModalProps={{
          disableAutoFocus: true,
          disableEnforceFocus: true,
          disableRestoreFocus: true,
        }}
        sx={{ pointerEvents: "none" }}
        PaperProps={{
          // Attach the ref here to measure the paper element.
          ref: popoverPaperRef,
          sx: {
            pointerEvents: "auto",
            zIndex: 1300,
            maxWidth: isMobile ? 280 : 350,
            borderRadius: "8px",
            marginLeft: isMobile ? "10px" : "390px",
          },
        }}
      >
        {popoverInfo && (
          <HoveredPaperListItem
            hoveredPaper={{
              id: popoverInfo.paper.id,
              title: popoverInfo.paper.title,
              type: popoverInfo.type,
              source: "list",
            }}
            open={true}
            onClose={() => setPopoverInfo(null)}
          />
        )}
      </Popover>
    </div>
  );
}
import { Grid, Tab, Tabs } from "@mui/material";
import { useContext, useState, useMemo } from "react";
import MapContext from "../contexts/MapContext";
import ClusterSearchList from "./lists/ClusterSearchList";
import PaperList from "./lists/PaperList";

export default function SearchResultsPanel({}) {
  const {
    searchPaperResults,
    searchClusterResults,
    yearFilter,
    searchQuery,
    mapName,
  } = useContext(MapContext);

  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  const getPaperCountLabel = () => {
    if (searchPaperResults && searchPaperResults.length > 0) {
      return ` (${searchPaperResults.length})`;
    }
    return "";
  };

/*  const filteredPapers = useMemo(() => {
    if (!searchPaperResults) return null;
    if (!yearFilter) return searchPaperResults;

    return searchPaperResults.filter(
      (paper) =>
        paper.year >= yearFilter.startYear && paper.year <= yearFilter.endYear
    );
  }, [searchPaperResults, yearFilter]);
*/
  return (
    <Grid
      container
      spacing={2}
      style={{ backgroundColor: "white", height: "100%" }}
    >
      {false && (
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label={`Papers${getPaperCountLabel()}`} />
            {false && <Tab label="Topics" />}
            {/*<Tab label="Viewports" />
          <Tab label="GPT" />
          <Tab label="Tab 3" />
          <Tab label="Tab 4" />
          <Tab label="Tab 5" />
  <Tab label="Tab 6" />*/}
          </Tabs>
        </Grid>
      )}

      {currentTab === 0 && (
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          {searchQuery && (
            <div style={{ width: "100%", height: "100%", marginTop: "10px" }}>
              <PaperList
                selection={{
                  type: "search",
                  args: { q: searchQuery || "" },
                  map_name: mapName,
                  fields: ["title", "authors", "year", "citationcount"],
                }}
                //clusterMetas={searchClusterResults}
                type={"search"}
                sortBy={"search_score"}
                sortDirection={"desc"}
                customSortingOptions={[
                  { field: "search_score", label: "Relevance" },
                  { field: "citationcount", label: "Citations" },
                  { field: "year", label: "Year" },
                ]}
              />
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MapContext from "../../contexts/MapContext";

const defaultDrawerBleeding = 68;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  position: "relative",
  touchAction: "none", // disable default panning so our touch handlers work properly
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.grey[300],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

export interface MobileDrawerProps {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  drawerHeight: number; // initial height (could be minHeight)
  minHeight?: number;
  maxHeight?: number;
}

export default function MobileDrawer({
  title,
  children,
  isOpen,
  onClose,
  drawerHeight,
  minHeight = window.innerHeight * 0.1,
  maxHeight = window.innerHeight * 0.8,
}: MobileDrawerProps) {
  const { isMapInteraction } = useContext(MapContext);
  const [currentDrawerHeight, setCurrentDrawerHeight] = useState(drawerHeight);
  // Ref for the initial height when dragging starts
  const startHeightRef = useRef(currentDrawerHeight);
  // Ref for the starting touch Y coordinate
  const touchStartYRef = useRef<number | null>(null);
  // Flag to indicate if a drag occurred
  const draggingRef = useRef(false);
  // A small movement threshold to distinguish a tap from a drag (in pixels)
  const DRAG_THRESHOLD = 5;

  useEffect(() => {
    setCurrentDrawerHeight(drawerHeight);
  }, [drawerHeight]);

  useEffect(() => {
    if (isMapInteraction) {
      setCurrentDrawerHeight(minHeight);
    }
  }, [isMapInteraction, minHeight]);

  // Toggle between min and max height on a tap (if no drag occurred)
  const toggleDrawerHeight = (e?: React.MouseEvent) => {
    if (e) e.stopPropagation();
    // Prevent toggling if a drag occurred recently.
    if (draggingRef.current) {
      draggingRef.current = false;
      return;
    }
    if (currentDrawerHeight < maxHeight * 0.95) {
      setCurrentDrawerHeight(maxHeight);
    } else {
      setCurrentDrawerHeight(minHeight);
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartYRef.current = e.touches[0].clientY;
    startHeightRef.current = currentDrawerHeight;
    draggingRef.current = false;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (touchStartYRef.current === null) return;
    const currentY = e.touches[0].clientY;
    const delta = touchStartYRef.current - currentY;
    if (Math.abs(delta) > DRAG_THRESHOLD) {
      draggingRef.current = true;
    }
    let newHeight = startHeightRef.current + delta;
    newHeight = Math.max(minHeight, Math.min(maxHeight, newHeight));
    setCurrentDrawerHeight(newHeight);
  };

  // On touch end, we simply leave the drawer at its current height.
  const handleTouchEnd = () => {
    touchStartYRef.current = null;
  };

  if (!isOpen) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        height: maxHeight,
        transform: `translateY(${maxHeight - currentDrawerHeight}px)`,
        transition: "transform 0.1s ease", // subtle transition for smoother movement
        zIndex: 1300,
      }}
    >
      <StyledBox
        sx={{
          position: "absolute",
          top: -defaultDrawerBleeding,
          right: 0,
          left: 0,
        }}
        onClick={toggleDrawerHeight}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <Puller />
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            window.history.back();
          }}
          sx={{ position: "absolute", top: 15, left: 5 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          sx={{
            p: 1,
            color: "text.secondary",
            textAlign: "center",
            fontSize: "1rem",
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            userSelect: "none",
          }}
        >
          {title}
        </Typography>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          sx={{ position: "absolute", top: 5, right: 5 }}
        >
          <CloseIcon />
        </IconButton>
      </StyledBox>
      <StyledBox
        sx={{
          px: 2,
          pb: 2,
          height: "100%",
          overflow: "auto",
        }}
      >
        {children}
      </StyledBox>
    </Box>
  );
}
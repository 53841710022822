// src/App.js
import React from "react";
import "./App.css";
import { MapProvider } from "./contexts/MapContext";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MapHandler from "./components/MapHandler"; // Import the new component
import { SnackbarContextProvider } from "./contexts/SnackbarContext";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./components/Login"; // Import the Login component
import Impressum from "./pages/Impressum";
import AboutPage from "./pages/AboutPage";
import MapList from "./components/MapList";
export default function App() {
  return (
    <BrowserRouter>
      <SnackbarContextProvider>
        <MapProvider>
          <div className="App">
            <Routes>
              <Route path="/" element={<Navigate to="/map/v2_2" replace />} />
              <Route path="/login" element={<Login />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/list_maps" element={<MapList />} />
              {/* Protected Routes */}
              <Route element={<PrivateRoute />}>
                <Route path="/map/:name" element={<MapHandler />} />
                <Route
                  path="/map/:name/search/:query"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/search/:query/coordinates/:coordinates"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/author/:authorId"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/author/:authorId/coordinates/:coordinates"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/journal/:journalId"
                  element={<MapHandler />}
                />
                  <Route
                  path="/map/:name/journal/:journalId/coordinates/:coordinates"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/corpus/:corpusId"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/corpus/:corpusId/coordinates/:coordinates"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/cluster/:clusterId"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/cluster/:clusterId/coordinates/:coordinates"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/search/:query/author/:authorId"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/search/:query/author/:authorId/coordinates/:coordinates"
                  element={<MapHandler />}
                />
                <Route 
                path="/map/:name/search/:query/cluster/:cluster_id"
                element={<MapHandler />}
                />
                <Route
                path="/map/:name/search/:query/cluster/:cluster_id/coordinates/:coordinates"
                element={<MapHandler />}
                />
                <Route
                  path="/map/:name/search/:query/corpus/:corpusId"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/search/:query/corpus/:corpusId/coordinates/:coordinates"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/author/:authorId/corpus/:corpusId"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/author/:authorId/corpus/:corpusId/coordinates/:coordinates"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/coordinates/:coordinates"
                  element={<MapHandler />}
                />
              </Route>
              {/* 404 Route - Must be last */}
              <Route
                path="*"
                element={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100vh",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={"/logowide.png"}
                      alt="Logo"
                      style={{ width: "200px", height: "auto" }}
                    />
                    <h1 style={{ fontSize: "3rem", marginBottom: "1rem" }}>
                      404
                    </h1>
                    <h2 style={{ marginBottom: "2rem" }}>Page Not Found</h2>
                    <p>
                      The page you're looking for doesn't exist or has been
                      moved.
                    </p>
                  </div>
                }
              />
            </Routes>
          </div>
        </MapProvider>
      </SnackbarContextProvider>
    </BrowserRouter>
  );
}

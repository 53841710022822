// src/apiFunctions.ts
import API_ROUTES from "./ApiRoutes";
import IViewport from "./interfaces/viewport";
import IClusterDetails from "./interfaces/clusterDetails";
import ILandmarkedPapers from "./interfaces/landmarkedPapers";
import IClusterMeta, { IClusterMetaSmall } from "./interfaces/clusterMeta";
import _ from "lodash";
import IPaperDetailsWithCoordinates, { IPaperDetails } from "./interfaces/paperDetails";
import IAuthorDetails from "./interfaces/authorDetails";
import { ILayer } from "./interfaces/mapConfig";
import { ISourceConfig } from "./contexts/MapContext";
import { getWithAbort, postWithAbort } from "./apiUtils";
import IPaperMeta from "./interfaces/paperMeta";
import IPaperSelection from "./interfaces/paperSelection";
import axios from "axios";
import IJournalDetails from "./interfaces/journalDetails";
import { IHistoryItem } from "./interfaces/userInterfaces";
import { IUserProfile } from "./interfaces/userInterfaces";

export async function getJournalDetails(journalId:string, signal?:AbortSignal):Promise<IJournalDetails>{
  try{
    return await getWithAbort<IJournalDetails>(API_ROUTES.getJournalDetails(journalId), undefined, signal);
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to fetch journal details.");
  }
}

export async function fetchJournalAuthors(mapName:string, journalId:string, signal?:AbortSignal):Promise<any>{
  try{
    return {authors:[]};
    //return await getWithAbort<any>(API_ROUTES.fetchJournalAuthors(mapName, journalId), undefined, signal);
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to fetch journal authors.");
  }
}

/**
 * Get a random author ID.
 */
export async function getRandomAuthorId(
  mapName: string,
  signal?: AbortSignal
): Promise<string> {
  try {
    const response = await getWithAbort<{ author_id: string }>(
      API_ROUTES.getRandomAuthorId(mapName),
      undefined,
      signal
    );
    return response.author_id;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error getting random author ID:", error);
      throw new Error(error.response?.data?.message || "Failed to get random author ID.");
    }
  }
}




/*
 * Get a random journal ID.
 */
export async function getRandomJournalId(
  mapName: string,
  signal?: AbortSignal
): Promise<string> {
  try {
    const response = await getWithAbort<{ journal_id: string }>(
      API_ROUTES.getRandomJournalId(mapName),
      undefined,
      signal
    );
    return response.journal_id;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error getting random journal ID:", error);
      throw new Error(error.response?.data?.message || "Failed to get random journal ID.");
    }
  }
}

/**
 * Get a random paper ID.
 */
export async function getRandomPaperId(
  mapName: string,
  signal?: AbortSignal
): Promise<string> {
  try {
    const response = await getWithAbort<{ corpusid: string }>(
      API_ROUTES.getRandomPaperId(mapName),
      undefined,
      signal
    );
    return response.corpusid;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error getting random paper ID:", error);
      throw new Error(error.response?.data?.message || "Failed to get random paper ID.");
    }
  }
}

/**
 * Get a random cluster ID.
 */
export async function getRandomClusterId(
  mapName: string,
  signal?: AbortSignal
): Promise<number> {
  try {
    const response = await getWithAbort<{ cluster_id: number }>(
      API_ROUTES.getRandomClusterId(mapName),
      undefined,
      signal
    );
    return response.cluster_id;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error getting random cluster ID:", error);
      throw new Error(error.response?.data?.message || "Failed to get random cluster ID.");
    }
  }
}

export async function createGeometry(mapName:string, geometryData:any, signal?:AbortSignal):Promise<any>{
  try {
    return await postWithAbort<any>(API_ROUTES.postCreateGeometry(mapName), geometryData, undefined, signal);
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to create geometry.");
  }
}

/**
 * Create a selection.
 */
export async function createSelection(
  mapName: string,
  selectionData: any,
  signal?: AbortSignal
): Promise<any> {
  try {
    return await postWithAbort<any>(
      API_ROUTES.postCreateSelection(mapName),
      selectionData,
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error creating selection:", error);
      throw new Error(error.response?.data?.message || "Failed to create selection.");
    }
  }
}

/**
 * Fetch the map configuration.
 */
export async function fetchMapConfig(mapName: string, signal?: AbortSignal): Promise<any> {
  try {
    return await getWithAbort<any>(API_ROUTES.getMapConfig(mapName), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error; // Re-throw to ensure the caller is aware of the cancellation
    } else {
      console.error("Error fetching map config:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch map configuration.");
    }
  }
}
/**
 * Update the map source JSON.
 */
export async function updateMapSourceJSON(
  mapName: string,
  sourceJson: any,
  signal?: AbortSignal
): Promise<any> {
  try {
    return await postWithAbort<any>(API_ROUTES.updateMapSourceJSON(mapName), sourceJson, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error updating map source JSON:", error);
      throw new Error(error.response?.data?.message || "Failed to update map source JSON.");
    }
  }
}

/**
 * Delete a layer from the map source JSON.
 */
export async function deleteSourceJSONLayer(
  mapName: string,
  layerId: string,
  signal?: AbortSignal
): Promise<any> {
  try {
    return await postWithAbort<any>(API_ROUTES.deleteSourceJSONLayer(mapName, layerId), undefined, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error deleting layer from map source JSON:", error);
      throw new Error(error.response?.data?.message || "Failed to delete layer from map source JSON.");
    }
  }
}

/**
 * Post a viewport bookmark.
 */
export async function postViewportBookmark(
  mapName: string,
  viewport: any,
  signal?: AbortSignal
): Promise<any> {
  try {
    return await postWithAbort<any>(
      API_ROUTES.postViewportBookmark(),
      { mapName, viewport },
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error posting viewport bookmark:", error);
      throw new Error(error.response?.data?.message || "Failed to post viewport bookmark.");
    }
  }
}


export async function createPapersSelection(mapName:string, selection:IPaperSelection, signal?:AbortSignal):Promise<IPaperMeta[]>{
  try{
    return await postWithAbort<any>(API_ROUTES.createPapersSelection(mapName), selection, undefined, signal);
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to create papers selection.");
  }
}
/**
 * Get paper selection based on criteria.
 */
export async function getPaperSelection(
  mapName: string,
  selection: IPaperSelection,
  signal?: AbortSignal
): Promise<{ papers: IPaperMeta[]; cluster_metas_by_clusterId: Record<number, IClusterMetaSmall> }> {
  try {
    // Prepare parameters by converting arrays to comma-separated strings
    const params: Record<string, string> = {
      type: selection.type,
      map_name: selection.map_name,
    };

    if (selection.fields && selection.fields.length > 0) {
      params.fields = selection.fields.join(',');
    }

    if (selection.sampling) {
      params.sampling = JSON.stringify(selection.sampling);
    }

    if (selection.limit !== undefined && selection.limit !== null) {
      params.limit = selection.limit.toString();
    }

    if (selection.args.cluster_id !== undefined && selection.args.cluster_id !== null) {
      params.cluster_id = selection.args.cluster_id.toString();
    }

    if (selection.args.cluster_ids && selection.args.cluster_ids.length > 0) {
      params.cluster_ids = selection.args.cluster_ids.join(',');
    }

    if (selection.args.bounds && selection.args.bounds.length > 0) {
      params.bounds = selection.args.bounds.join(',');
    }

    if (selection.args.ids && selection.args.ids.length > 0) {
      params.ids = selection.args.ids.join(',');
    }
    if (selection.args.author_id) {
      params.author_id = selection.args.author_id.toString();
    }

    if (selection.args.q) {
      params.q = selection.args.q;
    }

    return await getWithAbort<{ papers: IPaperMeta[]; cluster_metas_by_clusterId: Record<number, IClusterMetaSmall> }>(
      API_ROUTES.getPaperSelection(mapName),
      {
        params,
        paramsSerializer: (params) => {
          const searchParams = new URLSearchParams();
          Object.keys(params).forEach((key) => {
            const value = params[key];
            if (value !== undefined && value !== null && value !== '') {
              searchParams.append(key, value);
            }
          });
          return searchParams.toString();
        },
      },
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching paper selection:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch paper selection.");
    }
  }
}

/**
 * Fetch details of a specific cluster.
 */
export async function fetchClusterDetails(
  mapName: string,
  clusterId: string,
  signal?: AbortSignal
): Promise<IClusterDetails> {
  try {
    const response = await getWithAbort<IClusterDetails>(API_ROUTES.fetchClusterDetails(mapName, clusterId), undefined, signal);

    if (typeof response !== "object") {
      throw new Error("Invalid response");
    }

    return response;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching cluster details:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch cluster details.");
    }
  }
}

/**
 * Generate a picture for a specific cluster.
 */
export async function generateClusterPicture(
  mapName: string,
  clusterId: string,
  contextType: string,
  signal?: AbortSignal
): Promise<IClusterDetails> {
  try {
    return await postWithAbort<IClusterDetails>(
      API_ROUTES.postGenerateClusterPicture(mapName, clusterId),
      { context_type: contextType },
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error generating cluster picture:", error);
      throw new Error(error.response?.data?.message || "Failed to generate cluster picture.");
    }
  }
}

/**
 * Fetch papers in a viewport.
 */
export async function fetchPapersInViewport(
  mapName: string,
  viewport: IViewport,
  signal?: AbortSignal
): Promise<IPaperMeta[]> {
  try {
    const response = await getWithAbort<IPaperMeta[]>(API_ROUTES.getPapersInViewport(mapName, viewport), undefined, signal);
    if (!Array.isArray(response)) {
      throw new Error("Invalid response format");
    }
    return response;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching papers in viewport:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch papers in viewport.");
    }
  }
}

/**
 * Fetch clusters in a viewport.
 */
export async function fetchClustersInViewport(
  mapName: string,
  viewport: IViewport,
  signal?: AbortSignal
): Promise<IClusterMeta[]> {
  try {
    const response = await getWithAbort<IClusterMeta[]>(API_ROUTES.getClustersInViewport(mapName, viewport), undefined, signal);
    if (!Array.isArray(response)) {
      throw new Error("Invalid response format");
    }
    return response;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching clusters in viewport:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch clusters in viewport.");
    }
  }
}

/**
 * Fetch search results.
 */
export async function fetchSearchResults(
  mapName: string,
  query: string,
  bounds: number[] | null = null,
  limit: number = 1000,
  signal?: AbortSignal
): Promise<{
  areas: any;
  landmarks: any;
  papers: IPaperMeta[];
  cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
  heatmap: {
    source: any;
    layer: any;
  };
  hex: {
    sources: any;
    layers: any;
  };
}> {
  try {
    return await getWithAbort<{
      areas: any;
      landmarks: any;
      papers: IPaperMeta[];
      cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
      heatmap: {
        source: any;
        layer: any;
      };
      hex: {
        sources: any;
        layers: any;
      };
    }>(
      API_ROUTES.searchPapers(mapName, query, limit, bounds),
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching search results:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch search results.");
    }
  }
}

/**
 * Fetch author details.
 */
export async function fetchAuthorDetails(
  authorId: string,
  signal?: AbortSignal
): Promise<IAuthorDetails> {
  try {
    return await getWithAbort<IAuthorDetails>(API_ROUTES.fetchAuthorDetails(authorId), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching author details:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch author details.");
    }
  }
}

/**
 * Fetch author papers.
 */
export async function fetchAuthorPapers(
  mapName: string,
  authorId: string,
  signal?: AbortSignal
): Promise<{
  layers: ILayer[];
  sources: ISourceConfig[];
  papers: IPaperMeta[];
  cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
}> {
  try {
    return await getWithAbort<{
      layers: ILayer[];
      sources: ISourceConfig[];
      papers: IPaperMeta[];
      cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
    }>(API_ROUTES.fetchAuthorPapers(mapName, authorId), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching author papers:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch author papers.");
    }
  }
}

/**
 * Fetch author citations.
 */
export async function fetchAuthorCitations(
  mapName: string,
  authorId: string,
  signal?: AbortSignal
): Promise<{
  layers: ILayer[];
  sources: ISourceConfig[];
  papers: IPaperMeta[];
  cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
}> {
  try {
    return await getWithAbort<{
      layers: ILayer[];
      sources: ISourceConfig[];
      papers: IPaperMeta[];
      cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
    }>(API_ROUTES.fetchAuthorCitations(mapName, authorId), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching author citations:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch author citations.");
    }
  }
}

/**
 * Fetch author references.
 */
export async function fetchAuthorReferences(
  mapName: string,
  authorId: string,
  signal?: AbortSignal
): Promise<{
  layers: ILayer[];
  sources: ISourceConfig[];
  papers: IPaperMeta[];
  cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
}> {
  try {
    return await getWithAbort<{
      layers: ILayer[];
      sources: ISourceConfig[];
      papers: IPaperMeta[];
      cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
    }>(API_ROUTES.fetchAuthorReferences(mapName, authorId), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching author references:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch author references.");
    }
  }
}

/**
 * Create a context.
 */
export async function createContext(
  selection: any,
  contexts: any,
  signal?: AbortSignal
): Promise<string> {
  try {
    return await postWithAbort<string>(
      API_ROUTES.postCreateContext(),
      { selection, contexts },
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error creating context:", error);
      throw new Error(error.response?.data?.message || "Failed to create context.");
    }
  }
}

/**
 * Function calling with prompting.
 */
export async function promptFunctionCalling(
  selection: any,
  contexts: any,
  prompt: any,
  promptingConfig: any,
  model: string = "gpt-4o-mini",
  cachingKey: string | null = null,
  signal?: AbortSignal
): Promise<any> {
  try {
    const payload: any = {
      selection,
      contexts,
      prompt,
      prompting_config: promptingConfig,
      model,
    };

    if (cachingKey) {
      payload.caching_key = cachingKey;
    }

    return await postWithAbort<any>(
      API_ROUTES.postFunctionCallingPrompting(),
      payload,
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error in function calling prompting:", error);
      throw new Error(error.response?.data?.message || "Failed to perform function calling prompting.");
    }
  }
}

/**
 * Old function calling prompting (if needed).
 */
export async function promptFunctionCalling_old(
  selection: any,
  contexts: any,
  prompting: any,
  model: string = "gpt-4o-mini",
  signal?: AbortSignal
): Promise<any> {
  try {
    return await postWithAbort<any>(
      API_ROUTES.postFunctionCallingPrompting_old(),
      { selection, contexts, prompting, model },
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error in old function calling prompting:", error);
      throw new Error(error.response?.data?.message || "Failed to perform old function calling prompting.");
    }
  }
}
/**
 * Fetch autocomplete suggestions.
 * @param mapName The name of the map
 * @param query The search query string
 * @param autocompleteType The type of autocomplete ('keywords', 'authors', 'journals', or 'institutions')
 * @param signal Optional AbortSignal for cancelling the request
 */
export async function fetchAutocompleteSuggestions(
  mapName: string,
  query: string,
  autocompleteType: 'keywords' | 'authors' | 'journals' | 'institutions',
  size: number = 10,
  signal?: AbortSignal
): Promise<any> {
  try {
    return await getWithAbort<any>(
      API_ROUTES.getAutocompleteSuggestions(mapName, query, autocompleteType, size),
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching autocomplete suggestions:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch autocomplete suggestions.");
    }
  }
}

/**
 * Create a heatmap layer.
 */
export async function createHeatmapLayer(
  selection: any,
  data_config: any,
  geometry_config: any,
  style_config: any,
  theme: "light" | "dark",
  signal?: AbortSignal
): Promise<any> {
  try {
    const payload = { selection, data_config, geometry_config, style_config, theme };
    return await postWithAbort<any>(API_ROUTES.postCreateHeatmapLayer(), payload, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error creating heatmap layer:", error);
      throw new Error(error.response?.data?.message || "Failed to create heatmap layer.");
    }
  }
}

/**
 * Create a hexbin layer.
 */
export async function createHexbinLayer(
  selection: any,
  data_config: any,
  geometry_config: any,
  style_config: any,
  theme: "light" | "dark",
  signal?: AbortSignal
): Promise<any> {
  try {
    const payload = { selection, data_config, geometry_config, style_config, theme };
    return await postWithAbort<any>(API_ROUTES.postCreateHexbinLayer(), payload, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error creating hexbin layer:", error);
      throw new Error(error.response?.data?.message || "Failed to create hexbin layer.");
    }
  }
}

/**
 * Create a grid layer.
 */
export async function createGridLayer(
  selection: any,
  data_config: any,
  geometry_config: any,
  style_config: any,
  theme: "light" | "dark",
  signal?: AbortSignal
): Promise<any> {
  try {
    const payload = { selection, data_config, geometry_config, style_config, theme };
    return await postWithAbort<any>(API_ROUTES.postCreateGridLayer(), payload, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error creating grid layer:", error);
      throw new Error(error.response?.data?.message || "Failed to create grid layer.");
    }
  }
}

/**
 * Create a cluster label layer.
 */
export async function createClusterLabelLayer(
  selection: any,
  data_config: any,
  geometry_config: any,
  style_config: any,
  theme: "light" | "dark",
  signal?: AbortSignal
): Promise<any> {
  try {
    const payload = { selection, data_config, geometry_config, style_config, theme };
    return await postWithAbort<any>(API_ROUTES.postCreateClusterLabelsLayer(), payload, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error creating cluster label layer:", error);
      throw new Error(error.response?.data?.message || "Failed to create cluster label layer.");
    }
  }
}

/**
 * Create a cluster layer.
 */
export async function createClusterLayer(
  selection: any,
  data_config: any,
  geometry_config: any,
  style_config: any,
  theme: "light" | "dark",
  signal?: AbortSignal
): Promise<any> {
  try {
    const payload = { selection, data_config, geometry_config, style_config, theme };
    return await postWithAbort<any>(API_ROUTES.postCreateClusterLayer(), payload, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error creating cluster layer:", error);
      throw new Error(error.response?.data?.message || "Failed to create cluster layer.");
    }
  }
}
export async function listMaps(signal?: AbortSignal): Promise<any> {
  try {
    return await getWithAbort<any>(API_ROUTES.listMaps(), undefined, signal);
  } catch (error: any) {
    console.error("Error listing maps:", error);
    throw new Error(error.response?.data?.message || "Failed to list maps.");
  }
}
/**
 * Prompt full text with optional caching.
 */
export async function promptFullText(
  selection: any,
  contexts: any,
  prompt: any,
  model: string = "gpt-4o-mini",
  cachingKey: string | null = null,
  signal?: AbortSignal
): Promise<any> {
  try {
    const payload: any = {
      selection,
      contexts,
      prompt,
      model,
    };

    if (cachingKey) {
      payload.caching_key = cachingKey;
    }

    return await postWithAbort<any>(API_ROUTES.postFullTextPrompting(), payload, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error in full text prompting:", error);
      throw new Error(error.response?.data?.message || "Failed to perform full text prompting.");
    }
  }
}

/**
 * Get cached response.
 */
export async function getCachedResponse(
  cachingKey: string,
  signal?: AbortSignal
): Promise<any> {
  if (!cachingKey) throw new Error("cachingKey is required to get cached response");

  try {
    return await getWithAbort<any>(API_ROUTES.getCachedResponse(cachingKey), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      throw error;
    } else {
      throw new Error(error.response?.data?.message || "Failed to get cached response.");
    }
  }
}

/**
 * Fetch paper details with coordinates.
 */
export async function fetchPaperDetailsWithCoordinates(
  mapName: string,
  corpusid: string,
  signal?: AbortSignal
): Promise<IPaperDetailsWithCoordinates> {
  try {
    return await getWithAbort<IPaperDetailsWithCoordinates>(
      API_ROUTES.getPaperDetailsWithCoordinates(mapName, corpusid),
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching paper details with coordinates:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch paper details with coordinates.");
    }
  }
}

/**
 * Generate a paper picture.
 */
export async function generatePaperPicture(
  mapName: string,
  corpusid: string,
  signal?: AbortSignal
): Promise<any> {
  try {
    return await postWithAbort<any>(
      API_ROUTES.postGeneratePaperPicture(mapName, corpusid),
      {}, // Assuming no payload is needed; adjust if necessary
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error generating paper picture:", error);
      throw new Error(error.response?.data?.message || "Failed to generate paper picture.");
    }
  }
}

/**
 * Fetch paper details.
 */
export async function fetchPaperDetails(
  corpusid: string,
  fields?: string[],
  signal?: AbortSignal
): Promise<IPaperDetails> {
  try {
    let url = API_ROUTES.getPaperDetails(corpusid);
    if (fields && fields.length > 0) {
      const params = new URLSearchParams();
      params.append("fields", fields.join(","));
      url += `?${params.toString()}`;
    }

    return await getWithAbort<IPaperDetails>(url, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching paper details:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch paper details.");
    }
  }
}

/**
 * Fetch paper references.
 */
export async function fetchPaperReferences(
  mapName: string,
  corpusid: string,
  signal?: AbortSignal
): Promise<{
  papers: IPaperMeta[];
  sources: ISourceConfig[];
  layers: ILayer[];
  cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
}> {
  try {
    return await getWithAbort<{
      papers: IPaperMeta[];
      sources: ISourceConfig[];
      layers: ILayer[];
      cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
    }>(API_ROUTES.getPaperReferences(mapName, corpusid), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching paper references:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch paper references.");
    }
  }
}

/**
 * Fetch paper citations.
 */
export async function fetchPaperCitations(
  mapName: string,
  corpusid: string,
  signal?: AbortSignal
): Promise<{
  papers: IPaperMeta[];
  sources: ISourceConfig[];
  layers: ILayer[];
  cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
}> {
  try {
    return await getWithAbort<{
      papers: IPaperMeta[];
      sources: ISourceConfig[];
      layers: ILayer[];
      cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
    }>(API_ROUTES.getPaperCitations(mapName, corpusid), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching paper citations:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch paper citations.");
    }
  }
}

/**
 * Fetch paper nearest neighbors.
 */
export async function fetchPaperNearestNeighbors(
  mapName: string,
  corpusid: string,
  signal?: AbortSignal
): Promise<{
  papers: IPaperMeta[];
  sources: ISourceConfig[];
  layers: ILayer[];
  cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
}> {
  try {
    return await getWithAbort<{
      papers: IPaperMeta[];
      sources: ISourceConfig[];
      layers: ILayer[];
      cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
    }>(API_ROUTES.getPaperNearestNeighbors(mapName, corpusid), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching paper nearest neighbors:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch paper nearest neighbors.");
    }
  }
}

export async function getUserProfile(signal?: AbortSignal): Promise<IUserProfile> {
  try {
    return await getWithAbort<IUserProfile>(API_ROUTES.getUserProfile(), undefined, signal);
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to fetch user profile.");
  }
}

/**
 * Get the current user's history items.
 * You can pass an optional limit as a query parameter.
 */
export async function getUserHistory(limit: number = 10, signal?: AbortSignal): Promise<IHistoryItem[]> {
  try {
    // Append limit as query param if provided.
    const url = `${API_ROUTES.getUserHistory()}?limit=${limit}`;
    return await getWithAbort<IHistoryItem[]>(url, undefined, signal);
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to fetch user history.");
  }
}

/**
 * Add a new history item.
 * The payload should include:
 *   - item_type: 'keyword', 'author', or 'journal'
 *   - query: string (the search text)
 *   - Optional ref_id: string (for author or journal)
 */
export async function addUserHistory(
  historyItem: { item_type: 'keyword' | 'author' | 'journal'; query: string; ref_id?: string },
  signal?: AbortSignal
): Promise<{ history_item_id: number; msg: string }> {
  try {
    return await postWithAbort<{ history_item_id: number; msg: string }>(
      API_ROUTES.addUserHistory(),
      historyItem,
      undefined,
      signal
    );
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to add history item.");
  }
}

/**
 * Delete a history item by ID.
 */
export async function deleteUserHistory(historyId: number, signal?: AbortSignal): Promise<{ msg: string }> {
  try {
    const response = await axios.delete(API_ROUTES.deleteUserHistory(historyId), { signal });
    return response.data;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    }
    throw new Error(error.response?.data?.message || "Failed to delete history item.");
  }
}

/**
 * Add a new bookmark.
 * The payload should include the corpusid of the paper.
 */
export async function addBookmark(corpusid: number, signal?: AbortSignal): Promise<{ bookmark_id: number; msg: string }> {
  try {
    return await postWithAbort<{ bookmark_id: number; msg: string }>(
      API_ROUTES.addBookmark(),
      { corpusid },
      undefined,
      signal
    );
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to add bookmark.");
  }
}

/**
 * Delete a bookmark by its ID.
 */
export async function deleteBookmark(bookmarkId: number, signal?: AbortSignal): Promise<{ msg: string }> {
  try {
    const response = await axios.delete(API_ROUTES.deleteBookmark(bookmarkId), { signal });
    return response.data;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    }
    throw new Error(error.response?.data?.message || "Failed to delete bookmark.");
  }
}
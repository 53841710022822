import React, { useEffect, useRef, useState, useContext } from "react";
import {
  IconButton,
  Typography,
  Skeleton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import MapContext, { IHoveredPaper } from "../../contexts/MapContext";
import { fetchPaperDetails } from "../../requests";
import { useSnackbar } from "../../contexts/SnackbarContext";
import CitationCountDisplay from "../paper/CitationCountDisplay";
import YearDisplay from "../paper/YearDisplay";
import "./HoveredPaperListItem.scss";

const SHOW_BOOKMARK_BUTTON = false;

interface HoveredPaperListItemProps {
  hoveredPaper: IHoveredPaper;
  open: boolean;
  onClose: () => void;
}

export default function HoveredPaperListItem({
  hoveredPaper,
  open,
  onClose,
}: HoveredPaperListItemProps) {
  const { setSelectedCorpusId } = useContext(MapContext);
  const snackbar = useSnackbar();
  const [meta, setMeta] = useState<any>(hoveredPaper.meta || null);
  const loadingPaperIdRef = useRef<number | null>(null);
  const fetchedPaperIdsRef = useRef<Set<number>>(new Set());

  // Reset meta when a new paper is hovered.
  useEffect(() => {
    setMeta(hoveredPaper.meta || null);
  }, [hoveredPaper.id, hoveredPaper.meta]);

  // Fetch paper details only once per paper ID.
  useEffect(() => {
    if (
      hoveredPaper &&
      !meta &&
      !fetchedPaperIdsRef.current.has(hoveredPaper.id)
    ) {
      const paperId = hoveredPaper.id;
      loadingPaperIdRef.current = paperId;
      fetchedPaperIdsRef.current.add(paperId);

      fetchPaperDetails(paperId.toString(), [
        "citationcount",
        "year",
        "authors",
        "tldr",
        "abstract",
        "journal",
      ])
        .then((details) => {
          if (loadingPaperIdRef.current === paperId) {
            setMeta(details);
          }
        })
        .catch(() => {
          snackbar.showSnackbar("Error fetching paper details", "error");
          // Allow retry on error.
          fetchedPaperIdsRef.current.delete(paperId);
        });
      return () => {
        loadingPaperIdRef.current = null;
      };
    }
  }, [hoveredPaper?.id, meta, snackbar]);

  // Close on Escape key.
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    if (open) {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, onClose]);

  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  const handleShowDetails = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedCorpusId(hoveredPaper.id.toString());
    onClose();
  };

  const handleBookmark = (e: React.MouseEvent) => {
    e.stopPropagation();
    snackbar.showSnackbar("Paper bookmarked", "success");
  };

  const getAuthorString = (
    authors: any[] | undefined,
    maxCharacters: number = 50
  ) => {
    if (!authors || authors.length === 0) return "authors unknown";
    if (authors.length === 1) {
      const name = authors[0].name;
      return name.length > maxCharacters
        ? name.slice(0, maxCharacters) + "..."
        : name;
    }
    const firstAuthor = authors[0].name;
    const suffix = " et al.";
    return firstAuthor.length + suffix.length > maxCharacters
      ? firstAuthor.slice(0, maxCharacters - suffix.length) + "..." + suffix
      : firstAuthor + suffix;
  };

  const getJournalString = (journal: any, maxCharacters: number = 50) => {
    if (!journal || !journal.name) return "";
    const prefix = "(";
    const suffix = ")";
    const availableChars = maxCharacters - prefix.length - suffix.length;
    return journal.name.length > availableChars
      ? `${prefix}${journal.name.slice(0, availableChars)}...${suffix}`
      : `${prefix}${journal.name}${suffix}`;
  };

  if (!open) return null;

  return (
    // Container with fixed height and footer always visible at bottom
    <div
      style={{ 
        display: "flex", 
        flexDirection: "column", 
        maxHeight: "40vh", 
        height: "100%",
        position: "relative" 
      }}
    >
      {/* Fixed close button that stays in place during scroll */}
      <div
        style={{
          position: "absolute",
          top: "5px",
          right: "5px",
          zIndex: 100,
        }}
      >
        <IconButton
          size="small"
          onClick={handleCloseClick}
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            padding: "4px",
            pointerEvents: "auto",
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      
      {/* Scrollable content area */}
      <div style={{ overflowY: "auto", flex: "1 1 auto", paddingTop: "10px" }}>
        <div
          className={`popupHoverPaper type-${hoveredPaper.type}`}
          style={{ cursor: "pointer", position: "relative" }}
        >
          {/* Title with larger font size */}
          <h3 style={{ marginBottom: "5px", paddingRight: "30px",marginTop:0 }}>
            {hoveredPaper.title}
          </h3>
          <div className="meta">
            {!meta ? (
              <>
                {/* Second row: two skeletons at 25% width each */}
                <div className="metaRow">
                  <Skeleton variant="text" width="33%" style={{ marginRight: "10px" }} />
                  <Skeleton variant="text" width="33%" style={{ marginRight: "10px" }} />
                </div>
                {/* Third row: two skeletons at 33% width each */}
                
                {/* TLDR: two full-width skeleton rows */}
                {/* TLDR: two full-width skeleton rows */}
                <div style={{ marginTop: "10px" }}>
                  <Skeleton variant="text" width="100%" style={{ marginBottom: "4px" }} />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="70%" style={{ marginBottom: "2px" }} />

                </div>
                
              </>
            ) : (
              <>
                <div className="metaRow">
                  <YearDisplay year={meta.year} />
                  <CitationCountDisplay citationCount={meta.citationcount} />
                </div>
                <div className="metaRow">
                  <Typography variant="body2">
                    {getAuthorString(meta.authors)}
                  </Typography>
                  <Typography variant="body2">
                    {getJournalString(meta.journal)}
                  </Typography>
                </div>
                {meta.tldr && (
                  <>
                    <Divider style={{ margin: "8px 0" }} />
                    <Typography variant="body2">
                      <strong>TLDR:</strong> {meta.tldr}
                    </Typography>
                  </>
                )}
                {meta.abstract && (
                  <>
                    <Divider style={{ margin: "8px 0" }} />
                    <Typography variant="body2">
                      <strong>Abstract:</strong> {meta.abstract}
                    </Typography>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {/* Fixed footer */}
      <div
        style={{
          borderTop: "1px solid #e0e0e0",
          display: "flex",
          marginTop: "8px",
          pointerEvents: "auto",
          zIndex: 2000,
        }}
      >
        <div
          className="hoverInfo"
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            textAlign: "center",
            padding: "6px",
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            borderRadius: "4px",
            flex: "70%",
            cursor: "pointer",
            transition: "background-color 0.2s",
            height: "28px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
          }}
          onClick={handleShowDetails}
          title="View paper details"
          onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.1)")
          }
          onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.05)")
          }
        >
          Show Details
        </div>
        {SHOW_BOOKMARK_BUTTON && (
          <div
            className="bookmarkButton"
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              textAlign: "center",
              padding: "6px",
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              borderRadius: "4px",
              flex: "30%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              transition: "background-color 0.2s",
              height: "28px",
              boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
            }}
            onClick={handleBookmark}
            title="Bookmark this paper"
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.1)")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.05)")
            }
          >
            <BookmarkIcon fontSize="small" />
          </div>
        )}
      </div>
    </div>
  );
}
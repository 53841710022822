import CodeIcon from "@mui/icons-material/Code";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LinkIcon from "@mui/icons-material/Link";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Box,
  styled,
  Alert,
} from "@mui/material";
import { Global } from "@emotion/react";
import { useContext, useEffect, useRef, useState, TouchEvent } from "react";
import { useLocation } from "react-router-dom";

import { fetchPaperDetailsWithCoordinates } from "../../requests";
import MapContext, { HoveredPaperType } from "../../contexts/MapContext";
import { useSnackbar } from "../../contexts/SnackbarContext";

import IPaperDetailsWithCoordinates from "../../interfaces/paperDetails";
import IPaperMeta from "../../interfaces/paperMeta";
import PaperList from "../lists/PaperList";
import AuthorsDisplay from "../paper/AuthorsDisplay";
import CitationCountDisplay from "../paper/CitationCountDisplay";
import PaperAIButton from "../paper/PaperAIButton";
import YearDisplay from "../paper/YearDisplay";
import CopySelectionButton from "../utils/CopySelectionButton";
import CollapsibleSegment from "./CollapsibleSegment";
import { createSelectionLayerConfigs } from "../../geometryCreationUtils";
import { IClusterMetaSmall } from "../../interfaces/clusterMeta";
import IPaperSelection from "../../interfaces/paperSelection";
import MobileDrawer from "./MobileDrawer";

// Styled components for header/puller remain the same.
const drawerBleeding = 56;

export default function PaperDetailsPanel() {
  const {
    selectedCorpusId,
    setSelectedAuthorDetails,
    setSelectedAuthorId,
    selectedPaperDetails,
    setSelectedPaperDetails,
    setSelectedCorpusId,
    removePaperLayers,
    setPromptingSelection,
    upsertLayer,
    viewport,
    flyTo,
    mapName,
    setSelectedJournalId,
    fitToBounds,
    isMobile,
  } = useContext(MapContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPapers, setIsLoadingPapers] = useState(false);
  const [papers, setPapers] = useState<IPaperMeta[]>([]);
  const [clusterMetasByClusterId, setClusterMetasByClusterId] = useState<Record<
    number,
    IClusterMetaSmall
  > | null>(null);
  const [currentTab, setCurrentTab] = useState(0); // 0: Overview, 1: Citations, 2: References
  const [currentSelection, setCurrentSelection] =
    useState<IPaperSelection | null>(null);
  const isMounted = useRef(true);
  const snackbar = useSnackbar();
  const location = useLocation();
  const isDebugMode = location.search.includes("debug");

  // Assume sidebarOpen is true when a paper is selected on mobile.
  const sidebarOpen = !!selectedCorpusId;

  // --- Mobile Drawer State ---
  const vh = window.innerHeight;
  const minHeight = vh * 0.15; // header always visible
  const maxHeight = vh * 0.9;
  const defaultHeight = vh * 0.35;
  const [drawerHeight, setDrawerHeight] = useState<number>(defaultHeight);
  const [drawerExpanded, setDrawerExpanded] = useState(false);

  // Touch event handlers for swipe behavior.
  const [startY, setStartY] = useState<number | null>(null);
  const [startHeight, setStartHeight] = useState<number>(defaultHeight);

  const handleTouchStart = (e: TouchEvent) => {
    setStartY(e.touches[0].clientY);
    setStartHeight(drawerHeight);
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (startY === null) return;
    const deltaY = startY - e.touches[0].clientY;
    let newHeight = startHeight + deltaY;
    if (newHeight > maxHeight) newHeight = maxHeight;
    if (newHeight < minHeight) newHeight = minHeight;
    setDrawerHeight(newHeight);
  };

  const handleTouchEnd = () => {
    setStartY(null);
  };

  const toggleDrawerHeight = () => {
    if (drawerHeight < maxHeight * 0.95) {
      setDrawerHeight(maxHeight);
    } else {
      setDrawerHeight(defaultHeight);
    }
    setDrawerExpanded((prev) => !prev);
  };
  // --- End Mobile Drawer State ---

  // Cleanup effect
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    isMounted.current = true;
  }, [selectedCorpusId]);

  // Fetch paper details when selectedCorpusId changes
  useEffect(() => {
    if (selectedCorpusId) {
    setSelectedPaperDetails(null);

      // Clear other selections.
      setSelectedAuthorId(null);
      setSelectedAuthorDetails(null);
      setSelectedJournalId(null);
      setCurrentTab(0); // Reset to Overview tab
      setCurrentSelection(null);
      setIsLoading(true);

      fetchPaperDetailsWithCoordinates(mapName, selectedCorpusId)
        .then((details: IPaperDetailsWithCoordinates) => {
          if (!isMounted.current) return;
          setSelectedPaperDetails(details);

          const currentZoom = viewport.zoom;
          const targetZoom = currentZoom > 11 ? currentZoom : 11;
          flyTo(
            {
              center: {
                lng: details.geometry.coordinates[0] as number,
                lat: details.geometry.coordinates[1] as number,
              },
              zoom: targetZoom,
              ne: {
                lng: details.geometry.coordinates[0] as number,
                lat: details.geometry.coordinates[1] as number,
              },
              sw: {
                lng: details.geometry.coordinates[0] as number,
                lat: details.geometry.coordinates[1] as number,
              },
            },
            true
          );
        })
        .catch((error: any) => {
          if (isMounted.current) {
            snackbar.showSnackbar("Error fetching paper details", "error");
            setSelectedCorpusId(null); // Hide the panel
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      setSelectedPaperDetails(null);
    }
  }, [selectedCorpusId]);

  // Fetch additional paper layers (for citations/references) based on the selected tab
  useEffect(() => {
    if (!selectedCorpusId) return;

    if (currentTab === 0) {
      // For Overview, we simply fly to the paper.
      removePaperLayers();
      if (selectedPaperDetails) {
        const currentZoom = viewport.zoom;
        const targetZoom = currentZoom > 12 ? currentZoom : 12;
        flyTo(
          {
            center: {
              lng: selectedPaperDetails.geometry.coordinates[0] as number,
              lat: selectedPaperDetails.geometry.coordinates[1] as number,
            },
            zoom: targetZoom,
            ne: {
              lng: selectedPaperDetails.geometry.coordinates[0] as number,
              lat: selectedPaperDetails.geometry.coordinates[1] as number,
            },
            sw: {
              lng: selectedPaperDetails.geometry.coordinates[0] as number,
              lat: selectedPaperDetails.geometry.coordinates[1] as number,
            },
          },
          true
        );
      }
      return;
    }

    setIsLoadingPapers(true);
    setPapers([]);
    setClusterMetasByClusterId(null);

    const fetchData = async () => {
      try {
        if (!isMounted.current) return;
        removePaperLayers();

        if (currentTab === 1) {
          const selection: IPaperSelection = {
            map_name: mapName,
            type: "paper_citations",
            args: { corpusid: selectedCorpusId },
            fields: ["title", "year", "citationcount"],
          };
          setCurrentSelection(selection);
          await createSelectionLayerConfigs(
            selection,
            "paper_citations",
            mapName,
            upsertLayer,
            5,
            fitToBounds
          );
        } else if (currentTab === 2) {
          const selection: IPaperSelection = {
            map_name: mapName,
            type: "paper_references",
            args: { corpusid: selectedCorpusId },
            fields: ["title", "year", "citationcount"],
          };
          setCurrentSelection(selection);
          await createSelectionLayerConfigs(
            selection,
            "paper_references",
            mapName,
            upsertLayer,
            5,
            fitToBounds
          );
        }
      } catch (error: any) {
        if (isMounted.current) {
          console.error(error);
          snackbar.showSnackbar("Error fetching paper layers", "error");
        }
      } finally {
        if (isMounted.current) setIsLoadingPapers(false);
      }
    };

    fetchData();
  }, [currentTab]);

  const getHoverType = (): HoveredPaperType => {
    if (currentTab === 1) {
      return "paper_citation";
    } else if (currentTab === 2) {
      return "paper_reference";
    } else {
      throw new Error("Invalid tab");
    }
  };

  // Main panel content (common for desktop and mobile)
  const panelContent = (
    <>
      {selectedPaperDetails ? (
        <>
          <div style={{ flex: 1, overflowY: "auto" }}>
            {currentTab === 0 && (
              <div style={{ padding: "20px", paddingTop: 0, paddingBottom: 0 }}>
                {!isMobile && (
                  <>
                    <h3 style={{ textAlign: "left", marginBottom: "-11px" }}>
                      {selectedPaperDetails.title}{" "}
                      {isDebugMode && (
                        <PaperAIButton
                          selectedCorpusId={selectedPaperDetails.id}
                          mapName={mapName}
                        />
                      )}
                      {isDebugMode && (
                        <Button
                          onClick={() => {
                            setPromptingSelection({
                              selectionType: "custom",
                              selectionArgs: {
                                map_name: mapName,
                                ids: [selectedPaperDetails.id],
                              },
                            });
                          }}
                        >
                          <CodeIcon />
                        </Button>
                      )}
                      {isDebugMode && (
                        <CopySelectionButton
                          selectionConfig={{
                            type: "custom",
                            map_name: mapName,
                            ids: [selectedPaperDetails.id],
                          }}
                        />
                      )}
                    </h3>
                    <Divider style={{ marginTop: "10px" }} />
                  </>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {selectedPaperDetails.openaccesspdf?.url && (
                    <IconButton
                      href={selectedPaperDetails.openaccesspdf.url}
                      target="_blank"
                      rel="noreferrer"
                      size="small"
                    >
                      <Tooltip title="Open as PDF" arrow>
                        <PictureAsPdfIcon fontSize="small" />
                      </Tooltip>
                    </IconButton>
                  )}
                </div>
                <div
                  className="meta"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: "10px",
                    marginTop: "10px",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <YearDisplay year={selectedPaperDetails.year} />
                    <AuthorsDisplay authors={selectedPaperDetails.authors} />
                    <CitationCountDisplay
                      citationCount={selectedPaperDetails.citationcount}
                    />
                  </div>
                  <div>
                    {selectedPaperDetails.url && (
                      <IconButton
                        sx={{ padding: 0 }}
                        href={selectedPaperDetails.url}
                        target="_blank"
                        rel="noreferrer"
                        size="small"
                      >
                        <Tooltip title="Open in Semanticscholar" arrow>
                          <LinkIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>
                    )}
                  </div>
                </div>
                {selectedPaperDetails.journal &&
                  selectedPaperDetails.publicationvenueid && (
                    <div
                      style={{
                        marginTop: "10px",
                        textAlign: "left",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <LibraryBooksIcon
                        style={{ color: "#70757a", fontSize: "14px" }}
                      />
                      <span
                        className="journal"
                        onClick={() =>
                          setSelectedJournalId(
                            selectedPaperDetails.publicationvenueid!
                          )
                        }
                        style={{
                          fontSize: "14px",
                          color: "rgba(55, 55, 238, 0.836)",
                          cursor: "pointer",
                        }}
                      >
                        {selectedPaperDetails.journal.name}
                      </span>
                    </div>
                  )}
                <Divider style={{ marginTop: "10px" }} />
              </div>
            )}
            <div style={{ padding: "0px 20px" }}>
              <Tabs
                value={currentTab}
                onChange={(e, newValue) => setCurrentTab(newValue)}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Overview" />
                <Tab
                  label="Citations"
                  disabled={selectedPaperDetails.citationcount === 0}
                />
                <Tab label="References" />
              </Tabs>
            </div>
            {currentTab === 0 && (
              <div style={{ padding: "20px" }}>
                {selectedPaperDetails.tldr ? (
                  <div
                    style={{
                      textAlign: "left",
                      marginTop: "10px",
                    }}
                  >
                    <b>TLDR:</b>{" "}
                    <Typography variant="body2" component="span">
                      {selectedPaperDetails.tldr}
                    </Typography>
                  </div>
                ) : null}
                {selectedPaperDetails.abstract ? (
                  <div style={{ marginTop: "10px" }}>
                    <CollapsibleSegment
                      name={"Abstract"}
                      maxPreviewHeight={20000}
                    >
                      <Typography
                        variant="body2"
                        component="p"
                        style={{
                          margin: 0,
                          textAlign: "left",
                        }}
                      >
                        {selectedPaperDetails.abstract}
                      </Typography>
                    </CollapsibleSegment>
                  </div>
                ) : (
                  <div style={{ marginTop: "10px" }}>
                    <Alert
                      severity="info"
                      icon={<InfoIcon />}
                      style={{ fontSize: "14px" }}
                    >
                      No abstract available for this paper.
                    </Alert>
                  </div>
                )}
                {!selectedPaperDetails.abstract &&
                  !selectedPaperDetails.tldr &&
                  selectedPaperDetails.url && (
                    <div style={{ marginTop: "20px", textAlign: "center" }}>
                      <Button
                        variant="outlined"
                        startIcon={<LinkIcon />}
                        href={selectedPaperDetails.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Show Paper on Semanticscholar
                      </Button>
                    </div>
                  )}
              </div>
            )}
            {(currentTab === 1 || currentTab === 2) && (
              <div style={{ padding: "0px 20px" }}>
                {currentSelection ? (
                  <PaperList
                    selection={currentSelection}
                    type={getHoverType()}
                    sortBy="year"
                    sortDirection="desc"
                    customSortingOptions={[
                      { field: "year", label: "Year" },
                      { field: "citationcount", label: "Citations" },
                    ]}
                    showDummyLoading={false}
                  />
                ) : papers.length > 0 ? (
                  <PaperList
                    papers={papers}
                    type={getHoverType()}
                    sortBy="year"
                    sortDirection="desc"
                    customSortingOptions={[
                      { field: "year", label: "Year" },
                      { field: "citationcount", label: "Citations" },
                    ]}
                    clusterMetas={clusterMetasByClusterId}
                  />
                ) : (
                  <i>No Papers Found</i>
                )}
              </div>
            )}
          </div>
          {!isMobile && (
            <div
              style={{
                padding: "10px",
                borderTop: "1px solid #eee",
                textAlign: "center",
            }}
          >
            <Button
              variant="contained"
              type="button"
              onClick={() => {
                setSelectedCorpusId(null);
                setSelectedPaperDetails(null);
                removePaperLayers();
              }}
            >
              Close
              </Button>
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={20} />
        </div>
      )}
    </>
  );

  // Use MobileDrawer on mobile when a paper is selected.
  if (isMobile && sidebarOpen) {
    return (
      <MobileDrawer
        title={
          selectedPaperDetails ? selectedPaperDetails.title : "Loading Paper..."
        }
        isOpen={true}
        onClose={() => {
          setSelectedCorpusId(null);
          setSelectedPaperDetails(null);
          removePaperLayers();
        }}
        drawerHeight={drawerHeight}
      >
        {panelContent}
      </MobileDrawer>
    );
  }

  // Default desktop view
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        height: "100vh",
        maxHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        width: "400px",
        zIndex: 4,
        boxShadow: "1px 1px 4px #c5c5c5",
        backgroundColor: "white",
      }}
    >
      {panelContent}
    </div>
  );
}

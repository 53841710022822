// src/utils/searchUtils.ts
import { ISourceConfig } from "./contexts/MapContext";
import { createSelectionLayerConfigs } from "./geometryCreationUtils";
import IPaperSelection from "./interfaces/paperSelection";

export async function performSearch(
  mapName: string,
  searchQuery: string,
  viewport: any,
  zoom: number,
  setSearchPaperResults: (results: any) => void,
  setSearchClusterResults: (results: any) => void,
  upsertLayer: (id: string, layer: any, source: ISourceConfig, beforeId?: string) => void,
  navigate: (path: string) => void,
  snackbar: any,
  fitToBounds: (options: {
    points?: { lng: number; lat: number }[];
    bounds?: { ne: { lng: number; lat: number }; sw: { lng: number; lat: number } };
  }, buffer?: number, animated?: boolean) => void,
  bounds?: number[] | null,
  shouldFitToBounds: boolean = true
) {
  try {
    /*const searchFeatures = await fetchSearchResults(mapName, searchQuery, bounds, 1000);*/
    const selection:IPaperSelection={
      map_name: mapName, 
      type: "search",
      args: {q: searchQuery,bounds: bounds || null },
      fields: ["title", "authors", "year", "citationcount"],
    };

    await createSelectionLayerConfigs(selection,"search_papers",mapName,upsertLayer,4,fitToBounds);
    const centerLng = ((viewport.ne.lng + viewport.sw.lng) / 2).toFixed(7);
    const centerLat = ((viewport.ne.lat + viewport.sw.lat) / 2).toFixed(7);

    //filter to papers which have geometry-> throw warning if geometry is missing
    
    /*if (shouldFitToBounds) {
      const coordinates=papersWithGeometry.map((paper:IPaperMeta)=>({lng: paper.geometry!.coordinates[0] as number,lat: paper.geometry!.coordinates[1] as number}));
      fitToBounds({points: coordinates},0.1,true);
    }*/
  } catch (e: any) {
    if (e.response?.status === 404) {
      snackbar.showSnackbar("No papers found", "info");
    } else {
      snackbar.showSnackbar("Error searching", "error");
      console.error(e);
    }
  }
}

import "./AuthorDisplay.scss";
import IPaperDetailsWithCoordinates from "../../interfaces/paperDetails";
import IPaperMeta from "../../interfaces/paperMeta";
import MapContext from "../../contexts/MapContext";
import { useContext } from "react";
import IAuthorMeta from "../../interfaces/authorMeta";

export default function AuthorsDisplay({
  authors,
}: {
  authors: IAuthorMeta[] | null;
}) {
  const { setSelectedAuthorId } = useContext(MapContext);
  return (
    <div style={{ textAlign: "left" }} className="authorDisplay">
      {(authors ?? []).map((author, idx) => {
        return (
          <span
            onClick={() => {
              setSelectedAuthorId(author.authorId.toString());
            }}
            className="author"
            style={{ paddingRight: "5px" }}
            key={author.authorId + idx}
          >
            {author.name} {idx < authors!.length - 1 ? "," : ""}
          </span>
        );
      })}
    </div>
  );
}

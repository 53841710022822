import _ from "lodash";
import React, { createContext, useEffect, useRef, useState } from "react";
import IClusterDetails from "../interfaces/clusterDetails";
import { IClusterMetaSmall } from "../interfaces/clusterMeta";
import IMapPosition from "../interfaces/mapPosition";
import IPaperDetailsWithCoordinates from "../interfaces/paperDetails";
import IPaperMeta from "../interfaces/paperMeta";
import IViewport from "../interfaces/viewport";
import { LayerProps, LngLatBoundsLike, MapRef } from "react-map-gl";
import IGeojsonFeature from "../interfaces/geojsonFeature";
import IPromptingSelection from "../interfaces/promptingSelection";
import { ILayer, ISourceJSON } from "../interfaces/mapConfig";
import IAuthorDetails from "../interfaces/authorDetails";
import IAuthorMeta from "../interfaces/authorMeta";
import IJournalMeta from "../interfaces/journalMeta";
import { LngLatBounds } from "maplibre-gl";
import IJournalDetails from "../interfaces/journalDetails";

export type HoveredPaperType =
  | "search"
  | "journal_paper"
  | "author_paper"
  | "author_citation"
  | "author_reference"
  | "paper_citation"
  | "paper_reference"
  | "paper_similar"
  | "cluster";

export interface IHoveredPaper {
  id: number;
  geometry?: IGeojsonFeature;
  meta?: {
    authors?: IAuthorMeta[];
    year: number;
    citationcount: number;
    journal?: IJournalMeta;
    tldr?: string | null;
    abstract?: string | null;
    title?: string; // Optional, only required if the source is "list"
  } | null;
  title?: string; // Optional, only required if the source is "list"
  source: "list" | "map"; // Indicate the source of the hover event
  type?: HoveredPaperType; //used for identifying which landmark to use
}

export interface IHighlightLayer {
  source: any | null;
  layer: LayerProps;
}

export interface IHoveredCluster {
  cluster_id: number;
  label?: string;
  source: "list" | "map" | "label";
  geometry?: IGeojsonFeature;
}

export interface ISourceConfig {
  type: string;
  data?: any;
  url?: string;
  tiles?: string[];
  [key: string]: any;
}

export interface YearFilter {
  startYear: number;
  endYear: number;
}

//TODO add geometrical details (e.g. lng lat or outline)

interface MapContextType {
  mapName: string;
  darkmode: boolean;
  isMobile: boolean;
  setIsMobile: (isMobile: boolean) => void;
  removeClusterLayers: () => void;
  removeAuthorLayers: () => void;
  removePaperLayers: () => void;
  removeJournalLayers: () => void;
  removeLayersByPrefix: (prefix: string) => void;
  setDarkmode: (darkmode: boolean) => void;
  sidebarOpen: boolean;
  promptingSelection: null | IPromptingSelection;
  viewport: IViewport;
  targetCenter: IMapPosition | null;
  zoom: number;
  selectedCorpusId: string | null;
  selectedCluster: ISelectedCluster | null;
  hoveredPaper: IHoveredPaper | null;
  hoveredCluster: IHoveredCluster | null;
  stickyHoveredPaper: IHoveredPaper | null;
  stickyHoveredCluster: IHoveredCluster | null;
  hoveredPaperDetails: any;
  selectedPaperDetails: IPaperDetailsWithCoordinates | null;
  searchPaperResults: IPaperMeta[] | null;
  searchClusterResults: Record<number, IClusterMetaSmall> | null;
  searchQuery: string | null;
  selectedClusterDetails: IClusterDetails | null;
  selectedAuthorId: string | null;
  selectedAuthorDetails: IAuthorDetails | null;
  selectedJournalId: string | null;
  selectedJournalDetails: IJournalDetails | null;
  yearFilter: YearFilter | null;
  isMapInteraction: boolean; // Indicates if the last user interaction was with the map
  setIsMapInteraction: (isMapInteraction: boolean) => void;
  setYearFilter: (filter: YearFilter | null) => void;
  setSelectedAuthorDetails: (details: IAuthorDetails | null) => void;
  setSelectedAuthorId: (id: string | null) => void;
  setSelectedJournalDetails: (details: IJournalDetails | null) => void;
  setSelectedJournalId: (id: string | null) => void;
  setSearchClusterResults: (
    results: Record<number, IClusterMetaSmall> | null
  ) => void;
  setSelectedClusterDetails: (details: IClusterDetails | null) => void;
  setMapName: (name: string) => void;
  setSelectedCluster: (cluster: ISelectedCluster | null) => void;
  setSidebarOpen: (open: boolean) => void;
  setPromptingSelection: (config: null | IPromptingSelection) => void;
  setViewport: (viewport: IViewport) => void;
  setTargetCenter: (viewport: IMapPosition | null) => void;
  setZoom: (zoom: number) => void;
  setSelectedCorpusId: (id: string | null) => void;
  setHoveredPaper: (hoveredPaper: IHoveredPaper | null) => void;
  setHoveredCluster: (hoveredCluster: IHoveredCluster | null) => void;
  setStickyHoveredPaper: (stickyHoveredPaper: IHoveredPaper | null) => void;
  setStickyHoveredCluster: (stickyHoveredCluster: IHoveredCluster | null) => void;
  setHoveredPaperDetails: (details: any) => void;
  setSelectedPaperDetails: (
    details: IPaperDetailsWithCoordinates | null
  ) => void;
  setSearchQuery: (query: string | null) => void;
  setSearchPaperResults: (results: IPaperMeta[] | null) => void;
  sourceJSON: ISourceJSON;
  setSourceJSON: (sourceJSON: ISourceJSON) => void;
  addLayer: (
    layerConfig: ILayer,
    sourceConfig: any | null,
    beforeId?: string
  ) => void;
  removeLayer: (id: string) => void;
  upsertLayer: (
    id: string,
    layerConfig: ILayer,
    sourceConfig?: any | null,
    beforeId?: string
  ) => void;
  zoomIn: (animated?: boolean) => void;
  zoomOut: (animated?: boolean) => void;
  flyTo: (viewport: IViewport, animated?: boolean) => void;
  flyToAnimated: (viewport: IViewport) => void;
  setMapRef: (ref: MapRef | null) => void;
  fitToBounds: (
    options: {
      points?: { lng: number; lat: number }[];
      bounds?: {
        ne: { lng: number; lat: number };
        sw: { lng: number; lat: number };
      };
    },
    buffer?: number,
    animated?: boolean,
    duration?: number
  ) => void;
  resetFeatureState: (id: number, type: string) => void;
  selectFeature: (id: number, type: string) => void;
  deselectFeature: () => void;
}

// Create the context with default values
const MapContext = createContext<MapContextType>({
  mapName: "",
  removeClusterLayers: () => {},
  removeAuthorLayers: () => {},
  removePaperLayers: () => {},
  removeJournalLayers: () => {},
  removeLayersByPrefix: () => {},
  selectedAuthorId: null,
  setSelectedAuthorId: () => {},
  selectedAuthorDetails: null,
  setSelectedAuthorDetails: () => {},
  selectedJournalId: null,
  setSelectedJournalId: () => {},
  selectedJournalDetails: null,
  setSelectedJournalDetails: () => {},
  darkmode: false,
  isMobile: false,
  setIsMobile: () => {},
  setDarkmode: () => {},
  selectedClusterDetails: null,
  searchClusterResults: null,
  setSearchClusterResults: () => {},
  setSelectedClusterDetails: () => {},
  selectedCluster: null,
  viewport: {
    ne: { lng: 0, lat: 0 },
    sw: { lng: 0, lat: 0 },
    center: { lng: 0, lat: 0 },
    zoom: 0,
  },
  targetCenter: null,
  zoom: 0,
  selectedCorpusId: null,
  hoveredPaper: null,
  hoveredCluster: null,
  stickyHoveredPaper: null,
  stickyHoveredCluster: null,
  hoveredPaperDetails: null,
  selectedPaperDetails: null,
  sidebarOpen: false,
  promptingSelection: null,
  searchQuery: null,
  searchPaperResults: null,
  yearFilter: null,
  isMapInteraction: false,
  setIsMapInteraction: () => {},
  setYearFilter: () => {},
  setSelectedCluster: () => {},
  setMapName: () => {},
  setSidebarOpen: () => {},
  setPromptingSelection: () => {},
  setViewport: () => {},
  setTargetCenter: () => {},
  setZoom: () => {},
  setSelectedCorpusId: () => {},
  setHoveredPaper: () => {},
  setHoveredCluster: () => {},
  setStickyHoveredPaper: () => {},
  setStickyHoveredCluster: () => {},
  setHoveredPaperDetails: () => {},
  setSelectedPaperDetails: () => {},
  setSearchQuery: () => {},
  setSearchPaperResults: () => {},
  sourceJSON: {
    layers: [],
    sources: {},
    glyphs: "",
    version: 0,
  },
  setSourceJSON: () => {},
  addLayer: () => {},
  removeLayer: () => {},
  upsertLayer: () => {},
  zoomIn: () => {},
  zoomOut: () => {},
  flyTo: () => {},
  flyToAnimated: () => {},
  fitToBounds: () => {},
  setMapRef: () => {},
  resetFeatureState: () => {},
  selectFeature: () => {},
  deselectFeature: () => {},
});

export interface ISelectedCluster {
  cluster_id: number;
  bounds?: number[] | null;
  geometry?: IGeojsonFeature;
}

export default MapContext;

export const MapProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [darkmode, setDarkmode] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedAuthorId, setSelectedAuthorId] = useState<string | null>(null);
  const [selectedAuthorDetails, setSelectedAuthorDetails] =
    useState<IAuthorDetails | null>(null);
  const [selectedJournalId, setSelectedJournalId] = useState<string | null>(null);
  const [selectedJournalDetails, setSelectedJournalDetails] =
    useState<IJournalDetails | null>(null);
  const [viewport, setViewport] = useState<IViewport>({
    ne: { lng: 0, lat: 0 },
    sw: { lng: 0, lat: 0 },
    center: { lng: 0, lat: 0 },
    zoom: 0,
  });
  const [targetCenter, setTargetCenter] = useState<IMapPosition | null>(null);
  const [zoom, setZoom] = useState(0);
  const [selectedClusterDetails, setSelectedClusterDetails] =
    useState<IClusterDetails | null>(null);
  const [searchClusterResults, setSearchClusterResults] = useState<Record<
    number,
    IClusterMetaSmall
  > | null>(null);
  const [selectedCorpusId, setSelectedCorpusId] = useState<string | null>(null);
  const [selectedCluster, setSelectedCluster] =
    useState<ISelectedCluster | null>(null);
  const [selectedPaperDetails, setSelectedPaperDetails] =
    useState<IPaperDetailsWithCoordinates | null>(null);
  const [hoveredPaper, setHoveredPaper] = useState<IHoveredPaper | null>(null);
  const [hoveredCluster, setHoveredCluster] = useState<IHoveredCluster | null>(
    null
  );
  const [stickyHoveredPaper, setStickyHoveredPaper] = useState<IHoveredPaper | null>(null);
  const [stickyHoveredCluster, setStickyHoveredCluster] = useState<IHoveredCluster | null>(null);
  const [hoveredPaperDetails, setHoveredPaperDetails] = useState(null);
  const [yearFilter, setYearFilter] = useState<YearFilter | null>(null);
  // Tracks whether the last user interaction was with the map
  const [isMapInteraction, setIsMapInteraction] = useState(false);
  const debouncedSetViewport = _.debounce(setViewport, 200);
  const debouncedSetZoom = _.debounce(setZoom, 200);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [promptingSelection, setPromptingSelection] =
    useState<null | IPromptingSelection>(null);
  const [mapName, setMapName] = useState("");
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [searchPaperResults, setSearchPaperResults] = useState<
    IPaperMeta[] | null
  >(null);
  const [sourceJSON, setSourceJSON] = useState<ISourceJSON>({
    layers: [],
    sources: {},
    glyphs: "",
    version: 1,
  });

  const mapRef = useRef<MapRef | null>(null);

  useEffect(() => {
    //TODO handle states -> e.g clear search if corpus is selected or author is selected
    if (!selectedAuthorId) {
      removeAuthorLayers();
      setSelectedAuthorDetails(null);
    }
    if (!selectedJournalId) {
      removeJournalLayers();
      setSelectedJournalDetails(null);
    }
    if (!selectedCorpusId) {
      setSelectedPaperDetails(null);
      removePaperLayers();
    }
    if (!selectedCluster) {
      removeClusterLayers();
    }
  }, [selectedCorpusId, selectedAuthorId, selectedJournalId, selectedCluster]);

  useEffect(() => {
    if (selectedCluster) {
      setSelectedAuthorId(null);
      setSelectedCorpusId(null);
      setSelectedJournalId(null);
      setSearchQuery(null);
    }
  }, [selectedCluster]);

  useEffect(() => {
    if (selectedJournalId) {
      setSearchQuery(null);
      setSearchPaperResults(null);
      setSelectedCluster(null);
      setSelectedCorpusId(null);
      setSelectedAuthorId(null);
    }
  }, [selectedJournalId]);

  useEffect(() => {
    if (selectedAuthorId) {
      setSelectedCluster(null);
      setSelectedCorpusId(null);
      setSelectedJournalId(null);
    }
  }, [selectedAuthorId]);

  useEffect(() => {
    if (selectedJournalId) {
      setSelectedCluster(null);
      setSelectedCorpusId(null);
      setSelectedAuthorId(null);
    }
  }, [selectedJournalId]);

  useEffect(() => {
    if (selectedCorpusId) {
      setSelectedAuthorId(null);
      setSelectedCluster(null);
      setSelectedJournalId(null);
    }
  }, [selectedCorpusId]);

  const findLayerIndexById = (layers: ILayer[], beforeId: string): number => {
    return layers.findIndex((layer) => layer.id.startsWith(beforeId));
  };

  const resetFeatureState = (id: number, type: string) => {};

  const selectFeature = (id: number, type: string) => {};

  const deselectFeature = () => {};
  const addLayer = (
    layerConfig: ILayer,
    sourceConfig: ISourceConfig | null,
    beforeId?: string
  ) => {
    setSourceJSON((prev: ISourceJSON) => {
      const newLayers: ILayer[] = [...prev.layers];
      const index = beforeId
        ? findLayerIndexById(prev.layers as any, beforeId)
        : -1;
      if (index !== -1) {
        newLayers.splice(index, 0, layerConfig);
      } else {
        newLayers.push(layerConfig);
      }

      const newSources = { ...prev.sources };
      if (sourceConfig) {
        newSources[layerConfig.source] = sourceConfig;
      }

      return { ...prev, layers: newLayers, sources: newSources };
    });
  };

  const removeLayersByPrefix = (prefix: string) => {
    setSourceJSON((prev: ISourceJSON) => {
      // Find all layers that start with the given prefix
      const layersToRemove = prev.layers.filter((layer) => 
        layer.id.startsWith(prefix)
      );
      
      // Create a new array without the layers that start with the prefix
      const newLayers = prev.layers.filter((layer) => 
        !layer.id.startsWith(prefix)
      );
      
      // Create a new sources object without the sources used by the removed layers
      const newSources = { ...prev.sources };
      layersToRemove.forEach(layer => {
        if (newSources[layer.source]) {
          delete newSources[layer.source];
        }
      });
      
      return { ...prev, layers: newLayers, sources: newSources };
    });
  };

  const removeAuthorLayers = () => {
    removeLayersByPrefix("author_papers");
    removeLayersByPrefix("author_citations");
    removeLayersByPrefix("author_references");
  };

  const removeJournalLayers = () => {
    removeLayersByPrefix("publication_papers");
  };

  const removePaperLayers = () => {
    removeLayersByPrefix("paper_citations");
    removeLayersByPrefix("paper_references");
  }

  const removeClusterLayers = () => {
    removeLayer("selected_cluster");
  };

  const removeLayer = (id: string) => {
    setSourceJSON((prev: ISourceJSON) => {
      const newLayers = prev.layers.filter((layer) => layer.id !== id);

      const newSources = { ...prev.sources };
      const layer = prev.layers.find((layer) => layer.id === id);
      if (layer && newSources[layer.source]) {
        delete newSources[layer.source];
      }

      return { ...prev, layers: newLayers, sources: newSources };
    });
  };

  const upsertLayer = (
    id: string,
    newLayerConfig: ILayer,
    newSourceConfig?: ISourceConfig,
    beforeId?: string
  ) => {
    setSourceJSON((prev: ISourceJSON) => {
      const layerIndex = findLayerIndexById(prev.layers, id);
      const newLayers = [...prev.layers];
      const newSources = { ...prev.sources };

      if (layerIndex === -1) {
        // Layer does not exist, insert it
        const index = beforeId
          ? findLayerIndexById(prev.layers as any, beforeId)
          : -1;
        if (index !== -1) {
          newLayers.splice(index, 0, newLayerConfig);
        } else {
          newLayers.push(newLayerConfig);
        }
      } else {
        // Layer exists, update it
        newLayers[layerIndex] = { ...newLayers[layerIndex], ...newLayerConfig };

        if (newLayerConfig.id in newSources) {
          // Remove existing (custom) source for the given layer if it exists
          delete newSources[newLayerConfig.id];
        }
      }

      if (newSourceConfig) {
        // Add a new source if it exists
        newSources[newLayerConfig.source] = {
          ...newSources[newLayerConfig.source],
          ...newSourceConfig,
        };
      }

      return { ...prev, layers: newLayers, sources: newSources };
    });
  };

  const zoomIn = (animated: boolean = true) => {
    setZoom((prevZoom) => {
      const newZoom = Math.min(prevZoom + 1, 20);
      if (animated && mapRef.current) {
        mapRef.current.getMap().flyTo({ zoom: newZoom });
      } else {
        return newZoom;
      }
      return newZoom;
    });
  };

  const zoomOut = (animated: boolean = true) => {
    setZoom((prevZoom) => {
      const newZoom = Math.max(prevZoom - 1, 1);
      if (animated && mapRef.current) {
        mapRef.current.getMap().flyTo({ zoom: newZoom });
      } else {
        return newZoom;
      }
      return newZoom;
    });
  };

  const flyTo = (viewport: IViewport, animated: boolean = true) => {
    setViewport(viewport);
    if (mapRef.current) {
      mapRef.current.getMap().flyTo({
        center: [viewport.center.lng, viewport.center.lat],
        zoom: viewport.zoom,
        essential: animated, // this animation is considered essential with respect to prefers-reduced-motion
      });
    }
  };

  const fitToBounds = (
    options: {
      points?: { lng: number; lat: number }[];
      bounds?: {
        ne: { lng: number; lat: number };
        sw: { lng: number; lat: number };
      };
    },
    buffer: number = 0.2, // Default buffer of 20%
    animated: boolean = true,
    duration: number = 2000 // Animation duration in milliseconds
  ) => {
    if (!mapRef.current) {
      console.warn("Map is not initialized yet.");
      return;
    }

    const map = mapRef.current.getMap();

    let bounds: [number, number, number, number]; // [minLng, minLat, maxLng, maxLat]

    if (options.bounds) {
      const { ne, sw } = options.bounds;
      bounds = [sw.lng, sw.lat, ne.lng, ne.lat];
    } else if (options.points && options.points.length > 0) {
      // Calculate bounds from points
      const lats = options.points.map((p) => p.lat);
      const lngs = options.points.map((p) => p.lng);
      const minLat = Math.min(...lats);
      const maxLat = Math.max(...lats);
      const minLng = Math.min(...lngs);
      const maxLng = Math.max(...lngs);
      bounds = [minLng, minLat, maxLng, maxLat];
    } else {
      console.warn("No points or bounds provided to fitToBounds.");
      return;
    }

    // Apply buffer
    const bufferedBounds = applyBuffer(bounds, buffer, map);

    // Fit the map to the buffered bounds
    map.fitBounds(bufferedBounds, {
      padding: 20, // Adjust padding as needed
      animate: animated,
      duration: duration, // Control animation speed
      essential: true, // Considered essential for accessibility
    });

    // **Retrieve and set accurate viewport after fitting bounds**
    const updatedBounds = map.getBounds();
    const center = updatedBounds.getCenter();
    const newZoom = map.getZoom();

    setViewport({
      sw: {
        lng: updatedBounds.getSouthWest().lng,
        lat: updatedBounds.getSouthWest().lat,
      },
      ne: {
        lng: updatedBounds.getNorthEast().lng,
        lat: updatedBounds.getNorthEast().lat,
      },
      center: {
        lng: center.lng,
        lat: center.lat,
      },
      zoom: newZoom,
    });
    setZoom(newZoom);
  };

  const applyBuffer = (
    bounds: [number, number, number, number],
    buffer: number,
    map: any // Replace 'any' with the actual type if available
  ): [number, number, number, number] => {
    const mapBounds = new LngLatBounds(
      [bounds[0], bounds[1]],
      [bounds[2], bounds[3]]
    );

    const center = mapBounds.getCenter();
    const ne = mapBounds.getNorthEast();
    const sw = mapBounds.getSouthWest();

    const width = ne.lng - sw.lng;
    const height = ne.lat - sw.lat;

    const bufferedWidth = width * buffer;
    const bufferedHeight = height * buffer;

    const bufferedNe = [ne.lng + bufferedWidth, ne.lat + bufferedHeight];
    const bufferedSw = [sw.lng - bufferedWidth, sw.lat - bufferedHeight];

    return [bufferedSw[0], bufferedSw[1], bufferedNe[0], bufferedNe[1]];
  };

  const flyToAnimated = (viewport: IViewport) => {
    flyTo(viewport, true);
  };

  const setMapRefFunction = (ref: MapRef | null) => {
    mapRef.current = ref;
  };

  return (
    <MapContext.Provider
      value={{
        removeClusterLayers,
        removeAuthorLayers,
        removePaperLayers,
        removeJournalLayers,
        removeLayersByPrefix,
        selectedAuthorId,
        setSelectedAuthorId,
        selectedAuthorDetails,
        setSelectedAuthorDetails,
        selectedJournalId,
        setSelectedJournalId,
        selectedJournalDetails,
        setSelectedJournalDetails,
        darkmode,
        setDarkmode,
        isMobile,
        setIsMobile,
        searchClusterResults,
        setSearchClusterResults,
        selectedClusterDetails,
        setSelectedClusterDetails,
        selectedCluster,
        setSelectedCluster,
        searchPaperResults,
        setSearchPaperResults,
        searchQuery,
        setSearchQuery,
        mapName,
        setMapName,
        viewport,
        targetCenter,
        sidebarOpen,
        setSidebarOpen,
        promptingSelection,
        setPromptingSelection,
        zoom,
        selectedCorpusId,
        hoveredPaper,
        setHoveredPaper,
        hoveredCluster,
        setHoveredCluster,
        stickyHoveredPaper,
        setStickyHoveredPaper,
        stickyHoveredCluster,
        setStickyHoveredCluster,
        hoveredPaperDetails,
        setHoveredPaperDetails,
        selectedPaperDetails,
        yearFilter,
        setYearFilter,
        isMapInteraction,
        setIsMapInteraction,
        setViewport: debouncedSetViewport,
        setTargetCenter,
        setZoom: debouncedSetZoom,
        setSelectedCorpusId,
        setSelectedPaperDetails,
        sourceJSON,
        setSourceJSON,
        addLayer,
        removeLayer,
        upsertLayer,
        zoomIn,
        zoomOut,
        flyTo,
        flyToAnimated,
        setMapRef: setMapRefFunction,
        fitToBounds,
        resetFeatureState,
        selectFeature,
        deselectFeature,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

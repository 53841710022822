import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import MapContext, { HoveredPaperType } from "../../contexts/MapContext";
import IPaperMeta from "../../interfaces/paperMeta";
import CitationCountDisplay from "../paper/CitationCountDisplay";

interface PaperListItemProps {
  paper: IPaperMeta;
  type: HoveredPaperType;
  // New callback to notify parent (PaperList) when an item is clicked
  onPaperClick: (paper: IPaperMeta, type: HoveredPaperType, event: React.MouseEvent<HTMLDivElement>) => void;
}

export default function PaperListItem({ paper, type, onPaperClick }: PaperListItemProps) {
  const { setHoveredPaper,isMobile } = useContext(MapContext);

  return (
    <div
      className="paperListItem"
      onMouseOver={() => {
        if (!isMobile) {
          setHoveredPaper({
            source: "list",
            geometry: paper.geometry,
            title: paper.title,
            id: paper.id,
            type: type,
          });
        }
      }}
      onMouseLeave={() => {
        if (!isMobile) {
          setHoveredPaper(null);
        }
      }}
      onClick={(e) => onPaperClick(paper, type, e)}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter") onPaperClick(paper, type, e as any);
      }}
      aria-label={`Paper titled ${paper.title}`}
      style={{ position: "relative" }}
    >
      <Typography
        variant="subtitle2"
        component="div"
        className="paperTitle"
        sx={{
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "left",
        }}
      >
        {paper.title}
      </Typography>
      <Box display="flex" alignItems="center">
        <PeopleOutlinedIcon fontSize="small" style={{ marginRight: 4 }} />
        <Typography variant="caption" color="textSecondary">
          {paper.authors && paper.authors.length > 0
            ? `${paper.authors[0].name}${
                paper.authors.length > 1 ? ` +${paper.authors.length - 1}` : ""
              }`
            : "Unknown author"}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="caption" color="textSecondary" className="paperYear">
          Year: {paper.year}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          |
        </Typography>
        <CitationCountDisplay citationCount={paper.citationcount} />
      </Box>
    </div>
  );
}
// src/handlers/onMapClick.ts

import { IHoveredCluster } from "../contexts/MapContext";
import { IHoveredPaper } from "../contexts/MapContext";
import { IMapConfig } from "../interfaces/mapConfig";

interface OnMapClickContext {
  setSelectedCluster: (cluster: any) => void;
  setSelectedCorpusId: (id: any) => void;
  setSelectedClusterDetails: (details: any) => void;
  setHoveredPaper: (hoveredPaper: IHoveredPaper | null) => void;
  setHoveredCluster: (hoveredCluster: IHoveredCluster | null) => void;
  setStickyHoveredCluster: (hoveredCluster: IHoveredCluster | null) => void;
  setStickyHoveredPaper: (hoveredPaper: IHoveredPaper | null) => void;
  hoverMode: string;
}

const onMapClick = (event: any, context: OnMapClickContext, config: IMapConfig) => {
  const { setSelectedCluster, setSelectedCorpusId, setSelectedClusterDetails, setHoveredCluster, setHoveredPaper, setStickyHoveredCluster, setStickyHoveredPaper, hoverMode } = context;
  const feature = event.features && event.features[0];
  if (feature && feature.id) {
    const assessIsCorpusFeature = (feature: any) => {
      let validLayers = [
        "papers",
        "search_papers_landmarks",
        "author_papers_landmarks",
        "author_citations_landmarks",
        "paper_references_landmarks",
        "paper_citations_landmarks",
        "author_references_landmarks",
        "author_papers",
        "search_papers",
        "publication_papers",
        "publication_papers_landmarks",
        "paper_labels",
        "selected_paper",
        "paper_polygons",
      ];
  
      //add a version _with_title and _without_title for each of the layers
      const validLayersWithTitle = validLayers.map(
        (layer) => `${layer}_with_titles`
      );
      const validLayersWithoutTitle = validLayers.map(
        (layer) => `${layer}_without_titles`
      );
      validLayers = [
        ...validLayers,
        ...validLayersWithTitle,
        ...validLayersWithoutTitle,
      ];
      return (
        validLayers.includes(feature.sourceLayer) ||
        validLayers.includes(feature.source) ||
        feature.source?.startsWith("search_landmarks") ||
        feature.sourceLayer?.startsWith("highlight_")
      );
    };

    const isCluster = config.cluster_layer_prefixes?.some(prefix => 
      feature.sourceLayer?.startsWith(prefix)
    );
    
    const isCorpusFeature = assessIsCorpusFeature(feature);

    console.log(feature.source, feature.sourceLayer, isCluster ? "cluster" : (isCorpusFeature ? "corpus" : "unknown"));

    if (isCluster) {
      let bounds: any = null;
      if (
        "min_lng" in feature.properties &&
        "min_lat" in feature.properties &&
        "max_lng" in feature.properties &&
        "max_lat" in feature.properties
      ) {
        bounds = [
          feature.properties.min_lng,
          feature.properties.min_lat,
          feature.properties.max_lng,
          feature.properties.max_lat,
        ];
      }

      if (hoverMode === "sticky") {
        setStickyHoveredCluster({
          cluster_id: feature.id,
          source: "label",
          label: `${feature.properties.label}`,
          geometry: feature.geometry,
        });
      } else {
        setSelectedCluster({
          cluster_id: feature.id,
          bounds: bounds,
        });
      }
    } else if (isCorpusFeature) {
      // Extract geometry - for papers, it might be a rectangle with 4 coordinates
      let geometry = feature.geometry;
      
      // For paper rectangles, we might need to calculate the center point
      if (feature.sourceLayer === "papers" || feature.source === "papers") {
        // If the geometry represents a rectangle (e.g., has coordinates array with 4 points)
        if (geometry?.coordinates && Array.isArray(geometry.coordinates[0]) && geometry.coordinates[0].length === 5) {
          // Calculate center of the rectangle
          const coords = geometry.coordinates[0];
          const minX = Math.min(...coords.map(c => c[0]));
          const maxX = Math.max(...coords.map(c => c[0]));
          const minY = Math.min(...coords.map(c => c[1]));
          const maxY = Math.max(...coords.map(c => c[1]));
          
          // Create a point geometry at the center
          geometry = {
            type: "Point",
            coordinates: [(minX + maxX) / 2, (minY + maxY) / 2]
          };
        }
      }
      
      if (hoverMode === "sticky") {
        setStickyHoveredPaper({
          id: feature.id,
          source: "map",
          geometry: geometry,
          title: feature.properties.title,
        });
      } else {
        setHoveredPaper({
          id: feature.id,
          source: "map",
          geometry: geometry,
          title: feature.properties.title,
          type: feature.sourceLayer || feature.source,
          meta: null,
        });
      }
      //setSelectedCluster(null);
      //setSelectedClusterDetails(null);
    }
  } else {
    //setSelectedCluster(null);
    //setSelectedClusterDetails(null);
    //setSelectedCorpusId(null);
    setStickyHoveredCluster(null);
    setStickyHoveredPaper(null);
    // Assuming setSelectedPaperDetails exists in context
    // setSelectedPaperDetails(null);
  }
};

export default onMapClick;
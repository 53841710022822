// src/hooks/useFeatureSelection.ts

import { useEffect, useContext } from "react";
import MapContext from "../contexts/MapContext";

const useFeatureSelection = (
  map: any,
  selectedFeature: { id: any; type: any },
  config: any
) => {
  const {
    resetFeatureState,
    selectFeature,
    deselectFeature,
  } = useContext(MapContext);

  useEffect(() => {
    if (selectedFeature.id) {
      selectFeature(selectedFeature.id, selectedFeature.type);
    } else {
      deselectFeature();
    }
  }, [selectedFeature, selectFeature, deselectFeature]);

  const setFeatureState = (
    featureId: any,
    featureType: any,
    isSelected: any
  ) => {
    // Implement feature state setting logic here
    // This function can be moved to utils if reused elsewhere
  };

  return { setFeatureState };
};

export default useFeatureSelection;
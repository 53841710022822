import CodeIcon from "@mui/icons-material/Code";
import {
  Button,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  Typography,
  Box,
  styled,
  IconButton,
} from "@mui/material";
import { Global } from "@emotion/react";
import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  TouchEvent,
} from "react";
import { useLocation } from "react-router-dom";

import { fetchAuthorDetails } from "../../requests";

import MapContext, { HoveredPaperType } from "../../contexts/MapContext";
import { useSnackbar } from "../../contexts/SnackbarContext";

import IPaperMeta from "../../interfaces/paperMeta";
import AIQuestionButton from "../ai/AIQuestionButton";
import AIQuestionFunctionCallingButton from "../ai/AIQuestionFunctionCallingButton";
import PaperList from "../lists/PaperList";
import CitationCountDisplay from "../paper/CitationCountDisplay";
import KeywordsChipsRenderer from "../renderers/KeywordsChipRenderer";
import CollapsibleSegment from "./CollapsibleSegment";

import { createSelectionLayerConfigs } from "../../geometryCreationUtils";
import { IClusterMetaSmall } from "../../interfaces/clusterMeta";
import IPaperSelection from "../../interfaces/paperSelection";
import CopySelectionButton from "../utils/CopySelectionButton";
import ResponsiveImage from "../utils/ResponsiveImage";

import MobileDrawer from "./MobileDrawer";

// Constants for the mobile drawer
const drawerBleeding = 56;

export default function AuthorDetailsPanel() {
  const {
    selectedAuthorId,
    selectedAuthorDetails,
    setSelectedAuthorId,
    setSelectedAuthorDetails,
    upsertLayer,
    removeAuthorLayers,
    mapName,
    isMobile,
    fitToBounds,
  } = useContext(MapContext);

  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [isLoadingPapers, setIsLoadingPapers] = useState(false);
  const [papers, setPapers] = useState<IPaperMeta[]>([]);
  const [clusterMetasByClusterId, setClusterMetasByClusterId] =
    useState<null | Record<number, IClusterMetaSmall>>(null);
  const isMounted = useRef(true);
  const [currentTab, setCurrentTab] = useState(0); // 0: Author Papers, 1: Citations, 2: References
  const snackbar = useSnackbar();
  const location = useLocation();
  const isDebugMode = location.search.includes("debug");
  const [currentSelection, setCurrentSelection] =
    useState<IPaperSelection | null>(null);

  // Determine if sidebar (mobile drawer) should be open
  const sidebarOpen = !!selectedAuthorId;

  // Mobile drawer height calculation
  const vh = window.innerHeight;
  const minHeight = vh * 0.15; // header always visible
  const maxHeight = vh * 0.9;
  const defaultHeight = vh * 0.35;
  const [drawerHeight, setDrawerHeight] = useState<number>(defaultHeight);

  // For tracking touch events
  const [startY, setStartY] = useState<number | null>(null);
  const [startHeight, setStartHeight] = useState<number>(drawerHeight);

  const handleTouchStart = (e: TouchEvent) => {
    setStartY(e.touches[0].clientY);
    setStartHeight(drawerHeight);
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (startY === null) return;
    const deltaY = startY - e.touches[0].clientY;
    let newHeight = startHeight + deltaY;
    if (newHeight > maxHeight) newHeight = maxHeight;
    if (newHeight < minHeight) newHeight = minHeight;
    setDrawerHeight(newHeight);
  };

  const handleTouchEnd = () => {
    setStartY(null);
  };

  const toggleDrawerHeight = () => {
    // Toggle between default and expanded heights
    if (drawerHeight < maxHeight * 0.95) {
      setDrawerHeight(maxHeight);
    } else {
      setDrawerHeight(defaultHeight);
    }
  };

  // Cleanup effect
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    isMounted.current = true;
  }, [selectedAuthorId]);

  // Fetch author details when selectedAuthorId changes
  useEffect(() => {
    if (selectedAuthorId) {
      setIsLoadingDetails(true);
      fetchAuthorDetails(selectedAuthorId)
        .then((authorDetails) => {
          if (isMounted.current) {
            setSelectedAuthorDetails(authorDetails);
          }
        })
        .catch((error) => {
          if (isMounted.current) {
            console.error(error);
            snackbar.showSnackbar("Error fetching author details", "error");
            setSelectedAuthorId(null); // Hide the panel
          }
        })
        .finally(() => {
          if (isMounted.current) setIsLoadingDetails(false);
        });
    } else {
      setSelectedAuthorDetails(null);
      removeAuthorLayers();
    }
  }, [selectedAuthorId, mapName]);

  // Fetch papers based on the selected tab
  useEffect(() => {
    if (!selectedAuthorId) {
      return;
    }
    const fetchData = async () => {
      setIsLoadingPapers(true);
      setPapers([]);
      try {
        removeAuthorLayers();
        let selection: IPaperSelection;
        if (currentTab === 0) {
          selection = {
            map_name: mapName,
            type: "author_papers",
            args: { author_id: selectedAuthorId },
            fields: ["title"],
          };
          setCurrentSelection(selection);
          await createSelectionLayerConfigs(
            selection,
            "author_papers",
            mapName,
            upsertLayer,
            5,
            fitToBounds
          );
        } else if (currentTab === 1) {
          selection = {
            map_name: mapName,
            type: "author_citations",
            args: { author_id: selectedAuthorId },
            fields: ["title"],
          };
          setCurrentSelection(selection);
          await createSelectionLayerConfigs(
            selection,
            "author_citations",
            mapName,
            upsertLayer,
            5,
            fitToBounds
          );
        } else if (currentTab === 2) {
          selection = {
            map_name: mapName,
            type: "author_references",
            args: { author_id: selectedAuthorId },
            fields: ["title"],
          };
          setCurrentSelection(selection);
          await createSelectionLayerConfigs(
            selection,
            "author_references",
            mapName,
            upsertLayer,
            5,
            fitToBounds
          );
        }
        if (!isMounted.current) return;
      } catch (error: any) {
        if (isMounted.current) {
          const errorMessage =
            currentTab === 0
              ? "Error fetching author papers"
              : currentTab === 1
              ? "Error fetching author citations"
              : "Error fetching author references";
          console.error(error);
          snackbar.showSnackbar(errorMessage, "error");
          if (currentTab === 2) {
            setCurrentTab(0); // Reset to Author Papers tab on error for References tab
          }
        }
      } finally {
        if (isMounted.current) setIsLoadingPapers(false);
      }
    };
    fetchData();
  }, [currentTab, selectedAuthorId]);

  const getHoverType = (): HoveredPaperType => {
    if (currentTab === 0) {
      return "author_paper";
    } else if (currentTab === 1) {
      return "author_citation";
    } else if (currentTab === 2) {
      return "author_reference";
    } else {
      throw new Error("Invalid tab");
    }
  };

  // Memoize selection and contexts for AIQuestionButton
  const researchQuestionSelection = useMemo(
    () => ({
      type: "author_papers",
      args: { author_id: selectedAuthorId },
      map_name: mapName,
      fields: ["title", "year", "citationcount"],
      limit: 200,
    }),
    [mapName, selectedAuthorId]
  );

  const researchQuestionContexts = useMemo(
    () => ({
      method: "sampled_titles",
      sampling: { n: 200 },
      meta: ["citationcount", "year"],
      content: ["title"],
    }),
    []
  );

  const keywordPromptingConfig = useMemo(
    () => ({
      function_name: "extract_keywords",
      function_description:
        "Extract a list of relevant keywords from the provided papers (Maximum 10)",
      property_name: "keywords",
      function_calling_args: {
        keywords: {
          type: "array",
          items: {
            type: "string",
          },
          description: "Array of keywords",
        },
      },
      message_prompt_prefix:
        "Please extract the main keywords from the following content (Maximum 10):",
      required_keys: ["keywords"],
      system_prompt:
        "You are an AI assistant specialized in extracting keywords from academic papers.",
    }),
    []
  );

  const panelContent = (
    <>
      {selectedAuthorDetails ? (
        <>
          {/* Author Details Section */}
          <div style={{ flex: 1, overflowY: "auto" }}>
            {currentTab === 0 && !isMobile && (
              <ResponsiveImage src="/researcher.webp" alt="Author" />
            )}
            <div style={{ padding: "20px", paddingTop: 0 }}>
              {currentTab === 0 && (
                <>
                  {!isMobile && (
                    <>
                      <h3 style={{ textAlign: "left", marginBottom: "5px" }}>
                        {selectedAuthorDetails.name}{" "}
                        {isDebugMode && (
                          <Button
                            onClick={() => {
                              /* prompting selection logic */
                            }}
                          >
                            <CodeIcon />
                          </Button>
                        )}
                        {isDebugMode && (
                          <CopySelectionButton
                            selectionConfig={{
                              type: "author",
                              map_name: mapName,
                              author_id: selectedAuthorId,
                            }}
                          />
                        )}
                      </h3>

                      <Divider style={{ marginTop: "10px" }} />
                    </>
                  )}
                  {selectedAuthorDetails.homepage && (
                    <div>
                      <a
                        href={selectedAuthorDetails.homepage}
                        style={{ textAlign: "left", fontSize: "11px" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Homepage
                      </a>
                    </div>
                  )}
                  <div
                    className="meta"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <CitationCountDisplay
                      citationCount={selectedAuthorDetails.citationCount}
                    />
                  </div>
                  <Divider style={{ marginTop: "10px" }} />
                  <div>
                    <CollapsibleSegment
                      name="Author Description"
                      maxPreviewHeight={150}
                    >
                      <AIQuestionButton
                        autoGenerate={true}
                        question={"What is this author researching about?"}
                        prompt={
                          "Describe the main research question(s) for the provided papers from this author. Give a brief overview of what this author is researching about. Highlight highly cited papers (if any) and their recent research (if relevant). Use simple language that can be understood by a layperson. Use one simple introductory sentence which describes the overall research field followed by up to 5 bullet points with one short sentence each. If there are too few papers, use fewer bullet points. Refer to this author as 'this researcher' or similar."
                        }
                        contexts={researchQuestionContexts}
                        selection={researchQuestionSelection}
                        cachingKey={
                          selectedAuthorId
                            ? `${mapName}_author_${selectedAuthorId}_research_question`
                            : undefined
                        }
                      />
                    </CollapsibleSegment>
                    <CollapsibleSegment
                      name="Top Keywords"
                      maxPreviewHeight={400}
                    >
                      <AIQuestionFunctionCallingButton
                        autoGenerate={true}
                        question="What are the main keywords of this author's research?"
                        prompt="Provide a list of up to 10 keywords mentioned in the papers of this author, along with an indicator of their strength within the cluster."
                        contexts={researchQuestionContexts}
                        selection={researchQuestionSelection}
                        cachingKey={
                          selectedAuthorId
                            ? `${mapName}_author_${selectedAuthorId}_keywords_fc`
                            : undefined
                        }
                        promptingConfig={keywordPromptingConfig}
                        renderer={KeywordsChipsRenderer}
                        model="gpt-4o-mini"
                      />
                    </CollapsibleSegment>
                  </div>
                </>
              )}
              {/* Tabs */}
              <Tabs
                value={currentTab}
                onChange={(e, newValue) => setCurrentTab(newValue)}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                style={{ marginTop: "20px" }}
              >
                <Tab label={`Author Papers`} />
                <Tab label={`Citations`} />
                <Tab label={`References`} />
              </Tabs>
              <Divider />
              {/* Tab Content */}
              <div style={{ marginTop: "10px" }}>
                {currentSelection ? (
                  <PaperList
                    selection={currentSelection}
                    type={getHoverType()}
                    sortBy="year"
                    sortDirection="desc"
                    customSortingOptions={[
                      { field: "year", label: "Year" },
                      { field: "citationcount", label: "Citations" },
                    ]}
                  />
                ) : papers.length > 0 ? (
                  <PaperList
                    papers={papers}
                    type={getHoverType()}
                    sortBy="year"
                    sortDirection="desc"
                    customSortingOptions={[
                      { field: "year", label: "Year" },
                      { field: "citationcount", label: "Citations" },
                    ]}
                    clusterMetas={clusterMetasByClusterId}
                  />
                ) : (
                  <i>No Papers Found</i>
                )}
              </div>
              <Divider />
            </div>
          </div>
          {/* Close Button */}
          {!isMobile && (
            <div
              style={{
                padding: "10px",
                borderTop: "1px solid #eee",
                textAlign: "center",
              }}
          >
            <Button
              variant="contained"
              type="button"
              onClick={() => {
                setSelectedAuthorId(null);
                setSelectedAuthorDetails(null);
                removeAuthorLayers();
              }}
            >
              Close
              </Button>
            </div>
          )}
        </>
      ) : (
        // Loading Indicator when no author is selected
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={20} />
        </div>
      )}
    </>
  );

  // If on mobile and sidebar is open, use the MobileDrawer with swipe/touch handlers
  if (isMobile && sidebarOpen) {
    return (
      <MobileDrawer
        title={
          selectedAuthorDetails ? selectedAuthorDetails.name : "Author Details"
        }
        isOpen={true}
        onClose={() => {
          setSelectedAuthorId(null);
          setSelectedAuthorDetails(null);
          removeAuthorLayers();
        }}
        drawerHeight={drawerHeight}
      >
        {panelContent}
      </MobileDrawer>
    );
  }

  // Default desktop view
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        height: "100vh",
        maxHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        width: "400px",
        zIndex: 4,
        boxShadow: "1px 1px 4px #c5c5c5",
        backgroundColor: "white",
      }}
    >
      {panelContent}
    </div>
  );
}


import { Chip } from "@mui/material";

export default function KeywordsChipsRenderer(keywords: string[]) {
  if (!Array.isArray(keywords)) {
    return <div>Invalid data format for keywords.</div>;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "8px",
      }}
    >
      {keywords.map((keyword, index) => (
        <Chip
          size="small"
          key={index}
          label={keyword}
          style={{ fontSize: "12px", backgroundColor: "#ffffff", color: "#000", boxShadow: "0 1px 3px rgba(0,0,0,0.12)" }}
          color="primary"
        />
      ))}
    </div>
  );
};
import {
  Button,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  IconButton,
  Typography,
  Box,
  styled,
} from "@mui/material";
import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  TouchEvent,
} from "react";
import { useLocation } from "react-router-dom";
import { Global } from "@emotion/react";

import { getJournalDetails } from "../../requests";
import MapContext, { HoveredPaperType } from "../../contexts/MapContext";
import { useSnackbar } from "../../contexts/SnackbarContext";

import { createSelectionLayerConfigs } from "../../geometryCreationUtils";
import { IClusterMetaSmall } from "../../interfaces/clusterMeta";
import IPaperMeta from "../../interfaces/paperMeta";
import IPaperSelection from "../../interfaces/paperSelection";
import AIQuestionButton from "../ai/AIQuestionButton";
import AIQuestionFunctionCallingButton from "../ai/AIQuestionFunctionCallingButton";
import PaperList from "../lists/PaperList";
import KeywordsChipsRenderer from "../renderers/KeywordsChipRenderer";
import CopySelectionButton from "../utils/CopySelectionButton";
import CollapsibleSegment from "./CollapsibleSegment";
import ResponsiveImage from "../utils/ResponsiveImage";
import MobileDrawer from "./MobileDrawer";

// The drawer bleeding constant remains the same.
const drawerBleeding = 56;

export default function JournalDetailsPanel() {
  const {
    selectedJournalId,
    selectedJournalDetails,
    setSelectedJournalId,
    setSelectedJournalDetails,
    upsertLayer,
    mapName,
    removeJournalLayers,
    fitToBounds,
    isMobile,
  } = useContext(MapContext);

  const [papers, setPapers] = useState<IPaperMeta[]>([]);
  const [clusterMetasByClusterId, setClusterMetasByClusterId] =
    useState<null | Record<number, IClusterMetaSmall>>(null);
  const [currentSelection, setCurrentSelection] =
    useState<IPaperSelection | null>(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const isMounted = useRef(true);
  const [currentTab, setCurrentTab] = useState(0);
  const snackbar = useSnackbar();
  const location = useLocation();
  const isDebugMode = location.search.includes("debug");

  // Sidebar is open when a journal is selected.
  const sidebarOpen = !!selectedJournalId;

  // Mobile drawer height configuration
  const vh = window.innerHeight;
  const minHeight = vh * 0.15; // At least 15% of viewport height.
  const maxHeight = vh * 0.9; // Maximum height 90% of viewport.
  const defaultHeight = vh * 0.35;
  const [drawerHeight, setDrawerHeight] = useState<number>(defaultHeight);
  const [drawerExpanded, setDrawerExpanded] = useState(false);

  // Touch event tracking for swipe behavior.
  const [startY, setStartY] = useState<number | null>(null);
  const [startHeight, setStartHeight] = useState<number>(drawerHeight);

  const handleTouchStart = (e: TouchEvent) => {
    setStartY(e.touches[0].clientY);
    setStartHeight(drawerHeight);
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (startY === null) return;
    const deltaY = startY - e.touches[0].clientY;
    let newHeight = startHeight + deltaY;
    if (newHeight > maxHeight) newHeight = maxHeight;
    if (newHeight < minHeight) newHeight = minHeight;
    setDrawerHeight(newHeight);
  };

  const handleTouchEnd = () => {
    setStartY(null);
  };

  // Toggle callback for header tap
  const toggleDrawerHeight = () => {
    if (drawerHeight < maxHeight * 0.95) {
      setDrawerHeight(maxHeight);
    } else {
      setDrawerHeight(defaultHeight);
    }
    setDrawerExpanded((prev) => !prev);
  };

  // Cleanup effect for component unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    isMounted.current = true;
  }, [selectedJournalId]);

  // Fetch journal details when a journal is selected
  useEffect(() => {
    if (selectedJournalId) {
      setIsLoadingDetails(true);
      getJournalDetails(selectedJournalId)
        .then((journalDetails) => {
          if (isMounted.current) {
            setSelectedJournalDetails(journalDetails);
          }
          return journalDetails;
        })
        .catch((error) => {
          if (isMounted.current) {
            console.error(error);
            snackbar.showSnackbar("Error fetching journal details", "error");
            setSelectedJournalId(null);
          }
        })
        .finally(() => {
          if (isMounted.current) setIsLoadingDetails(false);
        });

      const selection: IPaperSelection = {
        map_name: mapName,
        limit: 100_000, //just for safety to not return too many papers or query for too long
        type: "publication_papers",
        args: { publication_id: selectedJournalId },
        fields: ["title", "year", "citationcount"],
      };
      setCurrentSelection(selection);
      (async () => {
        try {
          await createSelectionLayerConfigs(
            selection,
            "publication_papers",
            mapName,
            upsertLayer,
            5,
            fitToBounds
          );
        } catch (error) {
          if (isMounted.current) {
            console.error("Error adding journal papers geometry:", error);
          }
        }
      })();
    } else {
      setSelectedJournalDetails(null);
      removeJournalLayers();
    }
  }, [selectedJournalId, mapName]);

  const getHoverType = (): HoveredPaperType => {
    if (currentTab === 0) {
      return "journal_paper";
    } else {
      throw new Error("Invalid tab");
    }
  };

  // Memoized configuration for the AI questions.
  const researchQuestionSelection = useMemo(
    () => ({
      type: "publication_papers",
      map_name: mapName,
      args: { publication_id: selectedJournalId },
      fields: ["title", "year", "citationcount"],
      limit: 200,
    }),
    [mapName, selectedJournalId]
  );

  const researchQuestionContexts = useMemo(
    () => ({
      method: "sampled_titles",
      sampling: { n: 200 },
      meta: ["citationcount", "year"],
      content: ["title"],
    }),
    []
  );

  const keywordPromptingConfig = useMemo(
    () => ({
      function_name: "extract_keywords",
      function_description:
        "Extract a list of high level keywords from the provided papers of this journal in order to describe the overall research field (Maximum 10)",
      property_name: "keywords",
      function_calling_args: {
        keywords: {
          type: "array",
          items: { type: "string" },
          description: "Array of keywords",
        },
      },
      message_prompt_prefix:
        "Please extract the main keywords from the following content (Maximum 10):",
      required_keys: ["keywords"],
      system_prompt:
        "You are an AI assistant specialized in extracting keywords from academic papers.",
    }),
    []
  );

  // Main panel content (desktop and mobile common content)
  const panelContent = (
    <>
      {selectedJournalDetails ? (
        <>
          <div style={{ flex: 1, overflowY: "auto" }}>
            {currentTab === 0 && !isMobile && (
              <ResponsiveImage src="/cover3.webp" alt="Journal" />
            )}
            <div style={{ padding: "20px", paddingTop: 0 }}>
              {currentTab === 0 && (
                <>
                  {!isMobile && (
                    <>
                      <h3 style={{ textAlign: "left", marginBottom: "5px" }}>
                        {selectedJournalDetails.name}{" "}
                        {isDebugMode && (
                          <CopySelectionButton
                            selectionConfig={{
                              type: "author",
                              map_name: mapName,
                              publication_id: selectedJournalId,
                            }}
                          />
                        )}
                      </h3>
                      <Divider style={{ marginTop: "10px" }} />
                    </>
                  )}
                  <div>
                    <CollapsibleSegment
                      name="Journal Keywords"
                      maxPreviewHeight={250}
                    >
                      <AIQuestionFunctionCallingButton
                        autoGenerate={true}
                        question="What are the main keywords of this journal's research?"
                        prompt="Provide a list of up to 10 keywords mentioned in the papers of this journal, along with an indicator of their strength within the cluster."
                        contexts={researchQuestionContexts}
                        selection={researchQuestionSelection}
                        cachingKey={
                          selectedJournalId
                            ? `${mapName}_publication_${selectedJournalId}_keywords_fc`
                            : undefined
                        }
                        promptingConfig={keywordPromptingConfig}
                        renderer={KeywordsChipsRenderer}
                        model="gpt-4o-mini"
                      />
                    </CollapsibleSegment>
                    <CollapsibleSegment
                      name="Journal Description"
                      maxPreviewHeight={250}
                    >
                      <AIQuestionButton
                        autoGenerate={true}
                        question={
                          "What are research questions of this journal?"
                        }
                        prompt={
                          "Describe the main research question(s) for the provided papers from this journal. Give a brief overview of what this journal is researching about. Use simple language that can be understood by a layperson. Use one simple introductory sentence which describes the overall research field followed by up to 5 bullet points with one short sentence each. If there are too few papers, use fewer bullet points. Refer to this journal as 'this journal' or similar."
                        }
                        contexts={researchQuestionContexts}
                        selection={researchQuestionSelection}
                        cachingKey={
                          selectedJournalId
                            ? `${mapName}_publication_${selectedJournalId}_research_question`
                            : undefined
                        }
                      />
                    </CollapsibleSegment>
                  </div>
                </>
              )}
              <Tabs
                value={currentTab}
                onChange={(e, newValue) => setCurrentTab(newValue)}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                style={{ marginTop: "20px" }}
              >
                <Tab label={`Journal Papers`} />
                {false && <Tab label={`Contributing Authors`} />}
              </Tabs>
              <Divider />
              <div style={{ marginTop: "10px" }}>
                {currentSelection ? (
                  <PaperList
                    selection={currentSelection}
                    type={getHoverType()}
                    sortBy="year"
                    sortDirection="desc"
                    customSortingOptions={[
                      { field: "year", label: "Year" },
                      { field: "citationcount", label: "Citations" },
                    ]}
                  />
                ) : papers.length > 0 ? (
                  <PaperList
                    papers={papers}
                    type={getHoverType()}
                    sortBy="year"
                    sortDirection="desc"
                    customSortingOptions={[
                      { field: "year", label: "Year" },
                      { field: "citationcount", label: "Citations" },
                    ]}
                    clusterMetas={clusterMetasByClusterId}
                  />
                ) : (
                  <i>No Papers Found</i>
                )}
              </div>
              <Divider />
            </div>
          </div>
          {!isMobile && (
            <div
              style={{
                padding: "10px",
                borderTop: "1px solid #eee",
                textAlign: "center",
              }}
          >
            <Button
              variant="contained"
              type="button"
              onClick={() => {
                setSelectedJournalId(null);
                setSelectedJournalDetails(null);
              }}
            >
              Close
              </Button>
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={20} />
        </div>
      )}
    </>
  );

  // Render MobileDrawer if on mobile and sidebar is open.
  if (isMobile && sidebarOpen) {
    return (
      <MobileDrawer
        title={
          selectedJournalDetails
            ? selectedJournalDetails.name
            : "Journal Details"
        }
        isOpen={true}
        onClose={() => {
          setSelectedJournalId(null);
          setSelectedJournalDetails(null);
        }}
        drawerHeight={drawerHeight}
      >
        {panelContent}
      </MobileDrawer>
    );
  }

  // Default desktop view.
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        height: "100vh",
        maxHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        width: "400px",
        zIndex: 4,
        boxShadow: "1px 1px 4px #c5c5c5",
        backgroundColor: "white",
      }}
    >
      {panelContent}
    </div>
  );
}

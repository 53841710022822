import ClearIcon from "@mui/icons-material/Clear";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SearchIcon from "@mui/icons-material/Search";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Popper,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import { useContext, useEffect, useRef, useState } from "react";
import MapContext from "../contexts/MapContext";
import { fetchAutocompleteSuggestions, getRandomAuthorId, getRandomJournalId, getRandomPaperId } from "../requests";

const randomKeywords = [
  "LLM Agents",
  "ChatGPT",
  "CRISPR",
  "Nuclear Fusion",
  "Quantum Computing",
  "Deep Learning",
  "Genome Editing",
  "Blockchain Technology",
  "Neural Networks",
  "Cybersecurity",
  "Climate Change",
  "Machine Learning",
  "Big Data Analytics",
  "Data Mining",
  "Augmented Reality",
  "Virtual Reality",
  "IoT Systems",
  "Biotechnology",
  "Renewable Energy",
  "Artificial Intelligence",
  "Self-Driving Cars",
  "Robotics",
  "Edge Computing",
  "5G Networks",
  "Smart Cities",
  "Natural Language Processing",
  "Computer Vision",
  "Drug Discovery",
  "Bioinformatics",
  "Synthetic Biology",
  "Gene Therapy",
  "Robotic Process Automation",
  "Predictive Analytics",
  "Data Science",
  "Cognitive Computing",
  "Wearable Technology",
  "Smart Grids",
  "E-commerce Systems",
  "Digital Signal Processing",
  "Optical Networks",
  "Cloud Computing",
  "Software Engineering",
  "Wireless Sensor Networks",
  "Internet Privacy",
  "Virtual Machines",
  "Human-Computer Interaction",
  "Nanotechnology",
  "Energy Storage",
  "Sustainable Development"
];

const Container = styled("div")<{ expanded: boolean }>(({ expanded, theme }) => ({
  position: "absolute",
  top: "15px",
  left: "15px",
  width: "350px",
  zIndex: 3,
  transition: "all 0.3s ease",
  backgroundColor: expanded ? "white" : "transparent",
  borderRadius: expanded ? "24px 24px 0px 0px" : "24px",
  boxShadow: expanded ? "0 2px 4px rgba(0,0,0,0.1)" : "none",
  padding: expanded ? theme.spacing(1) : 0,
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-paper": {
    backgroundColor: "white",
    borderRadius: "0px 0px 24px 24px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    border: "1px solid lightgrey",
  },
}));

const SearchInputContainer = styled("div")<{ expanded: boolean }>(({ expanded }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
  borderRadius: expanded ? "24px" : "24px",
  padding: "0px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
}));

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const StyledClearIcon = styled(ClearIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  cursor: "pointer",
  fontSize: "1.5rem",
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  width: "100%",
  "& .MuiToggleButton-root": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
    fontSize: "0.75rem",
  },
}));

const DescriptionContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "4px",
});

export const SearchButton = ({
  initialValue,
  loading,
  onSubmit,
  onClear,
  disabled,
}: {
  initialValue?: string;
  loading: boolean;
  onSubmit: (value: string) => void;
  onClear: () => void;
  disabled?: boolean;
}) => {
  const [tabInputs, setTabInputs] = useState<{ [key in "keywords" | "authors" | "journals"]: string }>({
    keywords: initialValue || "",
    authors: "",
    journals: "",
  });

  const [value, setValue] = useState<any>(null);
  const [options, setOptions] = useState<any[]>([]);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [lastSubmittedValue, setLastSubmittedValue] = useState("");
  const [searchType, setSearchType] = useState<"keywords" | "authors" | "journals">("keywords");
  const [isFocused, setIsFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [randomLoading, setRandomLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { mapName, setSelectedAuthorId, setSelectedCorpusId, searchQuery, setSelectedJournalId, selectedAuthorId, selectedCorpusId, selectedJournalId, isMapInteraction } = useContext(MapContext);
  const inputValue = tabInputs[searchType];

  const descriptionMap: Record<string, string> = {
    keywords: "Find papers by Keywords",
    authors: "Find papers for specific authors",
    journals: "Find papers for specific journals",
  };
  const description = descriptionMap[searchType];

  // Expanded if input (or children) focused, dropdown open, or when options/noResults message is shown.
  const expanded = isFocused || open || (options.length > 0 && !loading) || noResultsFound;

  useEffect(() => {
    if (searchQuery && searchQuery.length > 0) {
      setTabInputs((prev) => ({ ...prev, [searchType]: searchQuery }));
    }
  }, [searchQuery, searchType]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsFocused(false);
        if (options.length === 0) {
          setOpen(false);
          setNoResultsFound(false);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [options.length]);

  useEffect(() => {
    if (inputValue.length === 0) {
      setOptions([]);
      setNoResultsFound(false);
    }
  }, [inputValue]);

  useEffect(() => {
    if (options.length > 0 && !loading) {
      setOpen(true);
    }
  }, [options, loading]);

  useEffect(() => {
    if (loading) {
      setIsFocused(false);
      setOpen(false);
    }
  }, [loading]);

  useEffect(() => {
    if (selectedAuthorId || selectedCorpusId || selectedJournalId || !isMapInteraction) {
      setIsFocused(false);
      setOpen(false);
      setOptions([]);
      setNoResultsFound(false);
    }
  }, [selectedAuthorId, selectedCorpusId, selectedJournalId,isMapInteraction]);

  const handleInputChange = (newInputValue: string) => {
    setTabInputs((prev) => ({ ...prev, [searchType]: newInputValue }));
    if (options.length === 0 && !noResultsFound) {
      setOpen(false);
    }
  };

  const handleSubmit = async () => {
    setSuggestionsLoading(false);
    setLastSubmittedValue(inputValue);
    setNoResultsFound(false);

    // Blur the input to block further typing
    inputRef.current?.blur();

    if (searchType === "keywords") {
      onSubmit(inputValue);
      setOptions([]);
      setOpen(false);
      setIsFocused(false);
    } else if (searchType === "authors" || searchType === "journals") {
      setSuggestionsLoading(true);
      try {
        const data = await fetchAutocompleteSuggestions(mapName, inputValue, searchType, 100);
        let newOptions: any[] = [];
        if (searchType === "authors") {
          newOptions = (data.authors || []).map((author: any) => ({
            ...author,
            type: "Author",
          }));
        } else if (searchType === "journals") {
          newOptions = (data.journals || []).map((journal: any) => ({
            ...journal,
            type: "Journal",
          }));
        }
        setOptions(newOptions);
        if (newOptions.length === 0) {
          setNoResultsFound(true);
          setOpen(true);
        } else {
          setNoResultsFound(false);
          setOpen(true);
        }
      } catch (error) {
        console.error(error);
        setNoResultsFound(true);
      } finally {
        setSuggestionsLoading(false);
      }
    }
  };

  const handleReset = () => {
    setTabInputs((prev) => ({ ...prev, [searchType]: "" }));
    setValue(null);
    setLastSubmittedValue("");
    setOptions([]);
    setNoResultsFound(false);
    onClear();
  };

  const handleOptionSelect = (option: any) => {
    setTabInputs((prev) => ({ ...prev, [searchType]: option.name || option.title || "" }));
    setValue(option);
    setOptions([]);
    setNoResultsFound(false);
    if (searchType === "authors" && option.authorid) {
      setSelectedAuthorId(option.authorid);
      setIsFocused(false);
    } else if (searchType === "journals" && option.id) {
      setSelectedJournalId(option.id);
      setIsFocused(false);
    }
    inputRef.current?.blur();
  };

  const handleRandomSelection = async () => {
    setTabInputs((prev) => ({ ...prev }));
    setValue(null);
    setOptions([]);
    setNoResultsFound(false);
    setRandomLoading(true);
    setIsFocused(false);
    
    try {
      if (searchType === "authors") {
        const authorId = await getRandomAuthorId(mapName);
        setSelectedAuthorId(authorId);
      } else if (searchType === "journals") {
        const journalId = await getRandomJournalId(mapName);
        setSelectedJournalId(journalId);
      } else if (searchType === "keywords") {
        const randomIndex = Math.floor(Math.random() * randomKeywords.length);
        const randomKeyword = randomKeywords[randomIndex];
        onSubmit(randomKeyword);
      }
    } catch (error) {
      console.error("Error fetching random selection:", error);
      if (searchType === "authors") {
        setSelectedAuthorId("random");
      } else if (searchType === "journals") {
        setSelectedJournalId("random");
      } else if (searchType === "keywords") {
        onSubmit("random");
      }
    } finally {
      setRandomLoading(false);
    }
  };

  return (
    <Container
      ref={containerRef}
      expanded={expanded}
      style={{
        padding: 0,
        marginBottom: 0,
        borderRadius: options.length > 0 || loading ? "25px 25px 0px 0px" : "25px",
      }}
    >
      {expanded && (
        <>
          <StyledToggleButtonGroup
            style={{ borderRadius: "24px 24px 0px 0px" }}
            value={searchType}
            exclusive
            onChange={(event, newSearchType) => {
              if (newSearchType !== null) {
                setSearchType(newSearchType);
                setOptions([]);
                setNoResultsFound(false);
              }
            }}
            aria-label="search type"
          >
            <ToggleButton value="keywords" aria-label="keywords" style={{ borderRadius: "24px 0px 0px 0px" }}>
              <LocalOfferIcon fontSize="small" />
              Keywords
            </ToggleButton>
            <ToggleButton value="authors" aria-label="authors" style={{ borderRadius: "0px 0px 0px 0px" }}>
              <PersonOutlineIcon fontSize="small" />
              Authors
            </ToggleButton>
            <ToggleButton value="journals" aria-label="journals" style={{ borderRadius: "0px 24px 0px 0px" }}>
              <LibraryBooksIcon fontSize="small" />
              Journals
            </ToggleButton>
          </StyledToggleButtonGroup>
          <DescriptionContainer style={{ justifyContent: "center", gap: "10px" }}>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ textAlign: "center", fontStyle: "italic" }}
            >
              {description}
            </Typography>
            <Tooltip title="Try a random selection">
              <IconButton size="small" onClick={handleRandomSelection} disabled={randomLoading}>
                {randomLoading ? <CircularProgress size={16} /> : <ShuffleIcon fontSize="small" />}
              </IconButton>
            </Tooltip>
          </DescriptionContainer>
        </>
      )}
      <SearchInputContainer expanded={expanded}>
        <Autocomplete
          freeSolo
          disableClearable
          options={options}
          getOptionLabel={(option: any) => option.name || option.title || ""}
          inputValue={inputValue}
          value={value}
          onFocus={() => setIsFocused(true)}
          onKeyDown={(event) => {
            if (event.key === "Enter" && inputValue !== lastSubmittedValue) {
              handleSubmit();
            }
          }}
          onInputChange={(event, newInputValue) => {
            handleInputChange(newInputValue);
          }}
          onChange={(event, newValue: any) => {
            if (typeof newValue === "string") {
              handleInputChange(newValue);
            } else if (newValue && (newValue.name || newValue.title)) {
              handleOptionSelect(newValue);
            }
          }}
          loading={suggestionsLoading}
          PopperComponent={(props) => <StyledPopper {...props} placement="bottom-start" />}
          PaperComponent={(props) => {
            if (suggestionsLoading && (searchType === "authors" || searchType === "journals")) {
              return (
                <Paper {...props}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ padding: "10px", textAlign: "center" }}
                  >
                    Loading for {searchType.charAt(0).toUpperCase() + searchType.slice(1)}
                  </Typography>
                </Paper>
              );
            }
            if (noResultsFound) {
              return (
                <Paper {...props}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ padding: "10px", textAlign: "center" }}
                  >
                    No matches found for "{inputValue}"
                  </Typography>
                </Paper>
              );
            }
            return options.length > 0 ? <Paper {...props} /> : <div></div>;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={`Search ${searchType.charAt(0).toUpperCase() + searchType.slice(1)}...`}
              inputRef={inputRef}
              disabled={loading || suggestionsLoading}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                endAdornment: (
                  <>
                    {suggestionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {inputValue.length > 0 && (
                      <IconButton onClick={handleReset} size="small">
                        <StyledClearIcon />
                      </IconButton>
                    )}
                    <Button
                      onClick={handleSubmit}
                      disabled={inputValue.length === 0 || disabled || loading}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      disableElevation
                      style={{ outline: "none" }}
                    >
                      {loading ? <CircularProgress size={24} /> : <StyledSearchIcon />}
                    </Button>
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              sx={{
                flexGrow: 1,
                "& .MuiOutlinedInput-root": {
                  width: "350px",
                  "& fieldset": { border: "none" },
                  "&:hover fieldset": { border: "none" },
                  "&.Mui-focused fieldset": { border: "none" },
                },
              }}
            />
          )}
          renderOption={(props, option) => {
            let icon;
            let label = option.name || option.title || "";
            
            switch (option.type) {
              case "Author":
                icon = <PersonOutlineIcon style={{ color: "#70757a", fontSize: "18px" }} />;
                return (
                  <li
                    {...props}
                    key={option.authorid}
                    onMouseDown={(event) => {
                      event.preventDefault();
                      handleOptionSelect(option);
                    }}
                    style={{ display: "flex", flexDirection: "column", padding: "8px 16px" }}
                  >
                    <div style={{ display: "flex", alignItems: "center", gap: "10px", width: "100%" }}>
                      {icon}
                      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                            {option.name}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            h-index: {option.hindex || "N/A"} • {option.papercount || 0} papers
                          </Typography>
                        </div>
                        {option.title && (
                          <Typography variant="caption" color="textSecondary" style={{ marginTop: "4px" }}>
                            Most cited: {option.title} ({option.year || "N/A"})
                          </Typography>
                        )}
                      </div>
                    </div>
                  </li>
                );
              case "Journal":
                icon = <LibraryBooksIcon style={{ color: "#70757a", fontSize: "18px" }} />;
                return (
                  <li
                    {...props}
                    key={option.journal_id || option.id}
                    onMouseDown={(event) => {
                      event.preventDefault();
                      handleOptionSelect(option);
                    }}
                    style={{ display: "flex", flexDirection: "column", padding: "8px 16px" }}
                  >
                    <div style={{ display: "flex", alignItems: "center", gap: "10px", width: "100%" }}>
                      {icon}
                      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          <Typography
                            variant="subtitle2"
                            style={{
                              fontWeight: "bold",
                              maxWidth: "70%",
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }}
                          >
                            {option.name}
                          </Typography>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{
                              flexShrink: 0,
                              textAlign: "right"
                            }}
                          >
                            {option.paper_count || 0} papers
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              case "Keyword":
                icon = <LocalOfferIcon style={{ color: "#70757a", fontSize: "18px" }} />;
                break;
              default:
                icon = <StyledSearchIcon />;
            }
            
            return (
              <li
                {...props}
                key={option.id || option.authorid}
                onMouseDown={(event) => {
                  event.preventDefault();
                  handleOptionSelect(option);
                }}
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                {icon}
                <span>{label}</span>
              </li>
            );
          }}
          open={open || options.length > 0 || noResultsFound}
          onOpen={() => setOpen(true)}
          onClose={() => {
            if (options.length === 0 && !noResultsFound) {
              setOpen(false);
            }
          }}
          disableCloseOnSelect={false}
          ListboxProps={{
            style: {
              maxHeight: '400px',
              overflowY: 'auto'
            }
          }}
          filterOptions={(options, state) => options}
          limitTags={-1}
        />
      </SearchInputContainer>
    </Container>
  );
};

export default SearchButton;
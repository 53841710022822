import React from "react";
import { Popup } from "react-map-gl/maplibre";
import ClusterStickyHoverPopup from "./popup/ClusterStickyHoverPopup";
import PaperStickyHoverPopup from "./popup/PaperStickyHoverPopup";

interface StickyMapPopupsProps {
  stickyHoveredPaper: any;
  stickyHoveredCluster: any;
}

const StickyMapPopups: React.FC<StickyMapPopupsProps> = ({
  stickyHoveredPaper,
  stickyHoveredCluster,
}) => {
  return (
    <>
      {stickyHoveredPaper?.geometry && (
        <Popup
          key={`sticky-paper-${stickyHoveredPaper.id}`} // unique key forces remount
          longitude={stickyHoveredPaper.geometry.coordinates[0]}
          latitude={stickyHoveredPaper.geometry.coordinates[1]}
          closeButton={false}
          offset={[0, -20] as [number, number]}
          className="sticky-paper-popup"
        >
          <PaperStickyHoverPopup hoveredPaper={stickyHoveredPaper} />
        </Popup>
      )}
      {stickyHoveredCluster?.geometry && (
        <Popup
          key={`sticky-cluster-${stickyHoveredCluster.cluster_id}`} // unique key forces remount
          longitude={stickyHoveredCluster.geometry.coordinates[0]}
          latitude={stickyHoveredCluster.geometry.coordinates[1]}
          closeButton={false}
          offset={[0, -20] as [number, number]}
          className="sticky-cluster-popup"
        >
          <ClusterStickyHoverPopup hoveredCluster={stickyHoveredCluster} />
        </Popup>
      )}
    </>
  );
};

export default StickyMapPopups;
import { LayerProps } from "react-map-gl";
import _ from 'lodash';
import { AddLayerObject, Map } from "maplibre-gl";
import ILandmarkLayer from "./interfaces/landmarkLayer";
import { getZoomSteps } from "./layerUtils";
import { HOVER_COLOR, SELECTION_COLOR } from "./colors";
const THRESHOLD_HOVER_MS = 10;
const DEFAULT_SOURCE = "default";

const landmarkLookup:Record<string,string>={
  "hovered_author_paper":"landmark_yellow",
  "hovered_journal_paper":"landmark_orange",
  "hovered_author_citation":"landmark_blue",
  "hovered_author_reference":"landmark_green",
  "search":"landmark_red",
  "highlighted_paper":"landmark_lila",
  "selected_paper":"landmark_red",
  "hovered_paper_citation":"landmark_blue",
  "hovered_paper_reference":"landmark_green",
  "hovered_cluster":"landmark_grey"
}

export function createLandmarkLayerConfig(layer: any): ILandmarkLayer {
  const landmarkLayer = layer as ILandmarkLayer;
  landmarkLayer.iconSize = 0.04;
  landmarkLayer.text_property=null;

  switch (layer.tag) {
    case "search":
      landmarkLayer.iconSize = 0.02;
      landmarkLayer.min_zoom_label = 7; //when to start showing the label
      landmarkLayer.text_property="title";
      landmarkLayer.icon = "landmark_red";
      break;
    case "hover":
      landmarkLayer.icon = _.get(landmarkLookup,layer.layer_name,"landmark_red");
      landmarkLayer.iconSize = 0.08;
      landmarkLayer.maxLength = 1
      break;
    case "selected_paper":
        landmarkLayer.icon = _.get(landmarkLookup,layer.layer_name,"landmark_red");
        landmarkLayer.iconSize = 0.08;
        landmarkLayer.maxLength = 1;
        break;
    case "paper_landmarks":
      landmarkLayer.text_property="title";
      landmarkLayer.icon = "landmark_grey";
      landmarkLayer.padding = 50;
      landmarkLayer.maxLength = 100;
      break;
    default:
      landmarkLayer.icon = "landmark_lila";
      break;
  }

  return landmarkLayer;
}

export function createLandmarkLayerProps(
  landmarkLayer: ILandmarkLayer,
  ignoreText: boolean = false,
  isGeojsonSource: boolean=false
): LayerProps {
  const layout: any = {
    "icon-allow-overlap": true,
    "icon-size": landmarkLayer.iconSize,
    "text-allow-overlap": false,
    "icon-image": landmarkLayer.icon,
    "icon-ignore-placement": true,
    "icon-anchor": "bottom",
    //"symbol-z-order": "auto",
    //"symbol-sort-key": 0,
    "text-optional":true
  };

  if (landmarkLayer.text_property && !ignoreText) {
    layout["text-field"] = [
      "case",
      [">", ["length", ["get", "title"]], landmarkLayer.maxLength || 100],
      [
        "concat",
        ["slice", ["get", "title"], 0, landmarkLayer.maxLength || 100],
        "...",
      ],
      ["get", landmarkLayer.text_property],
    ];

    layout["text-font"] = ["Arial Unicode MS Bold"];
    layout["text-size"] = landmarkLayer.font_size || 10; // Set normal text size above the min_zoom_label
    

    layout["text-padding"] = landmarkLayer.padding || landmarkLayer.label_size || 2;
    layout["text-anchor"] = "left";
    layout["text-offset"] = [1.2, 0];
    layout["text-justify"] = "left";
  }

  
  const paint: any = {
    "text-color": [
      "case",
      ["boolean", ["feature-state", "select"], false],
      SELECTION_COLOR, // Used if the feature is selected
      ["boolean", ["feature-state", "hover"], false],
      HOVER_COLOR, // Used if the feature is hovered but not selected
      "rgba(0,0,0,0.7)", // Default color used if the feature is neither selected nor hovered
    ],
    "text-halo-color": "rgba(255,255,255,0.5)",
    "text-halo-width": 1,
    "text-halo-blur": 1,
  };

  const layerProps: LayerProps = {
    id: landmarkLayer.layer_name,
    type: "symbol",
    layout: layout,
    paint: paint,
    source: landmarkLayer.source || DEFAULT_SOURCE,
    
    minzoom: landmarkLayer.min_zoom,
    maxzoom: landmarkLayer.max_zoom,
  };

  if(!isGeojsonSource){
    layerProps['source-layer']= landmarkLayer.layer_name;
  }

  return layerProps;
}
import { useEffect, useState } from "react";
import { listMaps } from "../requests";
import MapMeta from "../interfaces/mapMeta";
import { Link } from "react-router-dom";

export default function MapList() {
    const [maps, setMaps] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        listMaps().then(setMaps);
    }, []);

    const filteredMaps = maps.filter(map => 
        map.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div style={{
            padding: '2rem',
            maxWidth: '800px',
            margin: '0 auto',
            height: '100vh',
            overflowY: 'auto'
        }}>
            <h1 style={{
                textAlign: 'left',
                marginBottom: '1rem'
            }}>
                Available Maps
            </h1>
            <input
                type="text"
                placeholder="Search maps..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                    padding: '0.5rem',
                    marginBottom: '1rem',
                    width: '100%',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    fontSize: '1rem'
                }}
            />
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                width: '100%'
            }}>
                {filteredMaps.map((map) => (
                    <div key={map} style={{
                        padding: '0.5rem',
                        width: '100%',
                        textAlign: 'left'
                    }}>
                        <Link to={`/map/${map}`} style={{
                            textDecoration: 'none',
                            color: '#0066cc',
                            fontSize: '1.1rem'
                        }}>
                            {map}
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}
import React, { useState, useRef, useEffect } from 'react';

interface ResponsiveImageProps {
  src: string;
  alt: string;
  maxImageHeight?: number;
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({ src, alt, maxImageHeight = 500 }) => {
  const [imgWidth, setImgWidth] = useState<number | null>(null);
  const [bgColor, setBgColor] = useState<string>('transparent');
  const imgRef = useRef<HTMLImageElement>(null);

  // Computes the average color by drawing the image into a 1x1 canvas.
  const computeAverageColor = (img: HTMLImageElement): string => {
    const canvas = document.createElement('canvas');
    const scale = 0.1; // Scale down for performance
    const width = Math.max(1, Math.floor(img.naturalWidth * scale));
    const height = Math.max(1, Math.floor(img.naturalHeight * scale));
    
    canvas.width = width;
    canvas.height = height;
    
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(img, 0, 0, width, height);
      const imageData = ctx.getImageData(0, 0, width, height);
      const data = imageData.data;
      
      // Extract all RGB values
      const colors: number[][] = [];
      for (let i = 0; i < data.length; i += 4) {
        colors.push([data[i], data[i + 1], data[i + 2]]);
      }
      
      // Sort each color channel and get median
      const medianR = colors.map(c => c[0]).sort((a, b) => a - b)[Math.floor(colors.length / 2)];
      const medianG = colors.map(c => c[1]).sort((a, b) => a - b)[Math.floor(colors.length / 2)];
      const medianB = colors.map(c => c[2]).sort((a, b) => a - b)[Math.floor(colors.length / 2)];
      
      return `rgba(${medianR}, ${medianG}, ${medianB}, 0.1)`;
    }
    return 'transparent';
  };

  // Handle the image load event to get the natural width and compute average color if needed.
  const handleImageLoad = () => {
    if (imgRef.current) {
      const width = imgRef.current.naturalWidth;
      setImgWidth(width);
      if (width > 400) {
        const avgColor = computeAverageColor(imgRef.current);
        setBgColor(avgColor);
      }
    }
  };

  // Container style is always 100% width, but with background color if needed
  const containerStyle: React.CSSProperties = {
    width: '100%',
    backgroundColor: imgWidth && imgWidth > 400 ? bgColor : 'transparent',
    display: 'flex',
    justifyContent: 'center'
  };

  // Image style with max-width constraint and max-height constraint
  const imageStyle: React.CSSProperties = {
    maxWidth: `${maxImageHeight}px`,
    maxHeight: `${maxImageHeight}px`,
    width: '100%',
    display: 'block'
  };

  return (
    <div style={containerStyle}>
      <img
        ref={imgRef}
        src={src}
        crossOrigin="anonymous"
        alt={alt}
        onLoad={handleImageLoad}
        style={{
          ...imageStyle,
          objectFit: 'cover',
          height: '100%'
        }}
      />
    </div>
  );
};

export default ResponsiveImage;
// src/components/MapHandler.tsx
import "./MapHandler.scss";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import FeedbackIcon from "@mui/icons-material/Feedback";
import LayersIcon from "@mui/icons-material/Layers";
import * as device from "react-device-detect";
import LightModeIcon from "@mui/icons-material/LightMode";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { Global } from "@emotion/react";
import _ from "lodash";
import maplibregl from "maplibre-gl";
import * as pmtiles from "pmtiles";
import { useContext, useEffect, useState, TouchEvent, useRef } from "react";
import { MapProvider } from "react-map-gl/maplibre";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MapContext, { ISourceConfig } from "../contexts/MapContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useSearch } from "../hooks/useSearch";
import { ILayer, IMapConfig } from "../interfaces/mapConfig";
import IViewport from "../interfaces/viewport";
import { createGeometry, fetchMapConfig } from "../requests";
import ColorLegend from "./ColorLegend";
import FeedbackDialog from "./FeedbackDialog";
import MapReactMap from "./MapReactMap";
import { SearchButton } from "./SearchButton";
import SearchResultsPanel from "./SearchResultsPanel";
import SideBar from "./SideBar";
import SidebarToggleButton from "./SidebarToggleButton";
import AuthorDetailsPanel from "./panels/AuthorDetailsPanel";
import ClusterDetailsPanel from "./panels/ClusterDetailsPanel";
import JournalDetailsPanel from "./panels/JournalDetailsPanel";
import LayerControl from "./panels/LayerControl";
import LayerCreationPanel from "./panels/LayerCreationPanel";
import PaperDetailsPanel from "./panels/PaperDetailsPanel";
import PromptingPanel from "./panels/PromptingPanel";
import LoadingScreen from "./utils/LoadingScreen";
import MobileDrawer from "./panels/MobileDrawer";

const drawerBleeding = 56;
const SHOW_FEEDBACK_BUTTON = false;

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: "0px -2px 8px rgba(0,0,0,0.1)",
}));

const Puller = styled("div")(() => ({
  width: 30,
  height: 6,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function MapHandler() {
  const {
    name,
    coordinates,
    query,
    authorId,
    corpusId,
    clusterId, // from URL (string)
    journalId,
  } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [mapConfig, setMapConfig] = useState<IMapConfig | null>(null);
  const [showLayerControls, setShowLayerControls] = useState(false);
  const [showCreateLayerPanel, setShowCreateLayerPanel] = useState(false);
  const isDebugMode = location.search.includes("debug");
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [drawerExpanded, setDrawerExpanded] = useState(false);
  const {
    darkmode,
    selectedAuthorId,
    selectedJournalId,
    setDarkmode,
    upsertLayer,
    mapName,
    setMapName,
    addLayer,
    removeLayer,
    searchQuery,
    setSearchQuery,
    setSearchClusterResults,
    setViewport,
    removeLayersByPrefix,
    setTargetCenter,
    setSelectedJournalId,
    viewport,
    setSourceJSON,
    sourceJSON,
    zoom,
    setZoom,
    selectedCorpusId,
    selectedCluster,
    setSelectedCluster,
    searchPaperResults,
    sidebarOpen,
    setSearchPaperResults,
    setSelectedCorpusId,
    setSelectedAuthorId,
    setSidebarOpen,
    promptingSelection,
    setPromptingSelection,
    zoomIn,
    flyToAnimated,
    zoomOut,
    isMobile,
    setIsMobile,
  } = useContext(MapContext);


  const snackbar = useSnackbar();
  const { loading, search } = useSearch();
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  // New state for search trigger
  const [searchTrigger, setSearchTrigger] = useState(0);

  // --- Mobile Drawer State for Search Results ---
  const vh = window.innerHeight;
  const minHeight = vh * 0.15;
  const maxHeight = vh * 0.9;
  const defaultHeight = vh * 0.35;
  const [drawerHeight, setDrawerHeight] = useState<number>(defaultHeight);
  const [startY, setStartY] = useState<number | null>(null);
  const [startHeight, setStartHeight] = useState<number>(defaultHeight);

  const handleTouchStart = (e: TouchEvent) => {
    setStartY(e.touches[0].clientY);
    setStartHeight(drawerHeight);
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (startY === null) return;
    const deltaY = startY - e.touches[0].clientY;
    let newHeight = startHeight + deltaY;
    if (newHeight > maxHeight) newHeight = maxHeight;
    if (newHeight < minHeight) newHeight = minHeight;
    setDrawerHeight(newHeight);
  };

  const handleTouchEnd = () => {
    setStartY(null);
  };

  const toggleDrawerHeight = () => {
    if (drawerHeight < maxHeight * 0.95) {
      setDrawerHeight(maxHeight);
    } else {
      setDrawerHeight(defaultHeight);
    }
    setDrawerExpanded((prev) => !prev);
  };

  // --- End Mobile Drawer State ---

  // Detect mobile device on initial load and on resize.
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(device.isMobile || device.isTablet || window.innerWidth < 600);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, [setIsMobile, device.isMobile,device.isTablet]);

  // Process URL parameters.
  useEffect(() => {
    if (name) {
      setMapName(name);
    }
    if (query) {
      setSearchQuery(query);
      if(!corpusId){
        setSidebarOpen(true);
      }
    }else{
      setSearchQuery(null);
      handleClearSearch();
      setSidebarOpen(false);
    }
    if (authorId) {
      setSelectedAuthorId(authorId);
    }
    if (corpusId) {
      setSelectedCorpusId(corpusId);
      setSidebarOpen(false);
    }else{
      setSelectedCorpusId(null);
    }
    if (journalId) {
      setSelectedJournalId(journalId);
    }else{
      setSelectedJournalId(null);
    }
    if (clusterId) {
      setSelectedCluster({ cluster_id: parseInt(clusterId) });
    }else{
      setSelectedCluster(null);
    }
   
   /* if (coordinates) {
      const [latStr, lngStr, zoomStr] = coordinates.split(",");
      const parsedLat = parseFloat(latStr);
      const parsedLng = parseFloat(lngStr);
      const parsedZoom = parseFloat(zoomStr.replace("z", ""));
      setTargetCenter({ lat: parsedLat, lng: parsedLng, zoom: parsedZoom });
      if (clusterId) {
        setSelectedCluster({ cluster_id: parseInt(clusterId) });
      }
    }*/
    setInitialLoadComplete(true);
  }, [name, query, authorId, corpusId, coordinates, clusterId, journalId]);

  useEffect(() => {
    if (mapName) {
      fetchMapConfig(mapName)
        .then((mapConfig: IMapConfig) => {
          const sortedLayers = mapConfig.source_json.layers;
          const _sourceJson = { ...mapConfig.source_json, layers: sortedLayers };
          setMapConfig({ ...mapConfig, source_json: _sourceJson });
          setSourceJSON(_.cloneDeep(_sourceJson));
        })
        .catch(() => {
          alert("Map not found");
        });
    }
  }, [mapName]);

  useEffect(() => {
    if (mapConfig) {
      if (darkmode && mapConfig.source_json_darkmode) {
        setSourceJSON(mapConfig.source_json_darkmode);
      } else {
        setSourceJSON(mapConfig.source_json);
      }
    }
  }, [darkmode, mapConfig]);

  useEffect(() => {
    if (!initialLoadComplete || !mapName) return;
    let newUrl = getNewUrl(
      mapName,
      viewport,
      zoom,
      searchQuery,
      selectedAuthorId,
      selectedCorpusId,
      selectedCluster?.cluster_id,
      selectedJournalId
    );
    if (isDebugMode) {
      newUrl += "?debug";
    }
    
    // Only navigate if the URL has actually changed.
    const currentUrl = location.pathname + location.search;
    if (currentUrl !== newUrl) {
      console.log("navigating to", newUrl);
      // Use replace: false to ensure the URL is added to browser history
      navigate(newUrl, { replace: false });
      // Also update browser history explicitly to ensure it's added
    }
  }, [
    initialLoadComplete,
    mapName,
    searchQuery,
    selectedAuthorId,
    selectedCorpusId,
    selectedCluster?.cluster_id,
    selectedJournalId,
    isDebugMode
  ]);

  const handleFeedbackOpen = () => {
    setFeedbackOpen(true);
  };
  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
  };

  useEffect(() => {
    if (searchQuery) {
      console.debug("searchQuery", searchQuery);
      search(searchQuery).then(() => {
        setSidebarOpen(true);
      });
    }
  }, [searchQuery, searchTrigger]);

  function handleSearchSubmit(q: string) {
    setSearchQuery(q);
    search(q, null, true).then(() => {
      setSidebarOpen(true);
    });
  }

  function handleClearSearch() {
    setSearchClusterResults(null);
    setSearchPaperResults(null);
    removeLayersByPrefix("search_papers");
    setSearchQuery("");
    /*const newUrl = getNewUrl(mapName, viewport, zoom, null, null, null, null);
    if (isDebugMode) {
      window.history.replaceState({}, "", newUrl + "?debug");
    } else {
      window.history.replaceState({}, "", newUrl);
    }*/
    setSidebarOpen(false);
  }

  if (!sourceJSON || !mapConfig) return <LoadingScreen />;

  return (
    <>
      <div style={{ display: "flex", zIndex: 1 }}>
        <SearchButton
          initialValue={query || ""}
          onSubmit={handleSearchSubmit}
          onClear={handleClearSearch}
          loading={loading}
        />

        {sidebarOpen && !isMobile && (
          <SideBar>
            <SearchResultsPanel />
          </SideBar>
        )}

        {isMobile && sidebarOpen && (
          <MobileDrawer
            title={
              searchPaperResults
                ? `${searchPaperResults.length} results`
                : `Search Results "${searchQuery}"`
            }
            isOpen={true}
            onClose={() => setSidebarOpen(false)}
            drawerHeight={drawerHeight}
          >
            <SearchResultsPanel />
          </MobileDrawer>
        )}

        {!!promptingSelection && (
          <PromptingPanel
            selectionType={promptingSelection.selectionType}
            selectionArgs={promptingSelection.selectionArgs}
          />
        )}

        {!isMobile && !isDebugMode && SHOW_FEEDBACK_BUTTON && (
          <div style={{ position: "absolute", top: 10, right: 10, zIndex: 2000 }}>
            <Button
              onClick={handleFeedbackOpen}
              color="primary"
              variant="contained"
              startIcon={<FeedbackIcon />}
              style={{
                textTransform: "none",
                padding: "6px 16px",
                borderRadius: "20px",
                backgroundColor: "white",
                color: "#1976d2",
                boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
              }}
            >
              Feedback
            </Button>
          </div>
        )}

        <div style={{ height: "100%" }}>
          <MapProvider>
            <MapReactMap sourceJSON={sourceJSON} config={mapConfig} />
            {isDebugMode && mapConfig?.source_json_darkmode && (
              <div
                style={{
                  textAlign: "center",
                  height: "50px",
                  position: "absolute",
                  top: "10px",
                  right: "100px",
                }}
              >
                <FormControlLabel
                  style={{ padding: 0, margin: 0 }}
                  control={
                    <Switch
                      checked={darkmode}
                      onChange={() => {
                        setDarkmode(!darkmode);
                      }}
                      color="default"
                      icon={<LightModeIcon />}
                      checkedIcon={<DarkModeIcon />}
                    />
                  }
                  label={darkmode ? "Dark Mode" : "Light Mode"}
                  labelPlacement="start"
                />
              </div>
            )}
            {isDebugMode && showCreateLayerPanel && (
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                }}
              >
                <LayerCreationPanel
                  onLayerCreated={(layers, sources) => {
                    const zipped = _.zip(layers, sources);
                    for (const [layer, source] of zipped) {
                      addLayer(layer as ILayer, source as ISourceConfig);
                    }
                  }}
                />
              </div>
            )}
            {isDebugMode && showLayerControls && (
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                }}
              >
                <LayerControl
                  sourceJson={sourceJSON}
                  onLayersUpdate={(layers: ILayer[]) => {
                    setSourceJSON({
                      ...sourceJSON,
                      layers: layers,
                    });
                  }}
                />
              </div>
            )}
            {isDebugMode && (
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
              >
                <IconButton onClick={() => setShowLayerControls(!showLayerControls)}>
                  <LayersIcon style={{ fontSize: "30px" }} />
                </IconButton>
                <IconButton onClick={() => setShowCreateLayerPanel(!showCreateLayerPanel)}>
                  <AddIcon style={{ fontSize: "30px" }} />
                </IconButton>
              </div>
            )}
            <div
              style={{
                position: "absolute",
                right: "15px",
                bottom: "30px",
                borderRadius: "3px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginBottom: "8px" }}>
                <IconButton
                  onClick={() =>
                    flyToAnimated({
                      center: { lng: 0, lat: 0 },
                      zoom: 2,
                      ne: { lng: 180, lat: 85 },
                      sw: { lng: -180, lat: -85 },
                    })
                  }
                  size="small"
                  style={{
                    padding: "4px",
                    borderRadius: "8px",
                    backgroundColor: "white",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  }}
                >
                  <CenterFocusStrongIcon style={{ fontSize: "22px" }} />
                </IconButton>
              </div>
              <div
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <IconButton onClick={() => zoomIn()} size="small" style={{ padding: "4px" }}>
                  <AddIcon style={{ fontSize: "22px" }} />
                </IconButton>
                <div style={{ height: "1px", backgroundColor: "#e0e0e0", margin: "0 4px" }} />
                <IconButton onClick={() => zoomOut()} size="small" style={{ padding: "4px" }}>
                  <RemoveIcon style={{ fontSize: "22px" }} />
                </IconButton>
              </div>
            </div>
            <div style={{ position: "absolute", bottom: 30, right: 57 }}>
              <ColorLegend />
            </div>
          </MapProvider>
        </div>
        {searchPaperResults &&
          !selectedCorpusId &&
          !selectedCluster &&
          !selectedAuthorId &&
          !isMobile && <SidebarToggleButton />}
        {isDebugMode && (
          <div
            style={{
              position: "absolute",
              zIndex: 2000,
              bottom: 10,
              right: 50,
              padding: 30,
            }}
          >
            Zoom: {zoom.toFixed(2)}
          </div>
        )}
        <div
          style={{
            position: "absolute",
            zIndex: 1000,
            bottom: 0,
            right: 0,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            fontSize: "11px",
            borderRadius: "3px",
            padding: "2px 5px",
            lineHeight: "13px",
          }}
        >
          Copyright © Scholarmaps 2025 &nbsp;•&nbsp; Data by SemanticScholar
          &nbsp;•&nbsp;
          <a href="/impressum" style={{ color: "#333" }}>
            Impressum
          </a>{" "}
          &nbsp;•&nbsp;
          <a href="/about" style={{ color: "#333" }}>
            About
          </a>
        </div>
      </div>
      {!isMobile && !isDebugMode && (
        <FeedbackDialog open={feedbackOpen} onClose={handleFeedbackClose} />
      )}
      {selectedCluster && <ClusterDetailsPanel />}
      {selectedAuthorId && <AuthorDetailsPanel />}
      {selectedJournalId && <JournalDetailsPanel />}
      {selectedCorpusId && <PaperDetailsPanel />}
    </>
  );
}

export default MapHandler;

function getNewUrl(
  mapName: string,
  viewport: IViewport,
  zoom: number,
  searchQuery?: string | null,
  selectedAuthorId?: string | null,
  selectedCorpusId?: string | null,
  selectedClusterId?: number | null,
  selectedJournalId?: string | null
): string {
  const centerLng = ((viewport.ne.lng + viewport.sw.lng) / 2).toFixed(7);
  const centerLat = ((viewport.ne.lat + viewport.sw.lat) / 2).toFixed(7);
  let url = `/map/${mapName}`;
  if (selectedJournalId) {
    url += `/journal/${selectedJournalId}`;
    //url += `/coordinates/${centerLat},${centerLng},${zoom}z`;
    return url;
  }
  if (selectedAuthorId) {
    url += `/author/${selectedAuthorId}`;
    return url;
  } else if (selectedClusterId) {
    url += `/cluster/${selectedClusterId}`;
    return url;
  }
  if (searchQuery) {
    url += `/search/${encodeURIComponent(searchQuery)}`;
  }
  
  if (selectedCorpusId) {
    url += `/corpus/${selectedCorpusId}`;
  }
  //url += `/coordinates/${centerLat},${centerLng},${zoom}z`;
  return url;
}
// src/components/Login.tsx
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import axios from "axios";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";

const Logo = styled("img")({
  width: "100%",
  maxWidth: "200px",
  marginBottom: "20px",
});

const Login = () => {
  const { loginUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Extract the redirect path from query parameters
  const queryParams = new URLSearchParams(location.search);
  const redirectPath = queryParams.get("redirect") || "/map/v2_2";

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isLoading) return; // Prevent resubmission while loading
    
    setIsLoading(true);
    try {
      // Determine the base URL dynamically based on the current hostname
      const currentHostname = window.location.hostname;
      const BASE_URL = process.env.REACT_APP_API_BASE_URL || `http://${currentHostname}:5000`;
      const response = await axios.post(`${BASE_URL}/auth/login`, {
        username,
        password,
      });
      loginUser(response.data); // Save tokens in context and localStorage
      navigate(redirectPath); // Redirect to the intended page
    } catch (err) {
      setError("Invalid credentials. Please try again.");
      console.error(err);
      setIsLoading(false);
    }
  };

  const loginForm = (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ width: "100%" }}
    >
      <TextField
        margin="normal"
        required
        fullWidth
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        disabled={isLoading}
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={isLoading}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={isLoading}
        sx={{ mt: 3, mb: 2 }}
      >
        {isLoading ? <CircularProgress size={24} color="inherit" /> : "Login"}
      </Button>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
        <Link to="/impressum" style={{ color: 'inherit', textDecoration: 'none' }}>
          <Typography variant="body2">
            Impressum
          </Typography>
        </Link>
        <Link to="/about" style={{ color: 'inherit', textDecoration: 'none' }}>
          <Typography variant="body2">
            About
          </Typography>
        </Link>
      </Box>
    </Box>
  );

  // Mobile view without Card
  if (isMobile) {
    return (
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          px: 4, // Increased horizontal padding
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            py: 4, // Added vertical padding
            px: 3, // Added horizontal padding inside the box
          }}
        >
          <Logo src="/logowide.png" alt="Company Logo" />
          <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
            Login
          </Typography>
          {error && (
            <Typography color="error" sx={{ mb: 3, px: 2 }}>
              {error}
            </Typography>
          )}
          {loginForm}
        </Box>
      </Container>
    );
  }

  // Desktop view with Card
  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Card sx={{ padding: 4, boxShadow: 3, width: "100%", maxWidth: 400 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Logo src="/logowide.png" alt="Company Logo" />
            <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
              Login
            </Typography>
            {error && (
              <Typography color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
            {loginForm}
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Login;
